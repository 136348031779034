import styled, { css } from "styled-components";
import { defaultTheme } from "utils/theming";
import { DividerProps } from "./Divider.interface";

export const DividerStyled = styled.div<DividerProps>`
  margin: ${({ theme }) => theme.space[2]} 0;
  margin-bottom: ${({ theme, hasHeader }: any) => hasHeader && theme.space[0]};
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.gray3};
`;
export const DividerVerticalStyled = styled.div<DividerProps>`
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  border-right: 1px solid ${({ theme }) => theme.palette.gray3};
`;
export const DividerVerticalWrapperStyled = styled.div<DividerProps>`
  width: 0px;
  overflow: visible;
  position: relative;
`;
export const HeaderStyled = styled.div<any>`
  padding: ${({ theme }) => theme.space[2]} !important;
  padding-left: ${({ theme }) => theme.space[4]} !important;
  color: ${({ theme }) => theme.palette.primaryBlue} !important;
  background-color: ${({ theme }) => theme.palette.gray2} !important;
  width: fit-content;
  height: fit-content;
  border-radius: 0 0 8px 0;
`;

DividerStyled.defaultProps = defaultTheme;
DividerVerticalStyled.defaultProps = defaultTheme;
DividerVerticalWrapperStyled.defaultProps = defaultTheme;
HeaderStyled.defaultProps = defaultTheme;
