import { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
    Input,
    Modal,
    Button,
    Box,
    Divider,
    Select,
    Text,
    ToggleSwitch,
} from 'components';
import { submitForm } from './submitForm';
import { getData } from './getData';

interface FormValues {
    OBAFee?: number;
    holdFee?: number;
    unlockPrice?: number;
    minutePrice?: number;
    pausePrice?: number;
    idlePrice?: number;
    trafficAllocation?: number;
}

const initialValues: FormValues = {
    unlockPrice: 0,
    minutePrice: 0,
    pausePrice: 0,
    idlePrice: 0,
    trafficAllocation: 0,
};

const validationSchema = yup.object().shape({
    OBAFee: yup.number().required(),
    holdFee: yup.number().required(),
    unlockPrice: yup.number().required(),
    minutePrice: yup.number().required(),
    pausePrice: yup.number().required(),
    idlePrice: yup.number().required(),
});

const defualtVariant = {
    unlockPrice: 0,
    minutePrice: 0,
    pausePrice: 0,
    idlePrice: 0,
    trafficAllocation: 0,
};

const TextInput = ({ ...props }: any) => <Field {...props} as={Input} />;

export const PriceChangeRequest = ({ edit = true, ...props }) => {
    const [values, setValues] = useState<FormValues>(initialValues);
    const [priceBand, setPriceBand] = useState('');
    const navigate = useNavigate();
    const [varinats, setVarinats] = useState<any>([{ defualtVariant }]);

    useEffect(() => {
        getData('d').then(setValues);
    }, []);

    const handleSubmit = (values: FormValues) => {
        submitForm(values).then(handleClose);
    };
    const handleClose = () => {
        navigate(-1);
    };
    return (
        <Formik initialValues={values} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <Modal
                        w={'850px'}
                        isOpen={true}
                        header="Price Change Request for Berlin"
                        onClickClose={handleClose}
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit">Make request</Button>
                            </>
                        }
                    >
                        <Text variant="caption" mb={16} mr={20}>
                            A change request can be submitted by selecting price
                            plans or custom values per vehicle. These changes
                            can be made at the zone level, either for a specific
                            period or on a recurring basis. However, please note
                            that these changes will only take effect after
                            approval from the pricing managers
                        </Text>
                        <Box d="flex" gap={4} xs={{ flexWrap: 'wrap' }}>
                            {/* <Select
                                w={80}
                                helperText="Country select effect on Zone options and currency"
                                label="Country"
                                name="Country"
                                options={[
                                    { label: 'Germany', value: 'DE' },
                                    { label: 'France', value: 'FR' },
                                    { label: 'United Kingdom', value: 'UK' },
                                    { label: 'United States', value: 'US' },
                                ]}
                            />
                            <Select
                                w={42}
                                label="zone"
                                name="zone"
                                options={[
                                    { label: 'Berlin', value: 'berlin' },
                                    { label: 'Hamburg', value: 'hamburg' },
                                    { label: 'Test', value: 'Test' },
                                ]}
                            /> */}
                            <Select
                                label="Vehicle type"
                                options={[
                                    { label: 'eScooter', value: '1' },
                                    { label: 'eBick', value: '2' },
                                ]}
                                w={40}
                            ></Select>
                            <Select
                                label="Price band"
                                options={[
                                    { label: 'Standard', value: '1' },
                                    { label: 'Discount', value: '2' },
                                    { label: 'Custom', value: '3' },
                                ]}
                                onChange={e => setPriceBand(e.target.value)}
                                w={40}
                            ></Select>
                        </Box>
                        <TextInput
                            w={300}
                            label="Dircsription"
                            name="dircsription"
                            helperText="Insert a Reason for change request"
                        />
                        {priceBand == '3' && (
                            <>
                                <Divider header="Custom price" spaceX="-16px" />
                                <Box
                                    mt={1}
                                    d="flex"
                                    gap={2}
                                    xs={{ flexWrap: 'wrap' }}
                                >
                                    <TextInput
                                        w={32}
                                        label="Unlock price"
                                        name="unlockPrice"
                                        textAfter="EUR"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Minute price"
                                        name="minutePrice"
                                        textAfter="EUR/min"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Pause price"
                                        name="pausePrice"
                                        textAfter="EUR/min"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Idle price"
                                        name="idlePrice"
                                        textAfter="EUR"
                                        type="number"
                                    />
                                </Box>
                            </>
                        )}
                        
                        <Divider header="Perodic" spaceX="-16px" />
                        <Text variant="caption" bold mt={2} w={'80%'} mb={2}>
                            If you wish for these changes to take effect within
                            a specific time frame and on a recurring basis,
                            please refer to this section.
                        </Text>
                        <Text variant="caption" mb={4} ml={4} w={'90%'}>
                            Conversely, if you leave the start and end dates and
                            recurrence as default values, your price change
                            request will generally update the zone-level pricing
                        </Text>
                        <Box d="flex" gap={4}>
                            <TextInput
                                type="datetime-local"
                                w={80}
                                name="validFrom"
                                label="Valid from"
                            />
                            <TextInput
                                type="datetime-local"
                                w={80}
                                name="validTo"
                                label="Valid to"
                            />
                        </Box>
                        <Box d="flex" gap={2} xs={{ direction: 'rtl' }}>
                            <Button
                                size="small"
                                onClick={() => {
                                    // let temp = values?.recurrence || [];
                                    // temp.push('');
                                    setValues({
                                        ...values,
                                        // recurrence: temp,
                                    });
                                }}
                            >
                                Add Rule
                            </Button>
                        </Box>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
