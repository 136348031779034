import styled, { css } from 'styled-components';
import { defaultTheme } from 'utils/theming';
const fontSizes: any = {
    title_1: 36,
    title_2: 32,
    title_3: 22,
    title_4: 18,
    title_5: 16,
    body_1: 18,
    body_2: 14,
    caption: 14,
};
const mobileFontSizes: any = {
    title_1: 24,
    title_2: 18,
    title_3: 16,
    title_4: 12,
    title_5: 10,
    body_1: 12,
    body_2: 10,
    caption: 8,
};
const fontWeight: any = {
    title_1: 700,
    title_2: 700,
    title_3: 700,
    title_4: 700,
    title_5: 700,
};

export const TextStyled = styled.div<any>`
    font-family: 'Circular TT', Arial;
    color: ${({ color, theme }) =>
        color ? theme.palette[color] : theme.palette.primaryBlue};
    ${({ variant, bold, theme }) =>
        css`
            font-weight: ${bold ? 700 : fontWeight[variant] || 'normal'};
            font-size: ${`${fontSizes[variant] || fontSizes.body_1}px`};

            @media (max-width: ${theme.screens.sm}) {
                font-size: ${`${mobileFontSizes[variant] || mobileFontSizes.body_1
            }px`};
            }
        `}
`;

TextStyled.defaultProps = defaultTheme;
