import { filterData, RowData } from './filterData';
export const sortData = (
    data: RowData[],
    payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
) => {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    let sortedData: RowData[];
    if (sortBy in data[0]) {
        sortedData = [...data].sort((a, b) => {
            if (payload.reversed) {
                // @ts-ignore
                return b[sortBy] ? b[sortBy].localeCompare(a[sortBy]) : -1;
            }
            // @ts-ignore
            return a[sortBy] ? a[sortBy].localeCompare(b[sortBy]) : -1;
        });
    } else {
        sortedData = [...data];
    }

    return filterData(sortedData, payload.search);
};
