import {
  DividerStyled,
  HeaderStyled,
  DividerVerticalStyled,
  DividerVerticalWrapperStyled,
} from "./Divider.styled";
import { DividerProps } from "./Divider.interface";
import React from "react";
import { Text } from "components";
export const Divider: React.FC<DividerProps> = ({
  header,
  color,
  variant = "horizontal",
  ...props
}: DividerProps) => {
  if (variant === "vertical") {
    return (
      <DividerVerticalWrapperStyled {...props}>
        <DividerVerticalStyled hasHeader={header != null} />
      </DividerVerticalWrapperStyled>
    );
  } else {
    return (
      <>
        <DividerStyled as="hr" hasHeader={header != null} {...props} />
        {header && (
          <HeaderStyled
            bold
            as={Text}
            variant="caption"
            spaceX={props.spaceX}
          >
            {header}
          </HeaderStyled>
        )}
      </>
    );
  }
};
