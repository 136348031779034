import styled, { css } from 'styled-components';
import { defaultTheme } from 'utils/theming';
import { GridProps } from './Grid.interface';

export const GridStyled = styled.div<GridProps>`
    margin-top: -${({ spacing, theme }) => theme.space[spacing || 0]};
    margin-left: -${({ spacing, theme }) => theme.space[spacing || 0]};
    > div {
        padding-left: ${({ spacing, theme }) => theme.space[spacing || 0]};
        padding-top: ${({ spacing, theme }) => theme.space[spacing || 0]};
    }

    flex-basis: 0;
    flex-grow: 1;
    ${({ container }) =>
        container &&
        css`
            display: flex;
            flex-wrap: wrap;
            flex-grow: 0;
        `};
    ${({ direction }) =>
        direction &&
        css`
            flex-direction: ${direction};
        `};

    @media (min-width: 0px) {
        ${({ xs = 0, columns = 12 }) =>
        xs &&
        css`
                flex-basis: ${typeof xs === 'number'
                ? xs * (100 / columns) + '%'
                : xs};
                flex-grow: 0;
                max-width: ${typeof xs === 'number'
                ? xs * (100 / columns)
                : 'unset'}%;
            `}
    }
    @media (min-width: 576px) {
        ${({ sm = 0, columns = 12 }) =>
        sm &&
        css`
                flex-basis: ${typeof sm === 'number'
                ? sm * (100 / columns) + '%'
                : sm};
                flex-grow: 0;
                max-width: ${typeof sm === 'number'
                ? sm * (100 / columns)
                : 'unset'}%;
            `}
    }
    @media (min-width: 768px) {
        ${({ md = 0, columns = 12 }) =>
        md &&
        css`
                flex-basis: ${typeof md === 'number'
                ? md * (100 / columns) + '%'
                : md};
                flex-grow: 0;
                max-width: ${typeof md === 'number'
                ? md * (100 / columns)
                : 'unset'}%;
            `}
    }
    @media (min-width: 992px) {
        ${({ lg = 0, columns = 12 }) =>
        lg &&
        css`
                flex-basis: ${typeof lg === 'number'
                ? lg * (100 / columns) + '%'
                : lg};
                flex-grow: 0;
                max-width: ${typeof lg === 'number'
                ? lg * (100 / columns)
                : 'unset'}%;
            `}
    }
    @media (min-width: 1200px) {
        ${({ xl = 0, columns = 12 }) =>
        xl &&
        css`
                flex-basis: ${typeof xl === 'number'
                ? xl * (100 / columns) + '%'
                : xl};
                flex-grow: 0;
                max-width: ${typeof xl === 'number'
                ? xl * (100 / columns)
                : 'unset'}%;
            `}
    }
`;

GridStyled.defaultProps = defaultTheme;
