export const getPricingPlanMapper = {
    zones: res => {
        const { data } = res;
        const { pricing = [] } = data || [];
        let eScooter = {};
        let eBike = {};
        pricing.forEach(i => {
            if (i.vehicleType == 'escooter') {
                eScooter = {
                    unlockPriceEScooter: i.unlockFee,
                    minutePriceEScooter: i.runningFee,
                    pausePriceEScooter: i.pausedFee,
                    idlePriceEScooter: i.idleFee,
                };
            }
            if (i.vehicleType == 'ebicycle') {
                eBike = {
                    unlockPriceEBike: i.unlockFee,
                    minutePriceEBike: i.runningFee,
                    pausePriceEBike: i.pausedFee,
                    idlePriceEBike: i.idleFee,
                };
            }
        });

        return {
            ...data,
            values: {
                ...eScooter,
                ...eBike,
                obaFee: data?.obaFee,
                holdFee: data?.holdFee,
                convenienceFee: data?.convenienceFee,
            },
        };
    },
    countries: res => {
        const { data } = res || {};
        const { pricing } = data || [];
        let eScooter = {};
        let eBike = {};

        pricing.forEach(i => {
            if (i.vehicleType == 'escooter') {
                eScooter = {
                    unlockPriceEScooter: i.unlockFee,
                    minutePriceEScooter: i.runningFee,
                    pausePriceEScooter: i.pausedFee,
                    idlePriceEScooter: i.idleFee,
                };
            }
            if (i.vehicleType == 'ebicycle') {
                eBike = {
                    unlockPriceEBike: i.unlockFee,
                    minutePriceEBike: i.runningFee,
                    pausePriceEBike: i.pausedFee,
                    idlePriceEBike: i.idleFee,
                };
            }
        });
        return {
            ...data,
            values: {
                ...eScooter,
                ...eBike,
                obaFee: data?.obaFee,
                holdFee: data?.holdFee,
                convenienceFee: data?.convenienceFee,
            },
        };
    },
    // experiment: (res: any = {}) => {
    //     const { data } = res;
    //     return data[0]
    // },
    history: (res: any = {}) => {
        const { data } = res;

        return data.map(i => ({
            ...i,
            country: i.createdBy || 'Unknow user',
        }));
    },
    periodic: res => {
        const data = res.data[0];
        const { pricing = [] } = data || [];
        let eScooter = {};
        let eBike = {};
        pricing.forEach(i => {
            if (i.vehicleType == 'escooter') {
                eScooter = {
                    unlockPriceEScooter: i.unlockFee,
                    minutePriceEScooter: i.runningFee,
                    pausePriceEScooter: i.pausedFee,
                    idlePriceEScooter: i.idleFee,
                };
            }
            if (i.vehicleType == 'ebicycle') {
                eBike = {
                    unlockPriceEBike: i.unlockFee,
                    minutePriceEBike: i.runningFee,
                    pausePriceEBike: i.pausedFee,
                    idlePriceEBike: i.idleFee,
                };
            }
        });

        return {
            values: {
                ...eScooter,
                ...eBike,
                ...data,
                validFrom: data?.validFrom?.slice(0, 16),
                validTo: data?.validTo?.slice(0, 16),
            },
        };
    },
    experiment: res => {
        const data = res.data;
        let eScooter = data.pricing.escooter.map(i => ({
            ...i,
            vehicleType: 'escooter',
        }));
        let ebicycle = data.pricing.ebicycle.map(i => ({
            ...i,
            vehicleType: 'ebicycle',
        }));

        return {
            values: {
                ...data,
                pricing: [...eScooter, ...ebicycle],
            },
        };
    },
};
