import { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Input, Badge, Modal, Button, Box, Divider } from 'components';
import { getData } from './getData';
import { submitForm } from './submitForm';
import { hasRole } from 'utils';

interface FormValues {
    obaFee?: number;
    holdFee?: number;
    convenienceFee?: number;
    unlockPriceEScooter?: number;
    minutePriceEScooter?: number;
    pausePriceEScooter?: number;
    idlePriceEScooter?: number;
    unlockPriceEBike?: number;
    minutePriceEBike?: number;
    pausePriceEBike?: number;
    idlePriceEBike?: number;
}

const initialValues: FormValues = {
    obaFee: 0,
    holdFee: 0,
    unlockPriceEScooter: 0,
    minutePriceEScooter: 0,
    pausePriceEScooter: 0,
    idlePriceEScooter: 0,
    unlockPriceEBike: 0,
    minutePriceEBike: 0,
    pausePriceEBike: 0,
    idlePriceEBike: 0,
};

const validationSchema = yup.object().shape({
    obaFee: yup.number().required(),
    holdFee: yup.number().required(),
    unlockPriceEScooter: yup.number().required(),
    minutePriceEScooter: yup.number().required(),
    pausePriceEScooter: yup.number().required(),
    idlePriceEScooter: yup.number().required(),
    unlockPriceEBike: yup.number().required(),
    minutePriceEBike: yup.number().required(),
    pausePriceEBike: yup.number().required(),
    idlePriceEBike: yup.number().required(),
});

const TextInput = ({ ...props }: any) => (
    <Field {...props} bgColor={props.disabled && 'white'} as={Input} />
);

export const CountryEdit = ({ data, isLoading, roles = [],onChange }) => {
    const [values, setValues] = useState<FormValues>(data.values);
    const navigate = useNavigate();
    const { currency = '' } = data;
    const editable = hasRole(roles, ['admin', 'pricing-manager']);

    useEffect(() => {
        setValues(data.values);
    }, [data.values]);

    const handleSubmit = (values: FormValues) => {
        onChange(values, handleClose);
    };
    const handleClose = () => {
        navigate(-1);
    };

    return (
        <Formik
            enableReinitialize={true}
            // validationSchema={validationSchema}
            initialValues={values}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <Modal
                        isLoading={isLoading}
                        isOpen={true}
                        header={`Edit ${data.internalName}`}
                        onClickClose={handleClose}
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    {editable ? 'Cancel' : 'Close'}
                                </Button>
                                <Button hidden={!editable} type="submit">
                                    Save
                                </Button>
                            </>
                        }
                    >
                        <Box d="flex" spaceY={2} gap={2}>
                            <Badge size="small" title="country">
                                {data.country}
                            </Badge>
                            <Badge size="small" title="Vat">
                                VAT {data.vatPercentage}
                            </Badge>
                        </Box>
                        <TextInput
                            label="OBA fee"
                            disabled={!editable}
                            textAfter={currency}
                            name="obaFee"
                            type="number"
                            helperText={
                                errors.obaFee && touched.obaFee
                                    ? errors.obaFee
                                    : null
                            }
                        />
                        <TextInput
                            label="Hold fee"
                            disabled={!editable}
                            name="holdFee"
                            textAfter={currency}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            label="convenience Fee"
                            name="convenienceFee"
                            textAfter={currency}
                            type="number"
                        />
                        <Divider
                            header="Pricing EScooter"
                            disabled={!editable}
                            spaceX="-16px"
                            mt={8}
                        />
                        <TextInput
                            label="Unlock price"
                            disabled={!editable}
                            name="unlockPriceEScooter"
                            textAfter={currency}
                            type="number"
                        />
                        <TextInput
                            label="Minute price"
                            disabled={!editable}
                            name="minutePriceEScooter"
                            textAfter={`${currency}/min`}
                            type="number"
                        />
                        <TextInput
                            label="Pause price"
                            disabled={!editable}
                            name="pausePriceEScooter"
                            textAfter={`${currency}/min`}
                            type="number"
                        />
                        <TextInput
                            label="Idle price"
                            disabled={!editable}
                            name="idlePriceEScooter"
                            textAfter={currency}
                            type="number"
                        />
                        <Divider header="Pricing EBike" spaceX="-16px" mt={8} />
                        <TextInput
                            label="Unlock price"
                            disabled={!editable}
                            name="unlockPriceEBike"
                            textAfter={currency}
                            type="number"
                        />
                        <TextInput
                            label="Minute price"
                            disabled={!editable}
                            name="minutePriceEBike"
                            textAfter={`${currency}/min`}
                            type="number"
                        />
                        <TextInput
                            label="Pause price"
                            disabled={!editable}
                            name="pausePriceEBike"
                            textAfter={`${currency}/min`}
                            type="number"
                        />
                        <TextInput
                            label="Idle price"
                            disabled={!editable}
                            name="idlePriceEBike"
                            textAfter={currency}
                            type="number"
                        />
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
