import { InputProps } from "./Input.interface"
import { ContainerStyled, InputAndIconsContainerStyled, InputStyled, LabelStyled } from "./styled"
import { Icon, Text } from 'components'
export const Input = ({
    icon = '',
    endIcon = '',
    fullWidth = false,
    label,
    id,
    placeholder,
    required,
    helperText,
    textAfter,
    className,
    register,
    bgColor,
    as = "input",
    ...props }: InputProps) => {
    return (
        <ContainerStyled className={className}>
            <LabelStyled hide={placeholder} htmlFor={id}>
                {label}
                {required && '*'}
            </LabelStyled>
            <InputAndIconsContainerStyled bgColor={bgColor}>
                {icon &&
                    <Icon icon={icon} size="medium" ml={3} color="gray4" />
                }
                <InputStyled
                    id={id}
                    placeholder={placeholder}
                    {...props}
                    as={as}
                />
                <Text bold mr={2} variant='caption'>{textAfter}</Text>
                {icon &&
                    <Icon icon={endIcon} size="medium" mr={3} color="gray4" />
                }
            </InputAndIconsContainerStyled>
            {helperText &&
                <Text
                    variant="caption"
                    mt={0.5}
                >
                    {helperText}
                </Text>
            }
        </ContainerStyled>)
}