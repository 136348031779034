import { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
    Input,
    Modal,
    Button,
    Box,
    Divider,
    Select,
    ToggleSwitch,
    Text,
    Badge,
} from 'components';
import { hasRole } from 'utils';
import { showNotification } from '@mantine/notifications';

interface FormValues {
    OBAFee?: number;
    holdFee?: number;
    unlockFee?: number;
    runningFee?: number;
    pausePrice?: number;
    idleFee?: number;
    trafficAllocation?: number;
    zoneId?: string;
    ebicycle?: boolean;
    escooter?: boolean;
    experimentName?: boolean;
    onlyWhitelist?: boolean;
}

// const initialValues: FormValues = {
//     unlockFee: 0,
//     runningFee: 0,
//     pausePrice: 0,
//     idleFee: 0,
//     trafficAllocation: 0,
// };

const validationSchema = yup.object().shape({
    OBAFee: yup.number().required(),
    holdFee: yup.number().required(),
    unlockFee: yup.number().required(),
    runningFee: yup.number().required(),
    pausedFee: yup.number().required(),
    idleFee: yup.number().required(),
});

const defualtVariant = {
    unlockFee: 0,
    runningFee: 0,
    pausedFee: 0,
    idleFee: 0,
};

const TextInput = ({ ...props }: any) => (
    <Field {...props} bgColor={props.disabled && 'white'} as={Input} />
);

export const ABTestEditOrAdd = ({
    data = { values: { pricing: null, zoneId: null } },
    edit = false,
    isLoading,
    onClickDelete = ({}: any) => {},
    roles = [],
    onChange,
    zonesOptions = [],
    priceBandsData = [],
    currency = '',
    ...props
}) => {
    const [values, setValues] = useState<any>(data.values);
    const navigate = useNavigate();
    const [openPopupDelete, setOpenPopupDelete] = useState(false);
    const [variants, setVariants] = useState<any>([]);
    const [country, setCountry] = useState('');
    const editable = hasRole(roles, ['admin', 'pricing-manager']);
    // const { currency = '', countryLevel = { values: {} } } = data;

    useEffect(() => {
        if (edit) {
            setValues(data.values);
            if (data.values?.pricing) setVariants(data.values?.pricing);
        }
    }, [data]);

    useEffect(() => {
        const temp: any = zonesOptions.filter(
            (i: any) => i.value === values?.zoneId
        );
        setCountry(temp[0]?.contry);
    }, [zonesOptions, values]);

    const handleSubmit = (valuesProps: FormValues) => {
        onChange({ ...valuesProps, ...values, variants }, handleClose);
    };
    const handleDeleteRule = index => {
        setVariants(variants.filter((i, ind) => index != ind));
    };
    const handleClose = () => {
        navigate(-1);
    };

    return (
        <Formik
            enableReinitialize={true}
            // validationSchema={validationSchema}
            initialValues={values}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <Modal
                        w={'950px'}
                        isLoading={isLoading}
                        isOpen={true}
                        header={
                            'A/B Test ' +
                            (edit ? `Edit for ${values?.zoneId}` : 'Add')
                        }
                        onClickClose={handleClose}
                        FooterLeftSide={
                            <Button
                                color="primaryOrange"
                                onClick={() => {
                                    setOpenPopupDelete(true);
                                }}
                                hidden={!editable || !edit}
                            >
                                Delete
                            </Button>
                        }
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    {editable ? 'Cancel' : 'Close'}
                                </Button>
                                <Button hidden={!editable} type="submit">
                                    Save
                                </Button>
                            </>
                        }
                    >
                        {!edit && (
                            <>
                                <Text bold mb={1}>
                                    To create an experimental feature, you need
                                    to follow these steps:
                                    <br />
                                    Befor that go to Optimizely and clarify the
                                    experimental key for your feature.
                                </Text>
                                <Text variant="caption" mb={8}>
                                    <br />
                                    1. Copy and paste the experimental key into
                                    this field here from Optimizely.
                                    <br />
                                    2. Set your desired values and prices.
                                    <br />
                                    3. Press the "Save" button to save the
                                    settings and generate a unique price IDs.
                                    <br />
                                    4. Take price IDs and update them in
                                    Optimizely.
                                    <br />
                                    5. Allocate the desired percentage of
                                    traffic allocation for your feature in
                                    Optimizely.
                                    <br />
                                    <Text
                                        variant="caption"
                                        bold
                                        ml={4}
                                        mr={20}
                                        mt={8}
                                    >
                                        After completing the setup, you can test
                                        all the prices in the consumer app using
                                        your internal credentials. Make sure to
                                        check if the changes, such as modified
                                        prices, are reflected correctly. You can
                                        toggle the whitelisted mode to off and
                                        observe the effects of the experimental
                                        pricing changes in real-time for all
                                        user after yuor test dont forget in
                                        duration of test keep the Whitelist
                                        "On".
                                    </Text>
                                </Text>
                                <Text variant="caption">
                                    When creating an experimental, it will
                                    initially start with a 'whitelist-only'
                                    view, and then during the editing process,
                                    you have the option to change it to a
                                    'disable-as-whitelist' setting
                                </Text>
                                <Divider spaceX="-16px" mt={8} />
                            </>
                        )}

                        <Box d="flex" gap={4} xs={{ flexWrap: 'wrap' }}>
                            {!edit && (
                                <Select
                                    w={42}
                                    disabled={!editable}
                                    label="Zone"
                                    options={[
                                        { value: '', label: '' },
                                        ...zonesOptions,
                                    ]}
                                    value={values?.zoneId}
                                    onChange={e => {
                                        setValues({
                                            ...values,
                                            zoneId: e.target.value,
                                        });
                                    }}
                                />
                            )}
                            <TextInput
                                label="Expremental name (key)"
                                name="experimentName"
                                disabled={!editable}
                                onChange={e => {
                                    setValues({
                                        ...values,
                                        experimentName: e.target.value,
                                    });
                                }}
                                w={96}
                            />
                            {edit ? (
                                <ToggleSwitch
                                    checked={values?.onlyWhitelist}
                                    flex={1}
                                    disabled={!editable}
                                    onChange={e => {
                                        setValues({
                                            ...values,
                                            onlyWhitelist: e.target.checked,
                                        });
                                    }}
                                    mb={2}
                                >
                                    Whitelist
                                </ToggleSwitch>
                            ) : (
                                ''
                            )}
                        </Box>
                        <Divider spaceX="-16px" />
                        <Text mt={6} variant="caption">
                            When you add or remove variants, the IDs for riding
                            prices will change. After saving your
                            changes/generate, make sure to use the newly
                            generated IDs. Remember to update the prices in
                            Optimizely by copying and pasting the updated IDs
                            from here.
                        </Text>
                        <Box d="flex" xs={{ direction: 'rtl' }} mt={4} gap={2}>
                            <Button
                                size="small"
                                hidden={!editable}
                                onClick={() =>
                                    setVariants([
                                        ...variants,
                                        {
                                            ...defualtVariant,
                                            vehicleType: 'escooter',
                                        },
                                    ])
                                }
                            >
                                Add variant for escooter
                            </Button>
                            <Button
                                size="small"
                                hidden={!editable}
                                onClick={() =>
                                    setVariants([
                                        ...variants,
                                        {
                                            ...defualtVariant,
                                            vehicleType: 'ebicycle',
                                        },
                                    ])
                                }
                            >
                                Add variant for ebicycle
                            </Button>
                        </Box>
                        {variants.map((i, index) => (
                            <>
                                <Divider
                                    // header={`Variant ${(index + 1 + 9)
                                    //     .toString(36)
                                    //     .toUpperCase()}`}
                                    header={`Variant of ${i.vehicleType}`}
                                    spaceX="-16px"
                                    key={`Divider-${index}`}
                                />
                                <Box
                                    d="flex"
                                    xs={{ direction: 'rtl' }}
                                    key={`CloseBTN-${index}`}
                                >
                                    <Button
                                        h={6}
                                        w={6}
                                        rounded
                                        size="small"
                                        variant="outlined"
                                        onClick={() => handleDeleteRule(index)}
                                        sx={{ border: 'none' }}
                                        type="button"
                                        hidden={!editable}
                                    >
                                        ✕
                                    </Button>
                                </Box>
                                <Box
                                    mt={1}
                                    d="flex"
                                    gap={2}
                                    xs={{ flexWrap: 'wrap' }}
                                    key={`box-${index}`}
                                >
                                    <Select
                                        disabled={!editable}
                                        hidden={
                                            !priceBandsData.filter(
                                                (i: any) =>
                                                    i.country?.toUpperCase() ==
                                                    country?.toUpperCase()
                                            ).length
                                        }
                                        options={[
                                            {
                                                label: `not selected band`,
                                                value: null,
                                            },
                                            ...priceBandsData
                                                .filter(
                                                    (i: any) =>
                                                        i.country?.toUpperCase() ==
                                                        country?.toUpperCase()
                                                )
                                                .map((i: any) => ({
                                                    label: `${i.country} ${i.name} (${i.UnlockFee} + ${i.MinuteFee}, Idle: ${i.IdleFee}) `,
                                                    value: i.id,
                                                })),
                                        ]}
                                        onChange={e => {
                                            // setPriceBand(e.target.value);
                                            let temp: any =
                                                priceBandsData.filter(
                                                    (i: any) =>
                                                        i.id == e.target.value
                                                )[0];
                                            const newVariants = [...variants]; // Create a new array
                                            newVariants[index] = {
                                                ...newVariants[index],
                                                unlockFee: temp.UnlockFee,
                                                runningFee: temp.MinuteFee,
                                                idleFee: temp.IdleFee,
                                                id: null,
                                            };
                                            setVariants(newVariants); // Update the state with the new array
                                        }}
                                        value={
                                            priceBandsData.length &&
                                            priceBandsData.filter(
                                                (i: any) =>
                                                    i.country?.toUpperCase() ==
                                                        country?.toUpperCase() &&
                                                    parseFloat(
                                                        variants[index]
                                                            .idleFee + ''
                                                    ) ==
                                                        parseFloat(
                                                            i?.IdleFee
                                                        ) &&
                                                    parseFloat(
                                                        variants[index]
                                                            ?.runningFee + ''
                                                    ) ==
                                                        parseFloat(
                                                            i?.MinuteFee
                                                        ) &&
                                                    parseFloat(
                                                        variants[index]
                                                            ?.unlockFee + ''
                                                    ) ==
                                                        parseFloat(i?.UnlockFee)
                                                // @ts-ignore
                                            )[0]?.id
                                        }
                                        // locked={locked}
                                        label="Prices band"
                                        name="unlockPriceEScooter"
                                        // textAfter={currency}
                                        type="number"
                                    />

                                    <Input
                                        w={32}
                                        label="Unlock price"
                                        name="unlockFee"
                                        disabled={!editable}
                                        value={i.unlockFee}
                                        onChange={e => {
                                            const newVariants = [...variants]; // Create a new array
                                            newVariants[index] = {
                                                ...newVariants[index],
                                                unlockFee: e.target.value,
                                                id: null,
                                            };
                                            setVariants(newVariants); // Update the state with the new array
                                        }}
                                        textAfter={currency}
                                        type="number"
                                    />
                                    <Input
                                        w={32}
                                        label="Minute price"
                                        name="runningFee"
                                        value={i.runningFee}
                                        disabled={!editable}
                                        onChange={e => {
                                            const newVariants = [...variants]; // Create a new array
                                            newVariants[index] = {
                                                ...newVariants[index],
                                                runningFee: e.target.value,
                                                id: null,
                                            };
                                            setVariants(newVariants); // Update the state with the new array
                                        }}
                                        textAfter={`${currency}/min`}
                                        type="number"
                                    />
                                    <Input
                                        w={32}
                                        label="Pause price"
                                        name="pausedFee"
                                        disabled={!editable}
                                        value={i.pausedFee}
                                        onChange={e => {
                                            const newVariants = [...variants]; // Create a new array
                                            newVariants[index] = {
                                                ...newVariants[index],
                                                pausedFee: e.target.value,
                                                id: null,
                                            };
                                            setVariants(newVariants); // Update the state with the new array
                                        }}
                                        textAfter={`${currency}/min`}
                                        type="number"
                                    />
                                    <Input
                                        w={32}
                                        label="Idle price"
                                        name="idleFee"
                                        disabled={!editable}
                                        value={i.idleFee}
                                        onChange={e => {
                                            const newVariants = [...variants]; // Create a new array
                                            newVariants[index] = {
                                                ...newVariants[index],
                                                idleFee: e.target.value,
                                                id: null,
                                            };
                                            setVariants(newVariants); // Update the state with the new array
                                        }}
                                        textAfter={currency}
                                        type="number"
                                    />
                                </Box>
                                <Box
                                    d="flex"
                                    gap={2}
                                    spaceY={4}
                                    sx={{ justifyContent: 'flex-end' }}
                                    key={`box2-${index}`}
                                >
                                    <Badge
                                        size="small"
                                        color="secondaryOrange"
                                        mt={4}
                                    >
                                        ID : {i.id || 'unallocated'}
                                    </Badge>
                                    <Button
                                        size="small"
                                        mt={1}
                                        variant="outlined"
                                        hidden={!i.id}
                                        onClick={() => {
                                            navigator.clipboard.writeText(i.id);
                                            showNotification({
                                                id: 'copy-data',
                                                title: 'Id copied',
                                                message: `id: ${i.id}`,
                                                autoClose: true,
                                            });
                                        }}
                                    >
                                        Copy id
                                    </Button>
                                </Box>
                            </>
                        ))}
                    </Modal>
                    <Modal
                        id="popupDelete"
                        isOpen={openPopupDelete}
                        onClickClose={() => {
                            setOpenPopupDelete(false);
                        }}
                        withExpandButton={false}
                        header={` Delete experiment item for ${values?.zoneId}`}
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setOpenPopupDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    hidden={!editable}
                                    onClick={() => {
                                        onClickDelete(() => {
                                            setOpenPopupDelete(false);
                                            handleClose();
                                        });
                                    }}
                                >
                                    Yes
                                </Button>
                            </>
                        }
                    >
                        <Text mt={6} mr={16} mb={2}>
                            Are you certain you want to delete the experiment
                            Item with the name "{values?.experimentName}"?
                        </Text>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
