import { Box, Divider, Card, Text, Badge, DataGrid } from 'components';

const HistoryItem = () => (
    <Box spaceY={2}>
        <Box d="flex" gap={2} xs={{ flexWrap: 'wrap' }}>
            <Text variant="caption" bold mr={4}>
                Mohammad Chorakchi
            </Text>
            <Box flex={1} d="flex" gap={2}>
                <Badge size="small" color="gray4">
                    Unlock: 1.2{' '}
                </Badge>
                <Badge size="small" color="gray4">
                    Minute: 2.22{' '}
                </Badge>
            </Box>
            <Badge size="small" variant="outlined">
                12th Feb 2020
            </Badge>
        </Box>
        <Divider />
    </Box>
);

export const ChangesHistory = ({ data = [] }: any) => {
    return (
        <>
            <Divider
                mt={8}
                spaceX="-16px"
                variant="caption"
                header="History of Changes"
            />
            <Text variant= "caption" mt={4}>
                The historical record will not include changes related to
                pricing adjustments for OBA fee, Hold fee and convenience fee.{' '}
            </Text>
            <DataGrid data={data} h={60} noFilter />
            {/* <HistoryItem />
            <HistoryItem />
            <HistoryItem />
            <HistoryItem /> */}
        </>
    );
};
