import { PeriodicEditOrAdd } from 'components';
import { useParams } from 'react-router-dom';
import { updatePricing, useCurrentUser, usePriceBands, useRootZone } from 'services';
import { submitMapper } from './helper';
import { useState } from 'react';

const type = 'periodic';

export const PeriodicAddPage = ({ ...props }) => {
    const { id } = useParams();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { data: zoneData, isLoading: zoneDataLoading } = useRootZone();

    const { data: userData, isLoading: userDataLoading } = useCurrentUser();

    const { priceBandsData, priceBandsupdateDate } = usePriceBands();


    const SubmitHandler = async (data, handleClose) => {
        setIsLoadingSubmit(true);
        const body = submitMapper(data);
        await updatePricing({
            id: type,
            type: 'pricing',
            body,
        });
        setIsLoadingSubmit(false);
    };

    return (
        <PeriodicEditOrAdd
            {...props}
            priceBandsData={priceBandsData}
            zonesOptions={zoneData}
            roles={userData?.roles}
            isLoading={isLoadingSubmit || zoneDataLoading || userDataLoading}
            edit={false}
            onChange={SubmitHandler}
        />
    );
};
