import { CountryEdit, Icon } from 'components';
import { useParams } from 'react-router-dom';
import {
    updateBasePricing,
    updatePricing,
    useCurrentUser,
    useGetPricingPlan,
} from 'services';
import { useState } from 'react';
import { submitMapper } from './helper';

export const CountriesDiteals = ({ ...props }) => {
    const { id } = useParams();
    const type = 'countries';
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const {
        data: data,
        isLoading: dataLoading,
        mutate: revalidateData,
    } = useGetPricingPlan(id, type);

    const { data: userData } = useCurrentUser();

    const submitHandler = async (data, handleClose) => {
        setIsLoadingSubmit(true);
        const dataTemp = submitMapper(data);
        await updateBasePricing({
            id: id,
            type,
            body: dataTemp.zoneData,
        });
        const res: any = await updatePricing({
            id: id,
            type,
            body: { data: dataTemp.pricing },
        });

        revalidateData();
        setIsLoadingSubmit(false);
    };

    return (
        <CountryEdit
            onChange={submitHandler}
            roles={userData?.roles}
            data={data || {}}
            isLoading={dataLoading}
            {...props}
        />
    );
};
