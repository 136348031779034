import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import * as moment from 'moment';
import { useLocalStorage } from 'usehooks-ts';
import {
    Input,
    Badge,
    Modal,
    Button,
    Box,
    Divider,
    Text,
    ToggleSwitch,
    Select,
} from 'components';
import { ChangesHistory } from './History';
import { hasRole } from 'utils';

interface FormValues {
    OBAFee?: number;
    holdFee?: number;
    convenienceFee?: number;
    unlockPriceEScooter?: number;
    minutePriceEScooter?: number;
    pausePriceEScooter?: number;
    idlePriceEScooter?: number;
    unlockPriceEBike?: number;
    minutePriceEBike?: number;
    pausePriceEBike?: number;
    idlePriceEBike?: number;
}

const initialValues: FormValues = {
    OBAFee: 0,
    holdFee: 0,
    convenienceFee: 0,
    unlockPriceEScooter: 0,
    minutePriceEScooter: 0,
    pausePriceEScooter: 0,
    idlePriceEScooter: 0,
    unlockPriceEBike: 0,
    minutePriceEBike: 0,
    pausePriceEBike: 0,
    idlePriceEBike: 0,
};

const validationSchema = yup.object().shape({
    OBAFee: yup.number().required(),
    holdFee: yup.number().required(),
    convenienceFee: yup.number().required(),
    unlockPriceEScooter: yup.number().required(),
    minutePriceEScooter: yup.number().required(),
    pausePriceEScooter: yup.number().required(),
    idlePriceEScooter: yup.number().required(),
    unlockPriceEBike: yup.number().required(),
    minutePriceEBike: yup.number().required(),
    pausePriceEBike: yup.number().required(),
    idlePriceEBike: yup.number().required(),
});

const TextInput = ({
    country,
    overAll,
    periodic,
    abTest,
    disabled,
    locked,
    ...props
}: any) => (
    <Box d="flex">
        <Field
            {...props}
            icon={locked && 'FaLock'}
            bgColor={(disabled || locked) && 'white'}
            disabled={disabled || locked}
            as={Input}
            w={'100%'}
        />
        <Badge
            color="gray4"
            variant="outlined"
            mt={8}
            size="small"
            ml={2}
            hidden={!country}
        >
            Country {country}
        </Badge>
    </Box>
);

export const ZoneEdit = ({
    data,
    isLoading,
    priceBandsData,
    roles = [],
    onChange,
}) => {
    const [values, setValues] = useState<FormValues>(data.values);
    const [locked, setLocked] = useState(false);
    const [priceBand, setPriceBand] = useState({});
    const [config, setConfig]: any = useLocalStorage('config-pricing-hub', {
        bookmarks: [],
    });
    const editable = hasRole(roles, ['admin', 'pricing-manager']);
    const navigate = useNavigate();
    const { currency = '', countryLevel = { values: {} } } = data;

    useEffect(() => {
        setValues(data.values);
        setLocked(
            data.experiment?.experimentKey && !data.experiment?.onlyWhitelist
        );

        priceBandsData &&
            setPriceBand(
                priceBandsData.filter(
                    i =>
                        data?.countryName == i?.country &&
                        data?.values?.idlePriceEScooter == i?.IdleFee &&
                        data?.values?.minutePriceEScooter == i?.MinuteFee &&
                        data?.values?.unlockPriceEScooter == i?.UnlockFee
                )[0]?.id
            );
        // setPriceBand()
    }, [data.values, priceBandsData]);

    const handleSubmit = (values: FormValues) => {
        onChange(values, handleClose);
    };
    const handleClose = () => {
        navigate(-1);
    };

    return (
        <Formik
            enableReinitialize={true}
            // validationSchema={validationSchema}
            initialValues={values}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <Modal
                        isLoading={isLoading}
                        isOpen={true}
                        header={
                            <>
                                <Text bold mr={6}>
                                    {data.zoneId}
                                </Text>
                                <Box d="flex" gap={2}>
                                    <Badge size="small" info="Country">
                                        {data.countryName}
                                    </Badge>
                                    <Badge size="small" info="Timezone">
                                        {data.timezone}
                                    </Badge>
                                    <Badge
                                        size="small"
                                        variant="outlined"
                                        info="Updated at"
                                    >
                                        {/* @ts-ignore */}
                                        {moment(data.updatedAt).format(
                                            'DD, MMM  YYYY'
                                        )}
                                    </Badge>
                                    <Badge
                                        mr={4}
                                        size="small"
                                        variant="outlined"
                                        info="Created at"
                                    >
                                        {/* @ts-ignore */}
                                        {moment(data.createdAt).format(
                                            'DD, MMM  YYYY'
                                        )}
                                    </Badge>
                                </Box>
                                <Box flex={1}></Box>
                                <Button
                                    mr={2}
                                    size="small"
                                    variant="outlined"
                                    noBorder
                                    onClick={() => {
                                        if (
                                            config.bookmarks
                                                .map(i => i.id)
                                                .includes(data.zoneId)
                                        ) {
                                            setConfig({
                                                ...config,
                                                bookmarks: [
                                                    ...config?.bookmarks.filter(
                                                        i => i.id != data.zoneId
                                                    ),
                                                ],
                                            });
                                        } else {
                                            setConfig({
                                                ...config,
                                                bookmarks: [
                                                    ...config?.bookmarks,
                                                    {
                                                        type: 'zones',
                                                        id: data.zoneId,
                                                    },
                                                ],
                                            });
                                        }
                                    }}
                                >
                                    {config.bookmarks
                                        .map(i => i.id)
                                        .includes(data.zoneId)
                                        ? ' Remove from bookmark'
                                        : ' Add to bookmark'}
                                </Button>
                            </>
                        }
                        onClickClose={handleClose}
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    {editable ? 'Cancel' : 'Close'}
                                </Button>
                                <Button hidden={!editable} type="submit">
                                    Save
                                </Button>
                            </>
                        }
                    >
                        {data.experiment?.experimentKey && (
                            <>
                                <Badge
                                    color="primaryOrange"
                                    mb={2}
                                    size="small"
                                    info="Experiment Key"
                                >
                                    {data.experiment?.experimentKey}
                                </Badge>
                                <ToggleSwitch
                                    icon="FaLock"
                                    spaceY={8}
                                    w={'100%'}
                                    checked={locked}
                                    onChange={e => {
                                        setLocked(e.target.checked);
                                    }}
                                >
                                    This zone locked as has expremental pricing
                                    (A/B test).
                                    <br />
                                    switch off the lock to make it editable, and
                                    then you can begin editing.
                                </ToggleSwitch>
                            </>
                        )}
                        {/* <Badge size="small" mb={4} info={`Company Address: ${countryLevel?.company?.address}`}>{countryLevel?.company?.name}</Badge> */}
                        {data.experiment?.onlyWhitelist && (
                            <Badge
                                color="primaryOrange"
                                mb={2}
                                size="small"
                                info="Experiment Key"
                            >
                                Only Whitelist
                            </Badge>
                        )}
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="OBA fee"
                            textAfter={currency}
                            country={countryLevel.obaFee}
                            name="obaFee"
                            type="number"
                            helperText={
                                errors.OBAFee && touched.OBAFee
                                    ? errors.OBAFee
                                    : null
                            }
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Hold fee"
                            name="holdFee"
                            textAfter={currency}
                            country={countryLevel.holdFee}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="convenienceFee"
                            name="convenienceFee"
                            textAfter={currency}
                            type="number"
                            country={countryLevel.obaFee}
                        />
                        <Divider
                            header="Pricing e-scooter"
                            spaceX="-16px"
                            mt={8}
                        />
                        {priceBandsData && (
                            <Select
                                disabled={!editable}
                                options={[
                                    {
                                        label: `not selected band`,
                                        value: null,
                                    },
                                    ...priceBandsData
                                        .filter(
                                            i =>
                                                i?.country?.toUpperCase() ==
                                                data?.countryName?.toUpperCase()
                                        )
                                        .map(i => ({
                                            label: `${i.country} ${i.name} (${i.UnlockFee} + ${i.MinuteFee}, Idle: ${i.IdleFee}) `,
                                            value: i.id,
                                        })),
                                ]}
                                onChange={e => {
                                    setPriceBand(e.target.value);
                                    let temp = priceBandsData.filter(
                                        i => i.id == e.target.value
                                    )[0];

                                    setValues({
                                        ...values,
                                        unlockPriceEScooter: temp.UnlockFee,
                                        minutePriceEScooter: temp.MinuteFee,
                                        idlePriceEScooter: temp.IdleFee,
                                    });
                                }}
                                value={priceBand}
                                locked={locked}
                                label="Prices band"
                                name="unlockPriceEScooter"
                                textAfter={currency}
                                country={
                                    countryLevel.values.unlockPriceEScooter
                                }
                                type="number"
                            />
                        )}
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Unlock price"
                            name="unlockPriceEScooter"
                            textAfter={currency}
                            country={countryLevel.values.unlockPriceEScooter}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Minute price"
                            name="minutePriceEScooter"
                            textAfter={`${currency}/min`}
                            country={countryLevel.values.minutePriceEScooter}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Pause price"
                            name="pausePriceEScooter"
                            textAfter={`${currency}/min`}
                            country={countryLevel.values.pausePriceEScooter}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Idle price"
                            name="idlePriceEScooter"
                            textAfter={currency}
                            type="number"
                            country={countryLevel.values.idlePriceEScooter}
                        />
                        <Divider
                            header="Pricing e-bike"
                            spaceX="-16px"
                            mt={8}
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Unlock price"
                            name="unlockPriceEBike"
                            textAfter={currency}
                            country={countryLevel.values.unlockPriceEBike}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Minute price"
                            name="minutePriceEBike"
                            textAfter={`${currency}/min`}
                            country={countryLevel.values.minutePriceEBike}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Pause price"
                            name="pausePriceEBike"
                            textAfter={`${currency}/min`}
                            country={countryLevel.values.pausePriceEBike}
                            type="number"
                        />
                        <TextInput
                            disabled={!editable}
                            locked={locked}
                            label="Idle price"
                            name="idlePriceEBike"
                            textAfter={currency}
                            country={countryLevel.values.idlePriceEBike}
                            type="number"
                        />

                        <ChangesHistory data={data.history} />
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};

export default ZoneEdit;
