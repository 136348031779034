import { SelectProps } from "./Select.interface"
import { ContainerStyled, TextAfterStyled, InputAndIconsContainerStyled, InputStyled, LabelStyled } from "./styled"
import { Text } from 'components'
export const Select = ({
    icon = '',
    endIcon = '',
    fullWidth = false,
    label,
    id,
    placeholder,
    required,
    helperText,
    textAfter,
    className,
    register,
    options = [],
    as,
    ...props }: SelectProps) => {
    return (
        <ContainerStyled className={className} hidden={props.hidden}>
            <LabelStyled htmlFor={id}>
                {label}
                {required && '*'}
            </LabelStyled>
            <InputAndIconsContainerStyled>
                <InputStyled
                    as="select"
                    id={id}
                    placeholder={placeholder}
                    hasIcon={icon ? true : false}
                    {...props}
                    {...(register ? register(props.name) : {})}
                >
                    {options.map(item => <option key={item.value + "name"} value={item.value}>{item.label}</option>)}
                </InputStyled>
                <TextAfterStyled>{textAfter}</TextAfterStyled>
                {/* <SVG icon={icon} size="medium" className="icon" color="gray4" /> */}
            </InputAndIconsContainerStyled>
            {helperText &&
                <Text
                    variant="caption"
                    mt={0.5}
                    mb={0.5}
                >
                    {helperText}
                </Text>
            }
        </ContainerStyled>)
}