import { PricingPlansTemplate } from 'components';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    useCurrentUser,
    useGetPricingPlans,
    usePriceBands,
    useRootZone,
} from 'services';

export const PricingPlansPage = ({ ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: zoneData } = useRootZone();
    const { type = 'zones' } = useParams();
    const {
        data: pricePlans,
        isLoading: pricePlansIsLoading,
        mutate: revalidatePrcingList,
    } = useGetPricingPlans(type);
    const { data: userData } = useCurrentUser();
    const { priceBandsData, priceBandsupdateDate } = usePriceBands();
    const { data: rootZoneData, isLoading: zoneDataLoading } = useRootZone();
    const { data: experimentData, isLoading: experimentDataLoading } =
        useGetPricingPlans('experiment');
    const { data: periodicData, isLoading: periodicDataLoading } =
        useGetPricingPlans('periodic');

    let pricePlansTemp = pricePlans;

    // const temp =

    if (type == 'zones' && pricePlans) {
        pricePlansTemp = pricePlans.map(i => ({
            ...i,
            isActive: rootZoneData.filter(j => j.value == i.zoneId)[0]
                ?.isActive,
            experiment:
                experimentData.filter(j => j.zoneId == i.zoneId)[0]
                    ?.experimentName || false,
            periodic: periodicData
                .filter(j => j.targetZones.includes(i.zoneId) && j.isActive)
                .map(i => i?.name),
                pricing: i.pricing.map(j => ({
                    ...j,
                    pricesBand: priceBandsData.filter(
                        k =>
                            k.country?.toUpperCase() ==
                                i.country?.toUpperCase() &&
                            j.vehicleType.toUpperCase() ==
                                k.vehicleType.toUpperCase() &&
                            parseFloat(k.MinuteFee) ==
                                parseFloat(j.runningFee) &&
                            parseFloat(k.UnlockFee) == parseFloat(j.unlockFee)
                    )[0]?.name,
                })),
        }));
    }
    if (type == 'experiment' && pricePlans) {
        pricePlansTemp = pricePlans.map(i => {
            const country = zoneData.filter(k => k.value == i.zoneId)[0]
                ?.contry;
            return {
                ...i,
                country: country,
                pricing: i.pricing.map(j => ({
                    ...j,
                    pricesBand: priceBandsData.filter(
                        k =>
                            k.country?.toUpperCase() ==
                                country?.toUpperCase() &&
                            j.vehicleType.toUpperCase() ==
                                k.vehicleType.toUpperCase() &&
                            parseFloat(k.MinuteFee) ==
                                parseFloat(j.runningFee) &&
                            parseFloat(k.UnlockFee) == parseFloat(j.unlockFee)
                    )[0]?.name,
                })),
            };
        });

    }
    if (type == 'periodic' && pricePlans) {
        pricePlansTemp = pricePlans.map(i => {
            const country = zoneData.filter(k => k.value == i.targetZones[0])[0]
                ?.contry;
            return {
                ...i,
                ctr: country,
                pricing: i.pricing.map(j => ({
                    ...j,
                    pricesBand: priceBandsData.filter(
                        k =>
                            k.country?.toUpperCase() ==
                                country?.toUpperCase() &&
                            j.vehicleType.toUpperCase() ==
                                k.vehicleType.toUpperCase() &&
                            parseFloat(k.MinuteFee) ==
                                parseFloat(j.runningFee) &&
                            parseFloat(k.UnlockFee) == parseFloat(j.unlockFee)
                    )[0]?.name,
                })),
            };
        });
    }

    // console.log('qqq',pricePlansTemp,priceBandsData,pricePlans)

    useEffect(() => {
        revalidatePrcingList();
    }, [location]);

    return (
        <PricingPlansTemplate
            {...props}
            roles={userData?.roles}
            pricePlans={pricePlansTemp}
            pricePlansIsLoading={pricePlansIsLoading}
            type={type}
            changeFilter={data => {
                navigate('/pricing/pricing-plans/' + data);
                revalidatePrcingList();
            }}
        />
    );
};
