import { DataGrid, Text, Box, Button, Card } from 'components';
import { useNavigate } from 'react-router-dom';
import { hasRole } from 'utils';
import { imagePricePark } from './img';

export const HomeTemplate = ({
    priceBands = [],
    priceRequests = [],
    roles = [],
    bookmark = [],
    ...props
}: any) => {
    const navigate = useNavigate();
    const editable = hasRole(roles, ['admin', 'pricing-manager']);
    return (
        <div className="content-wrapper  container" {...props}>
            <Box d="flex" w="fit-content" mt={40}>
                {/* <Text
                    bold
                    variant="title_1"
                    flex={1}
                    sx={{ fontSize: '60px' }}
                    mt={8}
                >
                    Price
                </Text>
                <Text
                    bold
                    variant="title_1"
                    flex={1}
                    sx={{
                        fontSize: '60px',
                        padding: '0px 8px',
                        marginLeft: '7px',
                        borderRadius: '27px',
                        background:
                            'linear-gradient(36deg, rgb(238 174 202 / 24%) 0%, rgb(148 187 233 / 23%) 56%)',
                        border: '1px dashed',
                    }}
                    mt={8}
                >
                    Park
                </Text> */}
                <img height={100} src={imagePricePark}></img>
            </Box>
            <Text variant="caption" flex={1} color="primaryOrange" mt="8px">
                Ver 2.3.0
            </Text>
            <Text variant="caption" flex={1} color="gray5" w="80%" mt={0}>
                A wizard for price management, allowing us to work our magic on
                revenue plans. It's all about tweaking and fine-tuning those
                price values, whether it's through experimental changes or
                simply requesting adjustments. With this tool, we've got the
                power to make pricing changes with a flick of the wrist! ✨🎩
            </Text>
            <Card
                header="Pricing Plans"
                HeaderRightSide={
                    <>
                        <Button
                            size="small"
                            onClick={() => navigate('ab-test-add')}
                            icon="FaPlus"
                            disabled
                            hidden={!editable}
                        >
                            A/B Test
                        </Button>
                        <Button
                            size="small"
                            onClick={() => navigate('periodic-add')}
                            icon="FaPlus"
                            hidden={!editable}
                        >
                            Periodic
                        </Button>
                        <Button
                            size="small"
                            onClick={() => navigate('periodic-add-ical')}
                            icon="FaPlus"
                            hidden={!editable}
                        >
                            Periodic iCal
                        </Button>

                        <Button
                            size="small"
                            onClick={() => navigate('pricing-plans')}
                            icon="FaAngleRight"
                        >
                            Pricing planes
                        </Button>
                    </>
                }
                mt={20}
            >
                <Text bold>
                    Edit and manage the pricing plans here, and obtain an
                    overview of them in a list format
                </Text>
            </Card>
            <Card
                header="Bookmarked Zone"
                // HeaderRightSide={<Button size="small">Add</Button>}
                mt={5}
            >
                <Text bold>
                    This is a local feature that showcases a list of zone
                    pricing values.
                </Text>
                <Text variant="caption">
                    The feature works by storing the pricing as a list of IDs in
                    local storage,fetch and display the current values right on
                    here.
                    <br />
                    You can add or remove items from the list as you please.
                    <br />
                    if you switch to a different browser, use another computer,
                    or perform some browser data cleanup for this site, Your
                    list will always remove.
                </Text>
                <DataGrid noFilter data={bookmark} h={60} />
            </Card>
            {/* <Card
                header="Change requests Prices"
                HeaderRightSide={
                    <>
                        <Button
                            size="small"
                            onClick={() => navigate('price-change-request')}
                            icon="FaPlus"
                        >
                            Change request
                        </Button>
                        <Button
                            size="small"
                            onClick={() => navigate('price-requests')}
                            icon="FaAngleRight"
                        >
                            Price requests
                        </Button>
                    </>
                }
                mt={5}
            >
                <Text bold>
                    "Quick Price Change View"! This is your go-to place for a
                    rapid overview of recent price changes
                </Text>
                <Text variant="caption">
                    You'll find a list of the most recent change requests,
                    sorted in chronological order. The newest requests will be
                    right at the top, so you won't miss a thing.
                </Text>
                <DataGrid noFilter data={priceRequests} h={60} />
            </Card> */}
            {/* <Card
                header="Comercial managers"
                HeaderRightSide={
                    <Button size="small" as="a" href="/users#create=true">
                        Add
                    </Button>
                }
                mt={5}
            >
                <Text variant="caption">
                    This is a quick and easy access to a comprehensive list of
                    commercial managers. You can view, modify, and manage their
                    information whenever necessary
                </Text>
                <DataGrid noFilter data={[]} h={60} />
            </Card> */}
            <Card
                header="Price Bands"
                HeaderRightSide={
                    <>
                        <Button
                            as="a"
                            variant="text"
                            size="small"
                            target="_blank"
                            href="https://docs.google.com/spreadsheets/d/1pmid2rhN9N6j7klKNoDQvKXUTCGelKYrwXzNWWzZCSk"
                        >
                            Open the spreadsheet and edit
                        </Button>
                        <Button
                            size="small"
                            onClick={() => navigate('pricing-bands')}
                            icon="FaAngleRight"
                        >
                            Price bands
                        </Button>
                    </>
                }
                mt={5}
            >
                <Text variant="caption">
                    Here is a view of price bands. Please review the spreadsheet
                    and make any necessary edits. Once the changes are
                    finalized, please request the development team to implement
                    these updates in the application. In the future, we aim to
                    make the price bands directly editable as a list here,
                    without the need for a separate spreadsheet.
                </Text>
                <DataGrid noFilter data={priceBands} h={60} />
            </Card>
            <Box h={20} />
        </div>
    );
};
