import { ABTestEditOrAdd, Icon } from 'components';
import { useParams } from 'react-router-dom';
import {
    updateBasePricing,
    updatePricing,
    useCurrentUser,
    useGetPricingPlan,
    usePriceBands,
    useRootZone,
} from 'services';
import { submitMapper } from './helper';
import { useState } from 'react';

const type = 'experiment';

export const ExperimentDietals = ({ ...props }) => {
    const { id } = useParams();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { priceBandsData, priceBandsupdateDate } = usePriceBands();
    const { data: zoneData } = useRootZone();
    const { data: userData } = useCurrentUser();
    const {
        data: data,
        isLoading: dataLoading,
        mutate: revalidateData,
    } = useGetPricingPlan(id, type);

    const SubmitHandler = async (data, handleClose) => {
        setIsLoadingSubmit(true);
        const body = submitMapper(data);

        await updateBasePricing({
            type: type,
            body,
            method: 'POST',
        });

        revalidateData();
        setIsLoadingSubmit(false);
        // handleClose();
    };

    const DeleteHandler = async handleClose => {
        setIsLoadingSubmit(true);

        await updateBasePricing({
            id: id,
            type: type,
            method: 'DELETE',
        });
        revalidateData();
        setIsLoadingSubmit(false);
        handleClose();
    };

    return (
        <ABTestEditOrAdd
            {...props}
            roles={userData?.roles}
            data={data || {}}
            onClickDelete={DeleteHandler}
            isLoading={dataLoading || isLoadingSubmit}
            edit={true}
            onChange={SubmitHandler}
            priceBandsData={priceBandsData}
            zonesOptions={zoneData}
        />
    );
};
