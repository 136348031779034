import styled, { css } from 'styled-components';
import { defaultTheme } from 'utils/theming';
import { BadgeProps } from './Badge.interface';

export const BadgeStyled = styled.div<BadgeProps>`
    background: none;
    border: none;
    border-radius: 200px;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    gap: ${({ theme }) => theme.space[2]};
    display: flex;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    padding: ${({ theme }) => theme.space[2]};
    font-weight: normal;
    cursor: default;
    line-height: ${({ theme }) => theme.space[4]};
    font-size: ${({ theme }) => theme.space[4]};
    min-height: ${({ theme }) => theme.space[9]};
    transition: all 0.1s ease-out;

    ${({ variant, color, theme }: any) => {
        switch (variant) {
            case 'contained':
                return css`
                    background-color: ${theme.palette[color]};
                    border: ${`1px solid ${theme.palette[color]}`};
                    color: ${theme.palette.white} !important;
                `;
            case 'outlined':
                return css`
                    background-color: ${theme.palette.white};
                    border: 1px solid ${theme.palette[color]};
                    color: ${theme.palette[color]} !important;
                `;
            case 'text':
                return css`
                    color: ${theme.palette[color]} !important;
                    text-decoration: underline;
                    padding-right: ${({ theme }) => theme.space[0]};
                    padding-left: ${({ theme }) => theme.space[0]};
                `;
            default:
                return css``;
        }
    }}

    ${({ size }) => {
        switch (size) {
            case 'small':
                return css`
                    font-size: ${({ theme }) => theme.space[2.5]};
                    line-height: ${({ theme }) => theme.space[1]};
                    min-height: ${({ theme }) => theme.space[4]};
                    max-height: ${({ theme }) => theme.space[4]};
                    gap: ${({ theme }) => theme.space[0.5]};
                `;
            case 'medium':
                return css`
                    font-size: ${({ theme }) => theme.space[4]};
                    line-height: ${({ theme }) => theme.space[5]};
                    max-height: ${({ theme }) => theme.space[9]};
                `;
            default:
                return css`
                    font-size: ${({ theme }) => theme.space[4]};
                    line-height: ${({ theme }) => theme.space[5]};
                    max-height: ${({ theme }) => theme.space[9]};
                `;
        }
    }}
    :disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    ${({ selected }) =>
        selected &&
        css`
            color: ${({ theme }) =>
                theme.palette.primaryVehicleGreen} !important;
        `}
`;

BadgeStyled.defaultProps = defaultTheme;
