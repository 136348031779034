import { useEffect, useState } from 'react';
import VirtualScroll from 'react-dynamic-virtual-scroll';
import {
    Input,
    Box,
    Text,
    Select,
    PricingItem,
    LoadingSpinner,
    ToggleSwitch,
} from 'components';
import { RowData } from './filterData';
import { sortData } from './sortData';
import { BoxCustomsProps } from '../../Atoms/Box/Box.interface';

// const columns = [
//     { field: "name", headerName: "Name" },
//     { field: "targetZones", headerName: "targetZones" },
//     { field: "pricing", headerName: "pricing" },
//     { field: "prilastEditcing", headerName: "lastEdit" },
//     { field: "id", headerName: "ID" },
//     { field: "country", headerName: "country" },
//     { field: "zoneName", headerName: "zoneName" },
//     { field: "escooterPricing", headerName: "escooterPricing" },
//     { field: "ebikePricing", headerName: "ebikePricing" },
//     { field: "timezone", headerName: "timezone" },
//     { field: "OBAFee", headerName: "OBAFee" },
//     { field: "holdFee", headerName: "holdFee" },
//     { field: "convenienceFee", headerName: "convenienceFee" },
//     { field: "vat", headerName: "vat" },
//     { field: "currency", headerName: "currency" },
// ]

const filterData = (data: RowData[], filters): RowData[] => {
    return data.filter(item => {
        for (let key in filters) {
            const filterValue = filters[key];
            if (filterValue && item[key] !== filterValue) {
                return false;
            }
        }
        return true;
    });
};

interface TableSortProps extends BoxCustomsProps {
    data: RowData[];
    loading?: boolean;
    noFilter?: boolean;
    changeFilter?: any;
    metaData?: any;
    type?: any;
}

export const DataGrid = ({
    type,
    changeFilter,
    data = [],
    metaData = { total: 0, count: 0, page: 1, pages: 1 },
    loading = false,
    noFilter,
    ...props
}: TableSortProps) => {
    const [search, setSearch] = useState('');
    const [activeFilter, setActiveFilter] = useState(false);
    const [filters, setFilters] = useState({ type: type, isActive: null });
    const [sortedData, setSortedData]: any = useState(data);
    const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const { count, total, page, pages } = metaData;

    useEffect(() => {
        // if(sortedData)
        // setSortedData(data);
        // setFilters({ ...filters, isactive: activeFilter });
        setFilters({ ...filters, isActive: null });
    }, [type]);
    useEffect(() => {
        setSortedData(filterData(data,filters));
        
        // setFilters({ ...filters, isactive: activeFilter });
    }, [data, filters, search]);
    // const setSorting = (field: keyof RowData) => {
    //     const reversed = field === sortBy ? !reverseSortDirection : false;
    //     setReverseSortDirection(reversed);
    //     setSortBy(field);
    //     setSortedData(sortData(data, { sortBy: field, reversed, search }));
    // };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(
            sortData(data, {
                sortBy,
                reversed: reverseSortDirection,
                search: value,
            })
        );
    };

    const handleFiltersChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { value, name } = event.currentTarget;
        setSearch('');
        setFilters({ ...filters, [name]: value });
        changeFilter(value);
    };



    // const filteredData = filterData(sortedData, filters);
    //   const filteredData = sortedData

    const rows = sortData(sortedData, {
        sortBy,
        reversed: reverseSortDirection,
        search: search,
    }).map((row: any, index) => (
        <PricingItem key={`${row.id}-${index}`} row={row} />
    ));

    return (
        <Box {...props}>
            {!noFilter && (
                <Box d="flex" sx={{ alignItems: 'flex-end' }} round={4} mt={4}>
                    <Box flex={1} d="flex" gap={2}>
                        <Select
                            w={52}
                            label="Type"
                            name="type"
                            value={filters.type}
                            options={[
                                { label: 'Zone', value: 'zones' },
                                { label: 'Country', value: 'countries' },
                                { label: 'Periodic', value: 'periodic' },
                                { label: 'Experiment', value: 'experiment' },
                                // { label: 'pricing', value: 'pricings' },
                            ]}
                            onChange={handleFiltersChange}
                        />
                        {/* <Select w={52}
                            label="Country"
                            name="country"
                            options={[
                                { label: 'All', value: '' },
                                { label: 'Germany', value: 'Germany' },
                                { label: 'Austria', value: 'Austria' },
                                { label: 'Belgium', value: 'Belgium' },
                            ]}
                            onChange={handleFiltersChange}
                        /> */}
                    </Box>
                    <ToggleSwitch
                        w={20}
                        m={2}
                        mr={4}
                        round={2}
                        placeholder="Search Zone or Country"
                        name="active"
                        mb="md"
                        hidden={type == 'countries'}
                        checked={filters.isActive}
                        onChange={e => {
                      
                            setFilters({ ...filters, isActive: e.target.checked });
                        }}
                    >
                        Active
                    </ToggleSwitch>
                    <Input
                        w={80}
                        round={2}
                        placeholder="Search Zone or Country"
                        name="search"
                        mb="md"
                        value={search}
                        onChange={handleSearchChange}
                        icon="FaSearch"
                    />
                </Box>
            )}
            <Box
                style={{
                    height: '100%',
                    overflow: 'auto',
                }}
                bgColor="gray2"
                xs={{}}
                h={props.h || 'calc(100vh - 260px)'}
                round={4}
                mt={4}
            >
                {loading ? (
                    <LoadingSpinner fadeIn mt={'25%'} isLoading={loading} />
                ) : (
                    <>
                        {rows.length ? (
                            <VirtualScroll
                                className="List"
                                style={{
                                    height: '100%',
                                    overflow: 'auto',
                                }}
                                minItemHeight={40}
                                totalLength={total || rows.length}
                                renderItem={rowIndex => {
                                    return rows[rowIndex];
                                }}
                            />
                        ) : (
                            <Text
                                align="center"
                                fadeIn
                                spaceY={
                                    props.h
                                        ? `calc(${props.h + 'px'} /0.6 )`
                                        : '25%'
                                }
                                sx={{ textAlign: 'center' }}
                            >
                                Oops! No Results
                            </Text>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};
