import React, { useRef } from 'react';
import {
    CardStyled,
    HeaderStyled,
    BodyStyled,
    FooterStyled,
    HeaderRightSideStyled,
    FooterLeftSideStyled,
    HeaderBlockStyled,
} from './Card.styled';
import { CardProps } from './Card.interface';
import { Text, Button } from 'components';
import { useState } from 'react';
import { useEffect } from 'react';

export const Card: React.FC<CardProps> = ({
    header,
    footer,
    FooterLeftSide,
    HeaderRightSide,
    subheader,
    children,
    collapsible = false,
    defaultCollapse = false,
    icon,
    bodyProps,
    ...props
}: CardProps) => {
    const [collapsed, setCollapsed] = useState(defaultCollapse === true);
    const ref = useRef(null);
    useEffect(() => {
        setCollapsed(
            typeof defaultCollapse === 'boolean'
                ? defaultCollapse === true
                : defaultCollapse === 'collapsedInMobileSize' &&
                    window.innerWidth <= 576
                    ? true
                    : false
        );
    }, [defaultCollapse]);
    return (
        <CardStyled
            {...props}
            height={ref.current && ref.current['clientHeight']}
            collapsed={collapsed}
            collapsible={collapsible}
        >
            {(header || HeaderRightSide) && (
                <HeaderStyled
                    collapsible={collapsible}
                    collapsed={collapsed}
                    onClick={() => {
                        if (collapsible) {
                            // setCollapsed(!collapsed);
                        }
                    }}
                >
                    <HeaderBlockStyled>
                        <Text className="mainHeader" variant="title_4">
                            {icon && (
                                <></>
                                // <SVG m={'-3px'} icon={icon} mr={1} />
                            )}
                            {header}
                        </Text>
                        <HeaderRightSideStyled>
                            {HeaderRightSide}
                            {collapsible && (
                                <Button
                                    m={'-8px'}
                                    ml={0.5}
                                    h={8}
                                    icon={
                                        collapsed ? 'chevronDown' : 'chevronUp'
                                    }
                                    rounded
                                    size="small"
                                    variant="outlined"
                                    onClick={() => setCollapsed(!collapsed)}
                                    sx={{ border: 'none' }}
                                />
                            )}
                        </HeaderRightSideStyled>
                    </HeaderBlockStyled>
                    {subheader && (
                        <Text className="subheader">
                            {subheader}
                        </Text>
                    )}
                </HeaderStyled>
            )}
            <BodyStyled
                collapsed={collapsed}
                ref={ref}
                {...bodyProps}
                as={typeof children === 'string' && Text}
            >
                {children}
            </BodyStyled>
            {(footer || FooterLeftSide) && (
                <FooterStyled className="footer">
                    <FooterLeftSideStyled>
                        {FooterLeftSide}
                    </FooterLeftSideStyled>
                    <div className="mainFooter">{footer}</div>
                </FooterStyled>
            )}
        </CardStyled>
    );
};
