import { createBrowserRouter, Outlet } from 'react-router-dom';
import {
    PeriodicDiteals,
    Box,
    Text,
    CountriesDiteals,
    PriceChangeRequest,
    ZonesDiteals,
    PeriodicAdd,
    PriceBands,
    PriceRequests,
    PricingPlansPage,
    BookmarckAdd,
    ExperimentDietals,
    PeriodicAddPage,
    ExperimentAddPages,
} from './components';
import { HomePage } from './components/Pages/HomePage/HomePage';

const popUps = [
    {
        path: 'zones-edit/:id',
        element: <ZonesDiteals />,
    },
    {
        path: ':type?/countries-edit/:id',
        element: <CountriesDiteals />,
    },
    {
        path: ':type?/periodic-edit/:id',
        element: <PeriodicDiteals />,
    },
    {
        path: ':type?/periodic-add',
        element: <PeriodicAddPage edit={false} />,
    },
    {
        path: ':type?/periodic-add-ical',
        element: <PeriodicAdd />,
    },
    {
        path: ':type?/ab-Test-add',
        element: <ExperimentAddPages />,
    },
    {
        path: ':type?/experiment-edit/:id',
        element: <ExperimentDietals />,
    },
    {
        path: ':type?/price-change-request',
        element: <PriceChangeRequest edit={false} />,
    },
    {
        path: ':type?/bookmarcks',
        element: <BookmarckAdd />,
    },
];
export const router = createBrowserRouter([
    {
        path: 'pricing',
        element: (
            <>
                <Outlet />
            </>
        ),
        children: [
            {
                path: '',
                element: (
                    <>
                        <HomePage />
                        <Outlet />
                    </>
                ),
                children: popUps,
            },
            {
                path: 'pricing-bands',
                element: <PriceBands />,
            },
            {
                path: 'price-requests',
                element: <PriceRequests />,
            },
            {
                path: 'pricing-plans/:type?',
                element: (
                    <>
                        <PricingPlansPage />
                        <Outlet />
                    </>
                ),
                children: popUps,
            },
        ],
    },

    {
        path: '/',
        element: (
            <Box m={10}>
                <Text variant="title_1" color="gray4">
                    Not found
                </Text>
            </Box>
        ),
    },
]);
