export const mockData = () => {
    const tempData = {
        data: [
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.090626, 50.76819],
                },
                properties: {
                    name: 'AACHEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'AACHEN',
                    uuid: '5efc9435-15a2-45bb-9dd6-e87d482cdc0a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-10T12:28:31.000Z',
                    updated: '2022-08-29T10:34:57.828Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [54.3667, 24.4667],
                },
                properties: {
                    name: 'ABU_DHABI',
                    isActive: true,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'ABU_DHABI',
                    uuid: '8827d620-bdd1-4836-949b-cc51df197bb0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:31.000Z',
                    updated: '2022-08-29T10:34:57.068Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-81.51818, 41.06899],
                },
                properties: {
                    name: 'AKRON',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'AKRON',
                    uuid: '13d4bfcd-9003-4586-bc19-e8b6aaacd455',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T23:01:47.594Z',
                    updated: '2023-02-21T23:01:47.595Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-3.63384, 40.5411],
                },
                properties: {
                    name: 'ALCOBENDAS',
                    isActive: false,
                    country: 'Spain',
                    timezone: 'Europe/Madrid',
                    id: 'ALCOBENDAS',
                    uuid: '700b4520-8c52-4056-b250-2ba9b2308bfa',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-05-22T10:35:44.567Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-77.0359363, 38.8047257],
                },
                properties: {
                    name: 'ALEXANDRIA',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'ALEXANDRIA',
                    uuid: 'd9807726-0fb8-48fc-b3cb-7936589bc6eb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-24T17:18:51.253Z',
                    updated: '2023-03-24T17:18:51.254Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.222124, 52.371353],
                },
                properties: {
                    name: 'ALMERE',
                    isActive: false,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Amsterdam',
                    id: 'ALMERE',
                    uuid: 'cf1978bd-ad38-4d85-b69e-41c346d0dcf0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-28T13:19:59.000Z',
                    updated: '2023-08-09T07:02:03.946Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.225487, 52.356766],
                },
                properties: {
                    name: 'ALMERE-FLORIADE',
                    isActive: false,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Amsterdam',
                    id: 'ALMERE-FLORIADE',
                    uuid: '73ef8748-135b-4645-807a-2d7387730903',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-11T16:41:05.000Z',
                    updated: '2023-05-08T11:52:30.994Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.387827, 52.156113],
                },
                properties: {
                    name: 'AMERSFOORT',
                    isActive: true,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Berlin',
                    id: 'AMERSFOORT',
                    uuid: '1743a94e-9d64-48d7-bb25-673ddc4bc313',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-20T14:54:18.000Z',
                    updated: '2022-08-29T10:34:55.800Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.344664625014, 49.8552996575955],
                },
                properties: {
                    name: 'ANDRYCHOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ANDRYCHOW',
                    uuid: '735ff36c-be90-4f3f-907a-5b1b0ddb3dd1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-07T07:20:40.000Z',
                    updated: '2022-09-07T07:20:40.965Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.563166, 47.478419],
                },
                properties: {
                    name: 'ANGERS',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'ANGERS',
                    uuid: '47548047-13db-4154-9d4c-dfa2beb914c8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-23T12:20:07.000Z',
                    updated: '2023-08-09T07:02:18.173Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-83.86707, 42.2935],
                },
                properties: {
                    name: 'ANN-ARBOR',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'ANN-ARBOR',
                    uuid: 'c91f0b50-08b5-45b3-a2a9-9cdff73b931e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T19:28:09.872Z',
                    updated: '2023-03-23T19:28:09.874Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.127476, 45.900707],
                },
                properties: {
                    name: 'ANNECY',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'ANNECY',
                    uuid: '8e327b28-9b4d-48fb-a507-5987f6867dc1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-06-26T14:31:28.000Z',
                    updated: '2023-08-09T07:02:11.107Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.86965, 43.46337],
                },
                properties: {
                    name: 'AREZZO',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'AREZZO',
                    uuid: '9d4af470-9e5e-4414-b23b-eecc87e2157c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-28T07:56:00.981Z',
                    updated: '2022-09-28T07:56:00.983Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-77.08384508678058, 38.89082300096021],
                },
                properties: {
                    name: 'ARLINGTON',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'ARLINGTON',
                    uuid: 'ec06c7b1-34c5-4c4d-9772-129a15dfe039',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-03T22:32:51.749Z',
                    updated: '2023-01-03T22:32:51.750Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.323242, 60.463949],
                },
                properties: {
                    name: 'ASANE',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'ASANE',
                    uuid: '4e98f276-5a6e-4d7d-932b-74591508b108',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-11-03T15:49:24.000Z',
                    updated: '2023-05-08T11:52:36.358Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.4343, 59.83376],
                },
                properties: {
                    name: 'ASKER',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'ASKER',
                    uuid: '96415952-b4ec-4a65-9730-e3bbaf777915',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-14T09:16:58.000Z',
                    updated: '2022-08-29T10:34:58.453Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [23.728021, 37.983913],
                },
                properties: {
                    name: 'ATHENS',
                    isActive: false,
                    country: 'GREECE',
                    timezone: 'Europe/Athens',
                    id: 'ATHENS',
                    uuid: '432b337a-7eef-4aa9-bbbf-200accb277e1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-09T08:08:03.000Z',
                    updated: '2023-08-09T07:01:51.604Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-85.58496, 32.60143],
                },
                properties: {
                    name: 'AUBURN',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'AUBURN',
                    uuid: '9f133a36-e6ed-4cbb-a815-73f1f4d81e12',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-16T18:46:27.168Z',
                    updated: '2023-04-09T04:03:25.203Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.887585, 48.364745],
                },
                properties: {
                    name: 'AUGSBURG',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'AUGSBURG',
                    uuid: '0028034d-c5d3-4286-b212-30ddaf03c9af',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-06-24T19:27:48.000Z',
                    updated: '2023-08-09T07:01:56.223Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.97811927037384, 53.844091916954],
                },
                properties: {
                    name: 'AUGUSTOW',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'AUGUSTOW',
                    uuid: '6d618a4e-af87-446e-8ab5-546c72b3279f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-20T09:52:14.865Z',
                    updated: '2023-08-09T07:01:35.124Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [4.805954, 43.948716],
                },
                properties: {
                    name: 'AVIGNON',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'AVIGNON',
                    uuid: 'bd8b3386-6335-4655-8916-b38b889865c8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-13T17:34:19.000Z',
                    updated: '2023-08-09T07:01:41.451Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.6217634605279, 47.7104280142576],
                },
                properties: {
                    name: 'Bad-Ischl',
                    isActive: false,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'BAD-ISCHL',
                    uuid: '055b8b83-2b4a-467a-b3a6-3a20fa8e1821',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-26T10:20:29.000Z',
                    updated: '2023-03-16T14:41:24.436Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.2359057597617, 48.0076271110308],
                },
                properties: {
                    name: 'BADEN-BEI-WIEN',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'BADEN-BEI-WIEN',
                    uuid: 'dd41cd8a-ed07-4ce1-8954-024d92451a8f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-17T13:37:34.000Z',
                    updated: '2022-08-29T10:34:59.243Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.520966, 59.890648],
                },
                properties: {
                    name: 'BAERUM',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'BAERUM',
                    uuid: 'dae7a521-df7a-4511-ab5d-15548c049b31',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-04-17T14:06:36.000Z',
                    updated: '2022-08-29T10:34:57.434Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-76.5644679, 39.2941707],
                },
                properties: {
                    name: 'BALTIMORE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'BALTIMORE',
                    uuid: 'a5668bbf-ba98-4273-b0e1-d1b37e00ce6f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-08T21:17:38.449Z',
                    updated: '2023-03-08T21:17:38.450Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.147404646386406, 48.73681910259765],
                },
                properties: {
                    name: 'BANSKA-BYSTRICA',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'BANSKA-BYSTRICA',
                    uuid: '0022c68e-2295-4790-baf9-f6c32a5ae9ca',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T17:15:18.503Z',
                    updated: '2023-03-30T17:15:18.506Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.1686, 41.3874],
                },
                properties: {
                    name: 'BARCELONA',
                    isActive: false,
                    country: 'SPAIN',
                    timezone: 'Europe/Berlin',
                    id: 'BARCELONA',
                    uuid: '8c7775c0-f1c0-46c7-b7e4-0d7850faac26',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-10T08:27:54.000Z',
                    updated: '2023-05-08T11:52:44.583Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.275500535898, 49.2925396690071],
                },
                properties: {
                    name: 'BARDEJOF',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'BARDEJOF',
                    uuid: '392528b4-a6b9-42b8-97d8-346900224fc6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-09T10:33:51.000Z',
                    updated: '2022-09-09T10:33:51.946Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.87, 41.11],
                },
                properties: {
                    name: 'BARI',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'BARI',
                    uuid: '6f17cbd7-ffb4-4274-9ede-910ec0c2ea1c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T08:48:17.000Z',
                    updated: '2022-08-29T10:34:57.451Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.58904, 47.5568],
                },
                properties: {
                    name: 'BASEL',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'BASEL',
                    uuid: 'f676ecab-059b-478c-81f0-ba91a4bb9e9d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:57.119Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [0.475670141549841, 51.5750431045689],
                },
                properties: {
                    name: 'BASILDON',
                    isActive: true,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'BASILDON',
                    uuid: '18d2fe80-9539-4dc9-8c3a-8912d1d5f620',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-19T13:54:09.000Z',
                    updated: '2022-08-29T10:34:58.660Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [34.748, 32.0132],
                },
                properties: {
                    name: 'BAT-YAM',
                    isActive: false,
                    country: 'ISRAEL',
                    timezone: 'Asia/Jerusalem',
                    id: 'BAT-YAM',
                    uuid: '8e49f91f-f1ba-4843-9a0c-9a0e13e17978',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-12T14:26:37.000Z',
                    updated: '2023-03-16T14:41:29.256Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.436631, 51.253745],
                },
                properties: {
                    name: 'BAUNATAL',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BAUNATAL',
                    uuid: 'd573535b-4f03-42f1-a178-0db904edf492',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-04T07:58:03.442Z',
                    updated: '2022-10-04T07:58:03.443Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.1005073339273, 50.3218966981136],
                },
                properties: {
                    name: 'BEDZIN-CZELADZ',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BEDZIN-CZELADZ',
                    uuid: '92c30d50-c746-4827-804e-6b10079facfd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-04T14:17:57.000Z',
                    updated: '2022-08-29T10:34:58.097Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-94.20887939627617, 36.372650684318984],
                },
                properties: {
                    name: 'BENTONVILLE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'BENTONVILLE',
                    uuid: '879cffe9-9b4b-4839-85cf-c6097c631461',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T10:22:47.291Z',
                    updated: '2023-08-06T23:50:18.315Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.671971245330639, 45.6875673253777],
                },
                properties: {
                    name: 'BERGAMO',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'BERGAMO',
                    uuid: '7e8c8d04-a444-4f89-a5de-6107085ba423',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-11-17T08:59:49.640Z',
                    updated: '2023-08-04T07:52:09.095Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.321916, 60.391315],
                },
                properties: {
                    name: 'BERGEN',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'BERGEN',
                    uuid: '68eb912b-8396-4e22-8163-b039902dcf08',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-11-02T11:24:27.000Z',
                    updated: '2023-03-16T14:41:28.757Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.34413, 60.325354],
                },
                properties: {
                    name: 'BERGEN_SOUTH',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'BERGEN_SOUTH',
                    uuid: '62c21b9b-7e42-4da6-b573-dd8ce1896e4d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-15T07:34:49.000Z',
                    updated: '2023-03-16T14:41:29.707Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-122.2920057, 37.8155642],
                },
                properties: {
                    name: 'BERKELEY',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'BERKELEY',
                    uuid: 'e8386b24-7774-40cc-9268-a5bcda88968e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-08T23:54:26.244Z',
                    updated: '2023-08-09T07:02:06.801Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.404954, 52.520008],
                },
                properties: {
                    name: 'BERLIN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BERLIN',
                    uuid: '40cfe9ed-5844-4e5c-9412-518c51a21201',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:29.000Z',
                    updated: '2022-08-29T10:34:57.130Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.29, 52.31],
                },
                properties: {
                    name: 'BERLIN_WAREHOUSE',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'BERLIN_WAREHOUSE',
                    uuid: 'c3752756-c9c9-441d-9449-f6993a335b21',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:55.004Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.553514086050114, 52.43523734299134],
                },
                properties: {
                    name: 'BERLIN-ADLERSHOF',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BERLIN-ADLERSHOF',
                    uuid: '1717f854-f8db-4b19-b395-6101cced85ea',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-19T07:57:30.386Z',
                    updated: '2022-12-19T07:57:30.388Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.509522729625104, 52.64025759281907],
                },
                properties: {
                    name: 'BERLIN-BUCH',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BERLIN-BUCH',
                    uuid: 'aefba597-0073-4b03-b3ad-706f7f9de6f8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-19T08:05:08.724Z',
                    updated: '2022-12-19T08:05:08.726Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.582675840322167, 52.5399423086356],
                },
                properties: {
                    name: 'BERLIN-MARZAHN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BERLIN-MARZAHN',
                    uuid: '8cd62e87-9367-4626-b282-66433857b239',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-19T08:02:08.051Z',
                    updated: '2022-12-19T08:02:08.052Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.44593, 46.9493],
                },
                properties: {
                    name: 'BERN',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'BERN',
                    uuid: 'd6a1ecc7-73a8-4677-863a-b94dccee8fa2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:56.529Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.623251287585333, 50.686125507744805],
                },
                properties: {
                    name: 'BIELAWA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BIELAWA',
                    uuid: '767e50a6-7ce5-4e3e-8958-452b41f2de69',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-01T16:21:08.994Z',
                    updated: '2023-03-01T16:21:08.996Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.533184, 52.028938],
                },
                properties: {
                    name: 'BIELEFELD',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BIELEFELD',
                    uuid: '612175d1-a2e6-4040-9cdb-0c466caadf9c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-04T07:39:57.000Z',
                    updated: '2022-08-29T10:34:58.124Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.4298039630089, 49.9693827800431],
                },
                properties: {
                    name: 'BOCHNIA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BOCHNIA',
                    uuid: 'd4f6d024-3905-4c72-8f6b-89ab0faa2cdf',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-16T14:19:37.000Z',
                    updated: '2023-08-09T07:02:05.619Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.222618, 51.478812],
                },
                properties: {
                    name: 'BOCHUM',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BOCHUM',
                    uuid: '2bfae764-880d-4c32-bdd2-b3e25409c320',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-08-20T09:30:04.000Z',
                    updated: '2022-08-29T10:34:56.541Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.014536, 48.682457],
                },
                properties: {
                    name: 'BOEBLINGEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BOEBLINGEN',
                    uuid: '65f9acad-b5aa-4da5-b8e2-b754380c6549',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-14T16:09:00.000Z',
                    updated: '2022-08-29T10:34:58.686Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.5693778905373, 51.26112082882],
                },
                properties: {
                    name: 'BOLESLAWIEC',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BOLESLAWIEC',
                    uuid: '8c7cac24-d356-45e1-81f0-7ab6ceabc7f4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-06T17:53:06.000Z',
                    updated: '2023-08-04T08:03:08.671Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.09694, 50.73194],
                },
                properties: {
                    name: 'BONN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BONN',
                    uuid: '5c94d362-94d7-4d5d-b43a-817cce07e19f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-23T15:34:48.000Z',
                    updated: '2022-08-29T10:34:57.652Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.9398, 57.721],
                },
                properties: {
                    name: 'BORAS',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'BORAS',
                    uuid: '001a1b4e-0b8f-41b6-be1a-145eca64b2ab',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-04T10:53:05.000Z',
                    updated: '2023-05-03T12:24:15.270Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.577346, 44.838397],
                },
                properties: {
                    name: 'BORDEAUX',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'BORDEAUX',
                    uuid: '52538141-8c22-4a65-8e7a-ba4dfa084d97',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-08-15T12:27:25.000Z',
                    updated: '2022-08-29T10:34:56.556Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.989167, 50.761282],
                },
                properties: {
                    name: 'BORNHEIM',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'BORNHEIM',
                    uuid: '7c837e50-5c9b-413b-a6dd-3c39df4bfaeb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-08T11:02:07.000Z',
                    updated: '2023-08-09T07:02:09.516Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.275194, 45.58925],
                },
                properties: {
                    name: 'BOURGOIN-JALLIEU',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'BOURGOIN-JALLIEU',
                    uuid: 'e9bc80c9-77e1-42aa-a39f-594a3fffa19a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-10T14:05:23.000Z',
                    updated: '2022-08-29T10:34:58.148Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [0.5538386597754084, 51.87787664457991],
                },
                properties: {
                    name: 'BRAINTREE',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'BRAINTREE',
                    uuid: '959c2ba1-9ce2-4bdf-8e44-3ee3d076df60',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-30T15:48:43.074Z',
                    updated: '2023-05-08T11:55:36.533Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.1179379156476, 48.1436876499891],
                },
                properties: {
                    name: 'BRATISLAVA',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Bratislava',
                    id: 'BRATISLAVA',
                    uuid: 'c19b4c35-ec76-41b2-a20d-6cfdccd1d77a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-03T14:39:37.000Z',
                    updated: '2022-08-29T10:34:57.870Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.74138027891971, 47.4977716223946],
                },
                properties: {
                    name: 'BREGENZ',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'BREGENZ',
                    uuid: '2b3cef56-2a6e-4488-a444-84df03904dd8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-21T12:49:04.000Z',
                    updated: '2022-08-29T10:34:56.569Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.812857, 53.083658],
                },
                properties: {
                    name: 'BREMEN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BREMEN',
                    uuid: '96567065-32ea-499c-8d12-ceec43eb633f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-10-01T05:45:02.000Z',
                    updated: '2023-05-01T07:01:33.734Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.58123377346179, 53.5548183652635],
                },
                properties: {
                    name: 'BREMERHAVEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BREMERHAVEN',
                    uuid: '1bbe0e8e-5944-40af-9ea4-fe8f06929f7e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-04T10:11:07.000Z',
                    updated: '2022-08-29T10:34:57.141Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-4.484861469441313, 48.395244785995345],
                },
                properties: {
                    name: 'BREST',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'BREST',
                    uuid: '6016b3db-87ff-405b-b99a-857b1aa8b0e1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-08-03T08:14:33.584Z',
                    updated: '2023-08-09T07:02:16.856Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.15761479886217, 50.8259030438449],
                },
                properties: {
                    name: 'BRIGHTON',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'BRIGHTON',
                    uuid: '9bd467c3-41c0-42ba-accd-81d9d77337aa',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-01T12:04:59.000Z',
                    updated: '2023-08-09T07:02:01.169Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-2.597334399508725, 51.454229296347904],
                },
                properties: {
                    name: 'BRISTOL',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'BRISTOL',
                    uuid: 'f858db3a-9cc6-49ff-92b1-5db9a67c26b2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-14T17:39:15.079Z',
                    updated: '2023-08-09T07:01:38.786Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.6068, 49.1951],
                },
                properties: {
                    name: 'BRNO',
                    isActive: false,
                    country: 'CZECHIA',
                    timezone: 'Europe/Prague',
                    id: 'BRNO-ZLZZCA5DBT',
                    uuid: '8a4841d9-90c9-4169-88c7-5b244a6f95ce',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-10T10:56:48.000Z',
                    updated: '2023-08-09T07:01:34.825Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.6068, 49.1951],
                },
                properties: {
                    name: 'BRNO',
                    isActive: false,
                    country: 'CZECHIA',
                    timezone: 'Europe/Prague',
                    id: 'BRNO',
                    uuid: '4cf2027b-0e63-49de-b9f5-d879d1bd7af6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-10T10:23:53.000Z',
                    updated: '2023-08-09T07:02:10.530Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.589853, 49.123826],
                },
                properties: {
                    name: 'BRUCHSAL',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BRUCHSAL',
                    uuid: '2320370f-2976-46da-b009-1c4884aa28d2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-20T14:48:50.000Z',
                    updated: '2023-08-09T07:01:55.279Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.89930720085744, 50.8293346552139],
                },
                properties: {
                    name: 'BRUEHL',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BRUEHL',
                    uuid: '8c3adf97-44ad-4ae4-a414-d483ffe01b5e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-25T15:27:41.000Z',
                    updated: '2022-08-29T10:34:58.171Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.522415, 52.264632],
                },
                properties: {
                    name: 'BRUNSWICK',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'BRUNSWICK',
                    uuid: '30f89bc8-c25a-4a12-bb30-698292cf2950',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-02-21T01:25:58.000Z',
                    updated: '2022-08-29T10:34:58.469Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [4.34873, 50.8451],
                },
                properties: {
                    name: 'BRUSSELS',
                    isActive: true,
                    country: 'Belgium',
                    timezone: 'Europe/Brussels',
                    id: 'BRUSSEL',
                    uuid: '56851447-2dbf-4510-86ec-ee068216bf9d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:57.165Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.47088984317014, 50.861491687361024],
                },
                properties: {
                    name: 'BRZEG',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BRZEG',
                    uuid: 'e6e989ca-0bf6-4ede-b378-87d65f05d233',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-18T15:38:12.900Z',
                    updated: '2023-08-04T08:08:49.673Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [26.1027550629005, 44.4349282518322],
                },
                properties: {
                    name: 'BUCHAREST',
                    isActive: false,
                    country: 'ROMANIA',
                    timezone: 'Europe/Bucharest',
                    id: 'BUCHAREST',
                    uuid: 'f18e0465-0471-466d-8cf7-2418b3d839d6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-04T08:45:32.000Z',
                    updated: '2023-08-08T15:48:34.957Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.0402, 47.4979],
                },
                properties: {
                    name: 'BUDAPEST',
                    isActive: true,
                    country: 'HUNGARY',
                    timezone: 'Europe/Budapest',
                    id: 'BUDAPEST',
                    uuid: '4e1ea88d-b776-4e5b-8c24-4698e9c17421',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:58.715Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.107812110866092, 49.34239811197429],
                },
                properties: {
                    name: 'BUKOWINA-TATRZANSKA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BUKOWINA-TATRZANSKA',
                    uuid: 'f3b3a60d-8c61-4c02-88ad-1941b5688efa',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-07T08:41:31.448Z',
                    updated: '2023-08-09T07:01:54.751Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-122.3661, 37.5779],
                },
                properties: {
                    name: 'BURLINGAME-MILLBRAE',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'BURLINGAME-MILLBRAE',
                    uuid: 'a3a02709-8ce6-4141-850a-2e933c6a2c50',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-10T15:03:38.601Z',
                    updated: '2023-08-09T07:01:23.514Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.71894569847879, 50.470242213474506],
                },
                properties: {
                    name: 'BUSKO-ZDROJ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BUSKO-ZDROJ',
                    uuid: '59893432-a262-4984-8c7d-9a15b945afca',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-12T14:55:49.256Z',
                    updated: '2023-08-04T08:01:38.565Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.9233055226995, 50.347440949428],
                },
                properties: {
                    name: 'BYTOM',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BYTOM',
                    uuid: '9ce40a7f-1fa9-443e-bd2b-a3dff7e006ad',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-04T14:11:10.000Z',
                    updated: '2023-08-04T08:02:06.160Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.49032462231827, 54.16989935856549],
                },
                properties: {
                    name: 'BYTOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'BYTOW',
                    uuid: '1037251b-a6f8-4c76-a7f5-b706698d4e99',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T14:25:59.821Z',
                    updated: '2023-03-30T14:25:59.822Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.11911688210321, 39.2194590832863],
                },
                properties: {
                    name: 'CAGLIARI',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'CAGLIARI',
                    uuid: '6f682eb7-8dd5-4e54-95f7-f34bfc85df33',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-14T10:59:02.000Z',
                    updated: '2023-08-04T07:53:44.512Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.01285, 50.9658],
                },
                properties: {
                    name: 'CARLSWERK',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'CARLSWERK',
                    uuid: 'ac8b0fd6-b138-430c-9a1e-b6e8d3099295',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:02:12.871Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.12689, 48.977249],
                },
                properties: {
                    name: 'CASGBS',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'CASGBS',
                    uuid: 'fda727d3-fc4c-4473-a5ef-eef90691a42f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-03T11:55:23.000Z',
                    updated: '2022-08-29T10:34:57.663Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.313596, 51.552688],
                },
                properties: {
                    name: 'CASTROP-RAUXEL',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'CASTROP-RAUXEL',
                    uuid: '738fa6a6-21bb-4141-9894-3a0a6ce054d3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-06T10:58:58.000Z',
                    updated: '2023-03-16T14:41:30.603Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.072203, 52.617338],
                },
                properties: {
                    name: 'CELLE',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'CELLE',
                    uuid: '416a6ff6-9512-4c51-a03e-b49a5aefba0a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-08-04T15:48:39.000Z',
                    updated: '2022-08-29T10:34:57.493Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.7666355968471, 43.59642688154722],
                },
                properties: {
                    name: 'CENTRAL-MICHIGAN-UNIVERSITY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'CENTRAL-MICHIGAN-UNIVERSITY',
                    uuid: 'cd502be5-53f6-47f6-84a0-851bc0c4dd8a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-29T19:53:25.021Z',
                    updated: '2023-03-29T19:53:25.023Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-111.8700328, 33.2833915],
                },
                properties: {
                    name: 'CHANDLER',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'CHANDLER',
                    uuid: '6e24f73d-158c-4dc5-8e18-670ba375715b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-27T18:04:10.313Z',
                    updated: '2023-08-09T07:02:05.902Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [4.444134977334414, 50.410728495192046],
                },
                properties: {
                    name: 'CHARLEROI',
                    isActive: true,
                    country: 'BELGIUM',
                    timezone: 'Europe/Brussels',
                    id: 'CHARLEROI',
                    uuid: 'fc147bb7-0b7f-4d24-9fbf-ff1b51048fe9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-06T08:58:15.450Z',
                    updated: '2023-04-06T08:58:15.451Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.425012824980172, 53.348396423570705],
                },
                properties: {
                    name: 'CHELMNO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'CHELMNO',
                    uuid: '645706e5-c359-499a-9334-6c603a62ce2a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-08-02T08:12:27.764Z',
                    updated: '2023-08-02T08:12:27.765Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [0.472421971731904, 51.734669840296],
                },
                properties: {
                    name: 'CHELMSFORD',
                    isActive: true,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'CHELMSFORD',
                    uuid: '5ee2f233-8d08-47a0-97db-d003191a6651',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-19T13:50:08.000Z',
                    updated: '2022-08-29T10:34:58.727Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.920806, 50.832806],
                },
                properties: {
                    name: 'CHEMNITZ',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'CHEMNITZ',
                    uuid: '338f7e41-2381-4884-b9da-b9c25fe278ed',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-11-02T15:09:56.000Z',
                    updated: '2022-08-29T10:34:56.514Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-104.80545, 41.161079],
                },
                properties: {
                    name: 'CHEYENNE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'CHEYENNE',
                    uuid: '8d664ca8-bbb2-47b9-9aeb-b4243380ef9a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-16T12:45:39.080Z',
                    updated: '2023-06-16T12:45:39.081Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-87.662235, 41.91248],
                },
                properties: {
                    name: 'CHICAGO',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'CHICAGO',
                    uuid: '97a3fa48-5df1-40a6-b610-776a7a1ea86e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T06:05:48.918Z',
                    updated: '2023-02-24T06:05:48.919Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.420194904407822, 53.168117892163764],
                },
                properties: {
                    name: 'CHOSZCZNO',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'CHOSZCZNO',
                    uuid: '6a0d4428-8762-4ca1-bed2-5832561c2daa',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-24T09:09:37.223Z',
                    updated: '2023-08-09T07:01:40.502Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.4, 50.1333333],
                },
                properties: {
                    name: 'CHRZANOW_TRZEBINIA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'CHRZANOW-TRZEBINIA',
                    uuid: 'dc93a321-6160-43ea-aabf-bf3965167d3f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-21T12:27:34.000Z',
                    updated: '2022-08-29T10:34:57.182Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.618974649930948, 52.8819654155364],
                },
                properties: {
                    name: 'CIECHANOW',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'CIECHANOW',
                    uuid: '406709c2-0d19-4984-b22f-292b15b87fc1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T14:22:34.876Z',
                    updated: '2023-08-09T07:01:32.262Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-119.70136688790598, 36.824476100762226],
                },
                properties: {
                    name: 'CLOVIS',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'CLOVIS',
                    uuid: 'eaa48566-45ca-4341-9788-eac9e174bf1d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-03T21:41:55.946Z',
                    updated: '2023-08-09T07:01:27.310Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [0.905322700020918, 51.8929928691591],
                },
                properties: {
                    name: 'COLCHESTER',
                    isActive: true,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'COLCHESTER',
                    uuid: '2fd7e154-d9e4-4d06-9c21-a544305b8a2c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-19T13:51:26.000Z',
                    updated: '2022-08-29T10:34:57.911Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.958795, 50.943133],
                },
                properties: {
                    name: 'COLOGNE',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'COLOGNE',
                    uuid: 'a6473e9e-7e9d-4814-8b6f-6b38f40b4312',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-23T10:03:15.000Z',
                    updated: '2022-08-29T10:34:57.203Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-83.0006407813727, 39.9622380969294],
                },
                properties: {
                    name: 'COLUMBUS',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'COLUMBUS',
                    uuid: '36d66a26-07e1-4723-953f-e99950bfbf7d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-23T13:52:10.000Z',
                    updated: '2022-08-29T10:34:58.989Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.5719, 55.6806],
                },
                properties: {
                    name: 'COPENHAGEN',
                    isActive: true,
                    country: 'Denmark',
                    timezone: 'Europe/Copenhagen',
                    id: 'COPENHAGEN',
                    uuid: 'e6a20099-cc26-470f-bfae-2bf225d38e38',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:58.485Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.3332687145698, 51.758494569249],
                },
                properties: {
                    name: 'COTTBUS',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'COTTBUS',
                    uuid: '2b20230e-1f5f-4585-ae63-339353471728',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-25T07:16:25.000Z',
                    updated: '2022-08-29T10:34:58.198Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.702897, 53.861834],
                },
                properties: {
                    name: 'CUXHAVEN',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'CUXHAVEN',
                    uuid: '5cf5aea7-cad9-46b6-8bb7-2490b15f7481',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T15:56:42.000Z',
                    updated: '2023-08-09T07:01:58.915Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.66532732370007, 50.156310347300575],
                },
                properties: {
                    name: 'CZERWIONKA-LESZCZYNY',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'CZERWIONKA-LESZCZYNY',
                    uuid: '08619e32-ef09-40d4-8f5a-72715bc019df',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-11-03T13:49:10.048Z',
                    updated: '2023-08-04T08:03:08.570Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.1131288462997, 50.8120105349954],
                },
                properties: {
                    name: 'CZESTOCHOWA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'CZESTOCHOWA',
                    uuid: 'f1fa2310-6467-4c15-99d3-e87d91f822d0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-09T11:56:14.000Z',
                    updated: '2023-08-04T08:03:34.859Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.186709805860488, 50.32544435584006],
                },
                properties: {
                    name: 'DABROWA-GORNICZA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'DABROWA-GORNICZA',
                    uuid: '3d7cda3b-bf1d-4086-ad57-f470c0d24ac1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-17T12:28:17.378Z',
                    updated: '2023-01-17T12:28:17.380Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.4442895036972, 48.2546610427489],
                },
                properties: {
                    name: 'DACHAU',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DACHAU',
                    uuid: 'c0ef894b-3178-4409-b2a3-f47ddfd89abd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-18T10:33:19.000Z',
                    updated: '2023-05-08T12:04:29.854Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.656217, 49.874763],
                },
                properties: {
                    name: 'DARMSTADT',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DARMSTADT',
                    uuid: '7dcc96b5-9677-4f24-b7a4-66b4ae845b38',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-22T16:31:42.000Z',
                    updated: '2022-08-29T10:34:58.213Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-121.740517, 38.544907],
                },
                properties: {
                    name: 'DAVIS',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'DAVIS',
                    uuid: '7f6ab411-b876-45c9-bba5-6b622c9d81ef',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-20T15:59:40.264Z',
                    updated: '2023-08-09T07:02:10.125Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.144522, 39.7747918],
                },
                properties: {
                    name: 'DAYTON',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'DAYTON',
                    uuid: '1a141172-a5b0-4147-97c9-17993cbffc06',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-08T21:16:00.737Z',
                    updated: '2023-03-08T21:16:00.739Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.612462, 53.048243],
                },
                properties: {
                    name: 'DELMENHORST',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DELMENHORST',
                    uuid: '97133e4f-c404-4025-ba34-a158988906ce',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-18T08:46:45.000Z',
                    updated: '2023-08-09T06:50:53.967Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.872647, 51.940495],
                },
                properties: {
                    name: 'DETMOLD',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DETMOLD',
                    uuid: 'e4c5777a-560d-494b-a049-0c3a75d81c33',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-08-06T13:51:35.000Z',
                    updated: '2022-08-29T10:34:57.002Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [51.515011376309, 25.3400904839539],
                },
                properties: {
                    name: 'DOHA',
                    isActive: true,
                    country: 'QATAR',
                    timezone: 'Asia/Qatar',
                    id: 'DOHA',
                    uuid: '905ac066-5630-4398-942a-a8dc198383c9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-02T08:35:43.000Z',
                    updated: '2022-08-29T10:34:58.497Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.739449, 47.414413],
                },
                properties: {
                    name: 'DORNBIRN',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'DORNBIRN',
                    uuid: '8e0142ec-cb8a-42b0-a5bd-fb9461670b95',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-07T14:50:29.000Z',
                    updated: '2022-08-29T10:34:56.583Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.459178, 51.517251],
                },
                properties: {
                    name: 'DORTMUND',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DORTMUND',
                    uuid: 'd3a5363d-5fb0-4cc1-8d69-b9731422ed26',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-19T06:53:14.000Z',
                    updated: '2023-04-03T10:22:45.768Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.2015232, 59.7402271],
                },
                properties: {
                    name: 'DRAMMEN',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'DRAMMEN',
                    uuid: '545766cc-386a-4ff1-b3bb-3fc2f91bf072',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-01T12:37:58.000Z',
                    updated: '2023-03-16T14:41:29.857Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.738389, 51.0525],
                },
                properties: {
                    name: 'DRESDEN',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'DRESDEN',
                    uuid: '0a6b9baa-72ba-4f19-8f67-84d3af07198d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-02T17:19:39.000Z',
                    updated: '2023-03-30T08:38:03.632Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [55.2708, 25.2048],
                },
                properties: {
                    name: 'DUBAI',
                    isActive: true,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'DUBAI',
                    uuid: '53e0f952-c934-4545-b124-166399f73c57',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:58.508Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [55.254513238218834, 25.100899299870306],
                },
                properties: {
                    name: 'DUBAI-HILLS',
                    isActive: false,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'DUBAI-HILLS',
                    uuid: '4ec18047-5beb-4d3b-8ef1-fd18528291ba',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-29T11:27:52.479Z',
                    updated: '2023-08-09T07:02:02.855Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-6.2601149, 53.3494636],
                },
                properties: {
                    name: 'Dublin',
                    isActive: false,
                    country: 'IRELAND',
                    timezone: 'Europe/Dublin',
                    id: 'DUBLIN',
                    uuid: 'df6ee39a-fa75-4ebf-adf7-2555d085bb8e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-09-18T09:02:12.000Z',
                    updated: '2023-08-09T07:02:12.560Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-6.256699, 53.385984],
                },
                properties: {
                    name: 'DUBLIN_UNIVERSITY',
                    isActive: true,
                    country: 'IRELAND',
                    timezone: 'Europe/Dublin',
                    id: 'DUBLIN_UNIVERSITY',
                    uuid: 'fa5118eb-ce2f-432f-a2d8-70e07fe601cd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-24T15:15:31.000Z',
                    updated: '2022-08-29T10:34:58.266Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.794295, 51.219786],
                },
                properties: {
                    name: 'DUESSELDORF',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DUESSELDORF',
                    uuid: '9b8ff141-f3c5-4c14-b5f5-2f3a0358eb1f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-23T10:02:15.000Z',
                    updated: '2022-08-29T10:34:58.283Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.765903, 51.426742],
                },
                properties: {
                    name: 'DUISBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'DUISBURG',
                    uuid: '5a71f85e-22bd-4c2f-abf3-f0a7f8db87b0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-06T10:56:27.000Z',
                    updated: '2023-03-22T09:35:22.391Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-78.88013, 36.02093],
                },
                properties: {
                    name: 'DURHAM',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'DURHAM',
                    uuid: '48afdca5-0ffb-46b5-82e9-1b6ef79266cb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T06:07:02.482Z',
                    updated: '2023-02-24T06:07:02.483Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.6511466878423, 50.7283056078294],
                },
                properties: {
                    name: 'DZIERZONIOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'DZIERZONIOW',
                    uuid: '19103462-1f07-494c-ad9a-8a9829a27a8f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-08T08:29:18.000Z',
                    updated: '2022-09-08T08:29:18.630Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.54519, 42.73777],
                },
                properties: {
                    name: 'EAST-LANSING',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'EAST-LANSING',
                    uuid: '2c96cdb3-9f76-471e-8289-9695da37d654',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T21:23:12.979Z',
                    updated: '2023-03-15T21:23:12.981Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-122.69522, 45.53924],
                },
                properties: {
                    name: 'EAST-PORTLAND',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'EAST-PORTLAND',
                    uuid: 'ce3f9391-35ce-4ea7-8c9f-33d7db063791',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-09T19:17:33.519Z',
                    updated: '2023-03-09T19:17:33.520Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-83.6251512, 42.2508232],
                },
                properties: {
                    name: 'EASTERN-MICHIGAN-UNIVERSITY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'EASTERN-MICHIGAN-UNIVERSITY',
                    uuid: '697aade0-dc3e-4730-b472-0ce17388d9d5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T23:07:56.609Z',
                    updated: '2023-03-23T23:07:56.610Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.4792, 51.4434],
                },
                properties: {
                    name: 'EINDHOVEN',
                    isActive: true,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Amsterdam',
                    id: 'EINDHOVEN',
                    uuid: '23644f47-67a3-40be-9b45-2e914936200f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-01T19:59:23.000Z',
                    updated: '2022-08-29T10:34:57.038Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.4257686134843, 54.1787901538725],
                },
                properties: {
                    name: 'ELBLAG',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ELBLAG',
                    uuid: 'a4c47484-b363-42fc-a27e-a483f9695887',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T12:40:42.000Z',
                    updated: '2023-08-09T07:02:00.878Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.361922033786243, 53.82560013438558],
                },
                properties: {
                    name: 'ELK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ELK',
                    uuid: '86db5866-9961-4496-988e-888f2203da81',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-25T15:08:00.342Z',
                    updated: '2023-04-25T15:08:00.344Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.65724045568575, 53.7540452888968],
                },
                properties: {
                    name: 'ELMSHORN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ELMSHORN',
                    uuid: 'ac2eba1e-0787-4f64-ba0b-0e8ffa30ec06',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-21T13:10:39.000Z',
                    updated: '2022-08-29T10:34:57.568Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.018762, 50.980285],
                },
                properties: {
                    name: 'ERFURT',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ERFURT',
                    uuid: '51da39bd-758d-4cc4-84b3-2f2a8a986c65',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-27T07:42:05.000Z',
                    updated: '2022-08-29T10:34:57.937Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.002307, 49.596197],
                },
                properties: {
                    name: 'ERLANGEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ERLANGEN',
                    uuid: '537cf831-8e03-4ad6-a9e9-626dc33f080d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-17T16:20:13.000Z',
                    updated: '2022-08-29T10:34:57.955Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.509674, 59.371098],
                },
                properties: {
                    name: 'ESKILSTUNA',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'ESKILSTUNA',
                    uuid: 'ae16f5fd-57c1-49fd-8e8c-615976932d00',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-07T11:11:19.000Z',
                    updated: '2022-08-29T10:34:57.677Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [24.766567, 60.17229],
                },
                properties: {
                    name: 'ESPOO',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'ESPOO',
                    uuid: 'f875e394-9b01-429d-a5ab-366c915a38fb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-09-04T15:59:20.000Z',
                    updated: '2023-04-07T03:28:04.780Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.014516, 51.451493],
                },
                properties: {
                    name: 'ESSEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ESSEN',
                    uuid: '229e2db3-6cda-4154-aa52-9575194bb48d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-08-20T09:30:50.000Z',
                    updated: '2022-08-29T10:34:58.296Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.388261, 48.946849],
                },
                properties: {
                    name: 'ETTLINGEN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ETTLINGEN',
                    uuid: 'a0315373-a2c4-4b54-afdd-f540eb9ef303',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-20T14:50:43.000Z',
                    updated: '2023-08-09T07:02:15.894Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.3562, 52.4813],
                },
                properties: {
                    name: 'EUREF',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'EUREF',
                    uuid: '391c7774-a983-4b15-8dd3-f7b413757048',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:24.266Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [55.1579196588472, 24.9716415760209],
                },
                properties: {
                    name: 'EXPO-2020',
                    isActive: true,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'EXPO-2020',
                    uuid: '39be4b8b-4827-40b2-80ae-9a5fef25c78e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-27T10:55:34.000Z',
                    updated: '2023-03-21T11:24:23.102Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-94.168852, 36.035058],
                },
                properties: {
                    name: 'FAYETTEVILLE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'FAYETTEVILLE',
                    uuid: 'd1f72057-3168-49a5-8dbb-8068fe3a5e39',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T22:57:29.915Z',
                    updated: '2023-02-21T22:57:29.917Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-6.21842930931348, 53.4566110395068],
                },
                properties: {
                    name: 'FINGAL',
                    isActive: true,
                    country: 'IRELAND',
                    timezone: 'Europe/Dublin',
                    id: 'FINGAL',
                    uuid: 'b6dcf9bd-24e9-4772-8f17-6311c6dc8cfc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-06T10:52:51.000Z',
                    updated: '2022-08-29T10:34:57.541Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.44699, 54.79374],
                },
                properties: {
                    name: 'FLENSBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'FLENSBURG',
                    uuid: '1e166c5a-cf7f-468d-b9c9-c309c62773d8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-22T11:30:13.000Z',
                    updated: '2022-08-29T10:34:56.591Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.80152, 55.276955],
                },
                properties: {
                    name: 'FOLKEMOEDET',
                    isActive: false,
                    country: 'DENMARK',
                    timezone: 'Europe/Berlin',
                    id: 'FOLKEMOEDET',
                    uuid: '79395340-a4fa-4aff-9ea9-d575ad662afd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-03T08:29:24.000Z',
                    updated: '2023-08-09T07:02:08.811Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.05717936342927, 44.2205938621712],
                },
                properties: {
                    name: 'FORLI',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'FORLI',
                    uuid: '94617455-faf5-4365-8b60-01d0dc43c4fc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-11T12:37:36.718Z',
                    updated: '2023-08-03T08:21:51.581Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-105.02114, 40.58274],
                },
                properties: {
                    name: 'FORT-COLLINS',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'FORT-COLLINS',
                    uuid: 'cb7ec9db-6b98-45db-af2b-97965f20a83e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T22:16:47.324Z',
                    updated: '2023-03-15T22:16:47.326Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.68, 50.11],
                },
                properties: {
                    name: 'FRANKFURT',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'FRANKFURT',
                    uuid: 'bb41f2ba-2bf9-42e4-809d-34e1268b6a42',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-22T14:52:57.000Z',
                    updated: '2022-08-29T10:34:57.967Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-121.98817183960468, 37.54775207450625],
                },
                properties: {
                    name: 'FREMONT',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'FREMONT',
                    uuid: '10c7b7ff-d2ac-4469-a01d-69f3cbffafed',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-03T21:32:51.085Z',
                    updated: '2023-08-09T07:02:09.161Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.482993, 47.657579],
                },
                properties: {
                    name: 'FRIEDRICHSHAFEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'FRIEDRICHSHAFEN',
                    uuid: 'de550884-ed4a-4119-986b-a961bc08807f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-03T13:26:56.000Z',
                    updated: '2023-04-27T18:24:18.112Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.989917, 49.470148],
                },
                properties: {
                    name: 'FUERTH',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'FUERTH',
                    uuid: '93e2c2a3-21f1-4d34-b6b5-e3e8390d81d8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-17T16:20:29.000Z',
                    updated: '2023-08-09T07:01:58.256Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.73168049897447, 48.341137833009185],
                },
                properties: {
                    name: 'GAENSERNDORF',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'GAENSERNDORF',
                    uuid: 'acca846e-f377-489b-b8db-87517e5a4bb0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-02T09:51:16.006Z',
                    updated: '2023-06-02T09:51:16.008Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-82.3249723577121, 29.651900257159923],
                },
                properties: {
                    name: 'GAINESVILLE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'GAINESVILLE',
                    uuid: '49d790dc-1522-4597-baf1-c43aab3784e9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-15T17:21:23.385Z',
                    updated: '2022-12-15T17:21:23.387Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.6466, 54.352],
                },
                properties: {
                    name: 'GDANSK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GDANSK',
                    uuid: '492f62f5-6601-4139-b6a9-4ac2bd91e7ca',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-05T14:34:40.000Z',
                    updated: '2022-08-29T10:34:57.054Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.08674308685611, 51.5174260322836],
                },
                properties: {
                    name: 'GELSENKIRCHEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'GELSENKIRCHEN',
                    uuid: '634997b9-a2e4-432a-b343-09e0f3a98f85',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-03T15:06:01.000Z',
                    updated: '2023-06-30T18:23:48.777Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.143158, 46.204391],
                },
                properties: {
                    name: 'GENEVA',
                    isActive: false,
                    country: 'Switzerland',
                    timezone: 'Europe/Berlin',
                    id: 'GENEVA',
                    uuid: '82f2b839-b1a8-49fe-9a26-12796ff3ac0c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-05-18T12:16:56.000Z',
                    updated: '2023-08-03T16:36:59.504Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.079753259274156, 50.87718048790583],
                },
                properties: {
                    name: 'GERA',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'GERA',
                    uuid: 'a992a051-6192-446b-aae2-7491b2f2d041',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-19T11:54:37.784Z',
                    updated: '2023-04-19T11:54:37.786Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.5465532409835, 52.5017804291765],
                },
                properties: {
                    name: 'GIFHORN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'GIFHORN',
                    uuid: 'c57a40d5-9dc7-4cb1-b4a7-4f59b21674cb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-11-16T15:13:59.000Z',
                    updated: '2022-08-29T10:34:58.528Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [34.8089, 32.0722],
                },
                properties: {
                    name: 'GIVATAYIM',
                    isActive: false,
                    country: 'ISRAEL',
                    timezone: 'Europe/Athens',
                    id: 'GIVATAYIM',
                    uuid: 'ee2cd100-3309-4322-ad7d-126bb99ca1b8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-31T11:06:57.000Z',
                    updated: '2023-08-09T07:01:37.473Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.769611404939326, 54.03612370568814],
                },
                properties: {
                    name: 'GIZYCKO',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GIZYCKO',
                    uuid: 'f21148e9-c2ff-407f-a5ef-83106fce516d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:17:47.789Z',
                    updated: '2023-08-09T07:01:42.329Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.6655922512694, 50.2932377390554],
                },
                properties: {
                    name: 'GLIWICE',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GLIWICE',
                    uuid: '5b8a1ec8-78f9-4294-a31e-84c1537d7151',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-04T14:09:21.000Z',
                    updated: '2023-08-03T16:15:03.489Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.397668, 55.663302],
                },
                properties: {
                    name: 'GLOSTRUP',
                    isActive: false,
                    country: 'DENMARK',
                    timezone: 'Europe/Copenhagen',
                    id: 'GLOSTRUP',
                    uuid: 'bce2ebd4-a410-45ee-bf58-11232f11357d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-05-29T10:10:50.000Z',
                    updated: '2023-08-09T07:01:36.878Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.38339570658266, 50.31709671713645],
                },
                properties: {
                    name: 'GLUCHOLAZY',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GLUCHOLAZY',
                    uuid: 'b0afba16-7112-46fb-b774-08a301c9038f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-27T14:16:59.274Z',
                    updated: '2023-02-27T14:16:59.276Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.35608151715556, 47.600240241173964],
                },
                properties: {
                    name: 'GODOLLO',
                    isActive: true,
                    country: 'HUNGARY',
                    timezone: 'Europe/Budapest',
                    id: 'GODOLLO',
                    uuid: 'b64e9cec-57e7-4cb9-af41-3e69e3423afb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-22T11:45:53.692Z',
                    updated: '2023-03-22T11:45:53.694Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.831048, 53.56799],
                },
                properties: {
                    name: 'GOLENIOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GOLENIOW',
                    uuid: '2f2bc79c-118e-4a28-8c34-d346ee1d7240',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-03T08:57:56.000Z',
                    updated: '2022-08-29T10:34:57.241Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.15772286027243, 49.657733108581084],
                },
                properties: {
                    name: 'GORLICE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GORLICE',
                    uuid: '0a51fb14-eaac-4a04-8676-e9ec4f28ac58',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-20T12:14:23.859Z',
                    updated: '2023-01-20T12:14:23.861Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.230223, 52.735665],
                },
                properties: {
                    name: 'GORZOW_WIELKOPOLSKI',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GORZOW_WIELKOPOLSKI',
                    uuid: 'bf959e9f-3aff-4e97-88b1-97e457b311c0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-03T09:01:17.000Z',
                    updated: '2023-03-16T14:41:30.058Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.9807, 57.709],
                },
                properties: {
                    name: 'GOTHENBURG',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'GOTHENBURG',
                    uuid: 'abf420a7-b7b7-4fdd-80ff-71aa5136bfca',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-03-16T14:41:29.687Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [1.91199, 48.99055],
                },
                properties: {
                    name: 'GPSEO',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'GPSEO',
                    uuid: '4d5b67d9-0806-45d9-9641-e8219438d5e5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-20T13:36:45.892Z',
                    updated: '2023-01-20T13:36:45.893Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.4449, 47.0659],
                },
                properties: {
                    name: 'GRAZ',
                    isActive: false,
                    country: 'Austria',
                    timezone: 'Europe/Vienna',
                    id: 'GRAZ',
                    uuid: '5ac258a2-937e-4961-8711-5ecada0253f1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:02:12.207Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.38942, 54.093196],
                },
                properties: {
                    name: 'GREIFSWALD',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'GREIFSWALD',
                    uuid: '0d10912e-9e95-446c-962f-053c4cd670c1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-06T08:36:39.000Z',
                    updated: '2022-08-29T10:34:58.795Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.725266, 45.188737],
                },
                properties: {
                    name: 'GRENOBLE',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'GRENOBLE',
                    uuid: '53a0ddc7-3c34-444c-910e-f89912713352',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-12-12T08:57:40.000Z',
                    updated: '2023-03-16T14:41:29.481Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.6262158736185, 52.1070241054458],
                },
                properties: {
                    name: 'GRODZISK-MAZOWIECKI',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GRODZISK-MAZOWIECKI',
                    uuid: 'b08cdb35-c04f-452d-a047-684b51efad2c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T12:44:18.000Z',
                    updated: '2022-08-29T10:34:57.528Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.7524265937853, 53.4846399176056],
                },
                properties: {
                    name: 'GRUDZIADZ',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GRUDZIADZ',
                    uuid: '7e15a351-5c5e-49ca-817c-2bcadeaeae66',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T12:47:06.000Z',
                    updated: '2022-08-29T10:34:57.700Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.494411423781287, 53.25053455170874],
                },
                properties: {
                    name: 'GRYFINO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'GRYFINO',
                    uuid: '8f173eea-3bf2-43ca-ba95-6330cbd0137b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-12T10:22:51.107Z',
                    updated: '2023-06-12T10:22:51.109Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.38361, 51.907557],
                },
                properties: {
                    name: 'GUETERSLOH',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'GUETERSLOH',
                    uuid: '31f9540b-b170-4164-8de2-ddf50987c460',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-10-30T12:57:07.000Z',
                    updated: '2022-08-29T10:34:56.596Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.633288996747893, 47.694820159655535],
                },
                properties: {
                    name: 'GYOR',
                    isActive: true,
                    country: 'HUNGARY',
                    timezone: 'Europe/Budapest',
                    id: 'GYOR',
                    uuid: '72f9e353-6c02-4928-8c13-1cf560f34852',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-11T10:23:16.171Z',
                    updated: '2022-10-11T10:23:16.173Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.9658, 51.48217],
                },
                properties: {
                    name: 'HALLE (SAALE)',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HALLE-(SAALE)',
                    uuid: '4e28fecc-0381-45ff-ab6f-d1c3066b4f20',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-10T11:50:21.000Z',
                    updated: '2022-08-29T10:34:58.020Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.8546194, 56.6731311],
                },
                properties: {
                    name: 'HALMSTAD',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'HALMSTAD',
                    uuid: 'dd1576ba-4188-4d63-90ee-04e0bf0b3ac8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-23T12:25:14.000Z',
                    updated: '2023-03-16T14:41:31.110Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.006637, 53.552906],
                },
                properties: {
                    name: 'HAMBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HAMBURG',
                    uuid: 'f9112799-5b98-4b56-a0cc-1e030ac2c34a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-23T10:04:26.000Z',
                    updated: '2022-08-29T10:34:57.268Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.8095272630065, 51.6800543857387],
                },
                properties: {
                    name: 'HAMM',
                    isActive: true,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'HAMM',
                    uuid: '78dc78e7-9ed9-44df-8a8f-de0241aa8328',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-18T17:13:04.000Z',
                    updated: '2022-08-29T10:34:57.078Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.740648, 52.376683],
                },
                properties: {
                    name: 'HANNOVER',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HANNOVER',
                    uuid: 'd0f7d267-2e07-499f-95db-6eb020812983',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-06-27T09:47:43.000Z',
                    updated: '2022-08-29T10:34:57.092Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.981909, 53.46035],
                },
                properties: {
                    name: 'HARBURG',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HARBURG',
                    uuid: 'cea4c62f-2682-4b53-87c8-3d25ed948df1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-10T07:55:54.000Z',
                    updated: '2023-08-09T07:01:43.227Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.2681776, 59.413832],
                },
                properties: {
                    name: 'HAUGESUND',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'HAUGESUND',
                    uuid: '449bc39e-3391-4a0b-af0b-5fae43daf408',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-01T12:38:57.000Z',
                    updated: '2023-08-09T07:01:55.855Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.675912, 49.403997],
                },
                properties: {
                    name: 'HEIDELBERG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HEIDELBERG',
                    uuid: '203f4d84-533e-4323-9573-76f021461f1a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-22T16:29:26.000Z',
                    updated: '2022-08-29T10:34:57.713Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.209259, 49.142644],
                },
                properties: {
                    name: 'Heilbronn',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HEILBRONN',
                    uuid: 'c5d3b821-fe8b-4d35-9793-d7b4833cdc2b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-11-11T09:59:56.000Z',
                    updated: '2022-08-29T10:34:57.538Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.7875041, 54.6369868],
                },
                properties: {
                    name: 'HEL',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'HEL',
                    uuid: '33938154-6494-4956-b4a9-c804a932a171',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T15:26:57.000Z',
                    updated: '2023-08-09T07:02:13.214Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.6945, 56.0465],
                },
                properties: {
                    name: 'HELSINGBORG',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'HELSINGBORG',
                    uuid: 'cc3024c2-78d5-42dd-a07d-3016b78e1393',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-05T14:33:24.000Z',
                    updated: '2022-08-29T10:34:56.624Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [24.9191, 60.1643],
                },
                properties: {
                    name: 'HELSINKI',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'HELSINKI',
                    uuid: '8c7c29ad-6ed1-44b0-882f-dd4391eb5f4a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-04-07T03:27:31.212Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.31503490574801, 50.7684218579717],
                },
                properties: {
                    name: 'HENNEF',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HENNEF',
                    uuid: '622f3df8-0582-447f-a3b9-92313586b3d7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-01T10:41:04.000Z',
                    updated: '2022-08-29T10:34:58.038Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.664977, 52.119275],
                },
                properties: {
                    name: 'HERFORD',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HERFORD',
                    uuid: '67332aa7-a240-4303-800f-87a96eff3d09',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-02-06T13:24:38.000Z',
                    updated: '2022-08-29T10:34:58.809Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.22449335626134, 51.5398016542762],
                },
                properties: {
                    name: 'HERNE',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HERNE',
                    uuid: '02c93f60-0a9f-4bd0-b92c-c2ad1527b318',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T12:37:44.000Z',
                    updated: '2022-08-29T10:34:58.548Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.967079, 56.137374],
                },
                properties: {
                    name: 'HERNING',
                    isActive: false,
                    country: 'DENMARK',
                    timezone: 'Europe/Copenhagen',
                    id: 'HERNING',
                    uuid: 'd9252dcc-a33f-4d46-9d1e-8ddd50643da4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-23T16:46:45.000Z',
                    updated: '2023-03-16T14:41:30.131Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.9564, 53.6031],
                },
                properties: {
                    name: 'HH TENDER',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HH-TENDER',
                    uuid: 'ad73e7a5-7e1c-4ad3-b73d-75034647ce6f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-28T09:06:28.000Z',
                    updated: '2023-08-09T07:01:28.483Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.936771, 51.168855],
                },
                properties: {
                    name: 'HILDEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HILDEN',
                    uuid: '8788024e-2950-4d3d-a5fa-9c12e20e8cbd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-07-27T11:47:01.000Z',
                    updated: '2022-08-29T10:34:56.633Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.951, 52.1509],
                },
                properties: {
                    name: 'HILDESHEIM',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'HILDESHEIM',
                    uuid: '90b07441-3855-4f52-be1d-1923ee5cb091',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-10T07:21:26.000Z',
                    updated: '2022-08-29T10:34:56.649Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [34.7777754351159, 32.014249370432],
                },
                properties: {
                    name: 'HOLON',
                    isActive: false,
                    country: 'ISRAEL',
                    timezone: 'Europe/Athens',
                    id: 'HOLON',
                    uuid: 'dd70129d-e764-4325-a09a-abad9b9e9b49',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-11T12:38:46.000Z',
                    updated: '2023-03-16T14:41:30.076Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.5549443350029, 58.320484802259],
                },
                properties: {
                    name: 'HORNBORGARSJON',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'HORNBORGARSJON',
                    uuid: 'bdfff14c-eb82-420e-b50f-6730888c7348',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-31T11:10:09.000Z',
                    updated: '2023-03-16T14:41:30.192Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.90158211639391, 48.93474179252996],
                },
                properties: {
                    name: 'HUMENNE',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'HUMENNE',
                    uuid: '3906708b-bca0-48bc-ae8c-e7bce10fa8ba',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-04T16:03:43.884Z',
                    updated: '2022-10-04T16:03:43.886Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.425832, 48.759799],
                },
                properties: {
                    name: 'INGOLSTADT',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'INGOLSTADT',
                    uuid: '044cf70b-a309-40bf-bff8-ffc97e61c197',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-22T16:34:08.000Z',
                    updated: '2022-08-29T10:34:58.060Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.39454, 47.26266],
                },
                properties: {
                    name: 'INNSBRUCK',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'INNSBRUCK',
                    uuid: 'c557c52c-256a-41b0-91b7-913234583fbf',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-22T12:28:14.000Z',
                    updated: '2022-08-29T10:34:58.558Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.60802939386315, 49.95269624883676],
                },
                properties: {
                    name: 'JASTRZEBIE-ZDROJ',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'JASTRZEBIE-ZDROJ',
                    uuid: '3e88f069-7014-43df-a837-ecf1b0ebe4e0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-20T12:08:45.500Z',
                    updated: '2023-01-20T12:08:45.501Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.193602378556278, 51.050317517802],
                },
                properties: {
                    name: 'JAWOR',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'JAWOR',
                    uuid: 'f393ccc5-56a3-4dfa-9054-a8d13ea5c565',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-23T10:06:06.081Z',
                    updated: '2023-08-04T08:04:42.231Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.2700183, 50.2044444],
                },
                properties: {
                    name: 'JAWORZNO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'JAWORZNO',
                    uuid: '493f4272-a514-4eb9-a090-ea5bd2b8d645',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-21T11:55:53.000Z',
                    updated: '2022-08-29T10:34:57.550Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [39.176856, 21.563752],
                },
                properties: {
                    name: 'JEDDAH',
                    isActive: true,
                    country: 'SAUDI ARABIA',
                    timezone: 'Asia/Riyadh',
                    id: 'JEDDAH',
                    uuid: '2bf393a9-65a2-40b8-89b0-68e62b38981f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-26T11:07:15.000Z',
                    updated: '2022-08-29T10:34:57.279Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.73430939326937, 50.90317289961686],
                },
                properties: {
                    name: 'JELENIA-GORA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'JELENIA-GORA',
                    uuid: '04f45ea1-ae06-41eb-9447-49b30b513f43',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-10T09:27:17.251Z',
                    updated: '2023-05-19T07:10:38.384Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.587907, 50.924794],
                },
                properties: {
                    name: 'JENA',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'JENA',
                    uuid: 'c4058e3d-2a3a-4481-961f-2c6c1f5b89fa',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-16T11:14:52.000Z',
                    updated: '2022-08-29T10:34:58.073Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.1725220067637, 60.1424742573482],
                },
                properties: {
                    name: 'JESSHEIM',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'JESSHEIM',
                    uuid: '1693b9ad-5323-4757-bfad-6a10cb8ea7da',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-20T15:22:35.000Z',
                    updated: '2023-03-16T14:41:27.749Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [29.7637937379458, 62.600603867712],
                },
                properties: {
                    name: 'JOENSUU',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'JOENSUU',
                    uuid: 'f62e49b2-3876-48bc-8748-f73905be8b18',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-02-16T15:01:16.000Z',
                    updated: '2023-04-17T05:54:10.048Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.1618, 57.7826],
                },
                properties: {
                    name: 'JONKOPING',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'JONKOPING',
                    uuid: '79bbce93-23b2-42a9-81e2-93bd426062cf',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-04T10:53:47.000Z',
                    updated: '2023-03-16T14:41:30.023Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.7169456, 54.6824846],
                },
                properties: {
                    name: 'JURATA_JASTARINA',
                    isActive: false,
                    country: 'Poland',
                    timezone: 'Europe/Warsaw',
                    id: 'JURATA',
                    uuid: '7b6a21c1-c6b0-4275-99d1-0ffeee5ba1d1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T15:23:37.000Z',
                    updated: '2023-08-09T07:01:44.216Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [25.74735, 62.242623],
                },
                properties: {
                    name: 'JYVASKYLA',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'JYVASKYLA',
                    uuid: 'f45e74c4-c868-401c-818e-951e4bc4c92e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-07T11:08:52.000Z',
                    updated: '2023-04-06T16:09:59.033Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.31, 46.62],
                },
                properties: {
                    name: 'KAERNTEN',
                    isActive: false,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'KAERNTEN',
                    uuid: '55942b4a-7849-4bab-84f2-9352118aec99',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-22T14:51:57.000Z',
                    updated: '2023-08-09T07:02:02.471Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.769667, 49.445448],
                },
                properties: {
                    name: 'KAISERSLAUTERN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'KAISERSLAUTERN',
                    uuid: 'fc50b242-5a5b-41f0-b47a-9baa6900f8ba',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-11-21T17:38:09.000Z',
                    updated: '2022-08-29T10:34:58.135Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.089526947943426, 51.7625318678042],
                },
                properties: {
                    name: 'KALISZ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KALISZ',
                    uuid: '1107562c-328f-44c0-aebd-3558fcfcc87f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-30T07:03:18.550Z',
                    updated: '2023-08-04T08:53:04.100Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.403653, 49.006889],
                },
                properties: {
                    name: 'KARLSRUHE',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'KARLSRUHE',
                    uuid: '5e21af7e-5a93-4625-af3a-d9b9a52dd0cc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-10T06:53:49.000Z',
                    updated: '2022-08-29T10:34:58.821Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.51, 59.4],
                },
                properties: {
                    name: 'KARLSTAD',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'KARLSTAD',
                    uuid: 'ae505b36-5f65-429e-a3c8-c9c14030f905',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-02T11:42:51.000Z',
                    updated: '2023-03-16T14:41:30.293Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.498355, 51.311876],
                },
                properties: {
                    name: 'KASSEL',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'KASSEL',
                    uuid: 'f9995b46-8fde-4e79-a2dd-855d757ebf87',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-23T07:43:31.000Z',
                    updated: '2022-08-29T10:34:58.412Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.0235896254982, 50.2644228211588],
                },
                properties: {
                    name: 'KATOWICE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KATOWICE',
                    uuid: '12aed620-21a2-4599-b9bf-be608e22b24e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-10T12:31:08.000Z',
                    updated: '2022-08-29T10:34:58.582Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.2047785557579, 50.3438567353147],
                },
                properties: {
                    name: 'KEDZIERZYN-KOZLE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KEDZIERZYN-KOZLE',
                    uuid: '57e0a869-94c9-4f54-88e1-886e344a90f5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-04T14:06:19.000Z',
                    updated: '2022-08-29T10:34:58.646Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-81.345373, 41.146139],
                },
                properties: {
                    name: 'KENT-STATE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'KENT-STATE',
                    uuid: '748ab459-c23a-4ae8-8adc-c23973fd9914',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-22T18:37:42.782Z',
                    updated: '2023-03-18T09:26:12.189Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.38584575979172, 54.072977970576375],
                },
                properties: {
                    name: 'KETRZYN',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KETRZYN',
                    uuid: '33c08aff-24d9-4c29-8de9-d2cc940fe1c2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:26:46.985Z',
                    updated: '2023-08-09T07:02:18.795Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.221496766866778, 49.883426682866315],
                },
                properties: {
                    name: 'KETY',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KETY',
                    uuid: 'c587d361-5521-4bb4-a6fe-9a60b37b93bb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-06T14:26:52.152Z',
                    updated: '2023-04-06T14:26:52.154Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.1234615, 54.3221335],
                },
                properties: {
                    name: 'KIEL',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'KIEL',
                    uuid: '4c82a7f2-0d62-4e74-94aa-15aa73996240',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-27T13:36:55.000Z',
                    updated: '2022-08-29T10:34:56.650Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.89441690277144, 48.427859057983056],
                },
                properties: {
                    name: 'KIRCHBERG-AM-WAGRAM',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'KIRCHBERG-AM-WAGRAM',
                    uuid: '25fce1c6-bc19-41c1-b292-7410cadc8c20',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-02T09:46:22.346Z',
                    updated: '2023-06-02T09:46:22.347Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.312856, 46.615494],
                },
                properties: {
                    name: 'KLAGENFURT',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'KLAGENFURT',
                    uuid: 'c4d05433-d5ba-43e5-a6cd-fef9e277f7a3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-03T10:12:22.000Z',
                    updated: '2022-08-29T10:34:58.148Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.9144517576709, 51.5353808526291],
                },
                properties: {
                    name: 'KLETTWITZ',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'KLETTWITZ',
                    uuid: '6e22275f-11bb-4bd5-8993-301c739e2726',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-28T11:43:13.000Z',
                    updated: '2023-08-09T07:02:03.156Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.654584042238753, 50.43749180405197],
                },
                properties: {
                    name: 'KLODZKO',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KLODZKO',
                    uuid: 'b2d0d959-3b5b-4a5c-a8d9-d5f249e364a2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-12T15:03:26.640Z',
                    updated: '2023-08-04T08:08:01.148Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.5801745534422, 46.5984836213899],
                },
                properties: {
                    name: 'KLOPEIN',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'KLOPEIN',
                    uuid: 'c0a1ebe8-d3e7-467d-b294-01431efd052c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-19T12:47:34.000Z',
                    updated: '2022-08-29T10:34:56.664Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [23.1249742453405, 63.8415775485069],
                },
                properties: {
                    name: 'KOKKOLA',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'KOKKOLA',
                    uuid: 'c537822b-bd20-4768-9c99-80016cc50b22',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-20T12:47:42.000Z',
                    updated: '2023-04-14T10:55:33.964Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.576944, 54.177778],
                },
                properties: {
                    name: 'KOLOBRZEG',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KOLOBRZEG',
                    uuid: '0e33e552-ec3c-49d7-8628-663f34967bc9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-09T14:58:41.000Z',
                    updated: '2022-08-29T10:34:58.592Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.1081194975793, 52.083034428032],
                },
                properties: {
                    name: 'KONSTANCIN-JEZIORNA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KONSTANCIN-JEZIORNA',
                    uuid: '199831cf-6bbf-4ef4-ac38-b6f9d2ed88d7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-23T16:03:55.000Z',
                    updated: '2023-08-09T07:01:34.046Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.2838156, 48.3448097],
                },
                properties: {
                    name: 'KORNEUBURG',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'KORNEUBURG',
                    uuid: '5693d753-bcca-4ea8-9fdc-6f9e57e416a1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-07-17T12:28:16.000Z',
                    updated: '2022-08-29T10:34:58.161Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.980217856104474, 54.12151295861318],
                },
                properties: {
                    name: 'KOSCIERZYNA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KOSCIERZYNA',
                    uuid: '77895744-55ce-4222-a4c9-180afdbd98e1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:46:02.947Z',
                    updated: '2023-02-21T20:46:02.949Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.2501156610801, 48.7165646095431],
                },
                properties: {
                    name: 'KOSICE',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Warsaw',
                    id: 'KOSICE',
                    uuid: '4a032b46-2f7a-4070-8830-75c525e650de',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-25T11:49:14.000Z',
                    updated: '2022-08-29T10:34:58.603Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.2175308, 54.2072711],
                },
                properties: {
                    name: 'KOSZALIN',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KOSZALIN',
                    uuid: '9c8f9c31-a1b7-43b7-815a-a3a22d43d3d6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T15:25:13.000Z',
                    updated: '2022-08-29T10:34:56.678Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.980397, 50.041174],
                },
                properties: {
                    name: 'KRAKOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KRAKOW',
                    uuid: '8b9bd7aa-51a5-4650-86e0-ea0aec1befcc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-08T12:38:53.000Z',
                    updated: '2022-08-29T10:34:57.572Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.9880399805934, 50.4845085426456],
                },
                properties: {
                    name: 'KRAPKOWICE-GOGOLIN',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KRAPKOWICE-GOGOLIN',
                    uuid: '177127b2-7ba8-4f7b-bc50-489b7e390873',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-07-28T08:45:47.000Z',
                    updated: '2023-05-18T08:57:21.117Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.75900981015147, 49.689739733093745],
                },
                properties: {
                    name: 'KROSNO',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KROSNO',
                    uuid: '8e13213d-3e31-46c4-8225-418ad033e66f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-08-10T10:23:57.189Z',
                    updated: '2023-08-10T10:29:34.410Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.445952225988037, 54.38313448409539],
                },
                properties: {
                    name: 'KRYNICA-MORSKA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KRYNICA-MORSKA',
                    uuid: '008a84aa-c850-49ee-b382-fe82be771919',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:48:51.943Z',
                    updated: '2023-08-09T07:02:14.803Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [27.6784607333809, 62.8922483923288],
                },
                properties: {
                    name: 'KUOPIO',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'KUOPIO',
                    uuid: '3b9132cc-9f5a-494d-a5c8-c3e8d94a6b0c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-02-16T10:46:06.000Z',
                    updated: '2023-04-12T08:58:50.222Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.353906876168338, 52.2333591896797],
                },
                properties: {
                    name: 'KUTNO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'KUTNO',
                    uuid: 'f962fcce-03d1-4ad6-91ca-164d96878cbb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-25T15:04:58.401Z',
                    updated: '2023-04-25T15:04:58.403Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [25.661175, 60.982577],
                },
                properties: {
                    name: 'LAHTI',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'LAHTI',
                    uuid: 'a4648925-b298-4543-bd47-6f7255039b74',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-07T13:12:18.000Z',
                    updated: '2023-04-06T05:08:56.703Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.947268, 51.102061],
                },
                properties: {
                    name: 'LANGENFELD',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'LANGENFELD',
                    uuid: '870f1115-5f34-4862-a305-b52ddcf8c39a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-12T13:03:18.000Z',
                    updated: '2022-08-29T10:34:58.425Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.54519, 42.73777],
                },
                properties: {
                    name: 'LANSING',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'LANSING',
                    uuid: 'b64f0d55-893b-4e64-97d4-3f90a1a813a4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T19:33:01.059Z',
                    updated: '2023-03-15T19:33:01.061Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [28.193507644192, 61.0592879061522],
                },
                properties: {
                    name: 'LAPPEENRANTA',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'LAPPEENRANTA',
                    uuid: '21c4dfc3-4074-4b57-bec6-9687fd713483',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-02-16T15:04:51.000Z',
                    updated: '2023-04-14T06:56:19.084Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-15.43566147304577, 28.12638011024677],
                },
                properties: {
                    name: 'LAS-PALMAS',
                    isActive: true,
                    country: 'SPAIN',
                    timezone: 'Europe/London',
                    id: 'LAS-PALMAS',
                    uuid: 'b9ac71c7-ccbd-4ece-8de9-ef97c8ecc9ca',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-19T14:19:02.624Z',
                    updated: '2023-01-19T14:19:02.626Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.63468, 46.5247],
                },
                properties: {
                    name: 'LAUSANNE',
                    isActive: false,
                    country: 'Switzerland',
                    timezone: 'Europe/Zurich',
                    id: 'LAUSANNE',
                    uuid: '03aa5144-e41e-4d61-91f0-c84ffae7e8cc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-03T16:37:18.014Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [0.10646, 49.49028],
                },
                properties: {
                    name: 'LE_HAVRE',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'LE_HAVRE',
                    uuid: '383512f9-0702-47ab-b739-a654db1882c1',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-19T14:32:10.000Z',
                    updated: '2023-08-09T07:01:31.973Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [1.851883, 48.693943],
                },
                properties: {
                    name: 'LE-PERRAY-EN-YVELINES',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'LE-PERRAY-EN-YVELINES',
                    uuid: '89519c18-be9d-4529-8970-a475e2691b3d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-26T11:11:59.000Z',
                    updated: '2023-08-09T07:01:36.210Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.5580869406515, 54.7575844164751],
                },
                properties: {
                    name: 'LEBA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LEBA',
                    uuid: '21267311-14b1-4a17-b711-20bd516052d5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-22T11:25:48.000Z',
                    updated: '2022-08-29T10:34:57.395Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.74459776435208, 54.53924351259937],
                },
                properties: {
                    name: 'LEBORK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LEBORK',
                    uuid: '8f2850b9-e92d-4f51-9275-2717618f45af',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-04T09:04:35.184Z',
                    updated: '2022-10-04T09:04:35.185Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.375639, 51.34025],
                },
                properties: {
                    name: 'LEIPZIG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'LEIPZIG',
                    uuid: '1b4c1962-1619-4ff5-a927-66654fdf3c78',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-06-26T16:02:34.000Z',
                    updated: '2022-08-29T10:34:56.693Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.093830493571232, 47.38243340533404],
                },
                properties: {
                    name: 'LEOBEN',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'LEOBEN',
                    uuid: '136c88bf-50e1-49a7-965b-b6fcaafaa78b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T10:10:32.812Z',
                    updated: '2023-03-15T10:10:32.813Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.574585627626, 51.8427917874338],
                },
                properties: {
                    name: 'LESZNO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LESZNO',
                    uuid: 'ce088416-d7c3-4536-b942-26defd64786b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-06T05:24:47.000Z',
                    updated: '2022-09-06T05:24:48.133Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.98729, 51.03375],
                },
                properties: {
                    name: 'LEVERKUSEN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'LEVERKUSEN',
                    uuid: '7e209522-4d2c-4e40-a07f-ab0f46ab3410',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-10T11:51:37.000Z',
                    updated: '2023-08-09T07:01:56.562Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.51421, 38.04958],
                },
                properties: {
                    name: 'LEXINGTON',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'LEXINGTON',
                    uuid: '03766117-46fb-4bbf-add7-ce775e55a975',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T06:23:27.119Z',
                    updated: '2023-02-24T06:23:27.120Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.57827, 50.6376],
                },
                properties: {
                    name: 'LIEGE',
                    isActive: true,
                    country: 'Belgium',
                    timezone: 'Europe/Brussels',
                    id: 'LIEGE',
                    uuid: '8558f2fb-2e7d-4544-9aff-34979337f332',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:58.654Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [3.0586, 50.633],
                },
                properties: {
                    name: 'LILLE',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'LILLE',
                    uuid: 'af58ee31-b106-44da-8463-b27adec2ec0a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-07-03T05:33:05.835Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.0429224, 59.9540833],
                },
                properties: {
                    name: 'LILLESTROM',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'LILLESTROM',
                    uuid: '62d0a924-8178-4cbd-800b-db28c0159c87',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-01T11:45:30.000Z',
                    updated: '2022-08-29T10:34:58.173Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.418949325796717, 49.704784223406904],
                },
                properties: {
                    name: 'LIMANOWA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LIMANOWA',
                    uuid: 'f83ab52f-1f07-4ff0-ba8e-1f3cd8f527bd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-17T12:19:59.827Z',
                    updated: '2023-08-04T08:04:33.723Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-8.595170003387295, 52.66103117401707],
                },
                properties: {
                    name: 'LIMERICK',
                    isActive: true,
                    country: 'IRELAND',
                    timezone: 'Europe/Dublin',
                    id: 'LIMERICK',
                    uuid: 'f8c85b38-1ec2-4d69-991f-3e511e28feac',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-28T08:46:04.656Z',
                    updated: '2023-04-13T09:43:47.169Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.687412, 47.554739],
                },
                properties: {
                    name: 'LINDAU',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'LINDAU',
                    uuid: 'f0fe17bf-aafd-4470-81d3-6d488caa6b7b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-28T13:26:55.000Z',
                    updated: '2022-08-29T10:34:58.665Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.6214, 58.4108],
                },
                properties: {
                    name: 'LINKOPING',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'LINKOPING',
                    uuid: '81b62723-5a62-4be9-a57e-550e7b308b11',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-01-22T10:57:27.000Z',
                    updated: '2022-08-29T10:34:58.679Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.2879, 48.3062],
                },
                properties: {
                    name: 'LINZ',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'LINZ',
                    uuid: '6aa00887-5862-4503-9ff9-5fae2ca6dc68',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:57.772Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-9.14396, 38.7065],
                },
                properties: {
                    name: 'LISBON',
                    isActive: false,
                    country: 'PORTUGAL',
                    timezone: 'Europe/Lisbon',
                    id: 'LISBON',
                    uuid: 'f873779d-48dc-4ec9-b8fb-a2151fe34b37',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:51.319Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.8869861524034, 52.3337250805558],
                },
                properties: {
                    name: 'LOMIANKI',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LOMIANKI',
                    uuid: '002857ab-2b67-4d00-8734-9dffcb7433f5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-13T19:19:11.000Z',
                    updated: '2022-08-29T10:34:57.319Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.109478907789196, 51.5062062145959],
                },
                properties: {
                    name: 'LONDON',
                    isActive: true,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'LONDON',
                    uuid: 'e6b4e7a3-9635-46d0-97c5-550c3bff9a87',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-06-24T12:08:03.000Z',
                    updated: '2022-08-29T10:34:57.786Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.10985175594120733, 51.521947356966336],
                },
                properties: {
                    name: 'LONDON-DEMO',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'LONDON-DEMO',
                    uuid: '776ad088-20e2-43bd-957c-9d01d9b2bfc5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-12T11:56:30.454Z',
                    updated: '2023-08-09T07:02:09.807Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.104583172432864, 51.5461352496089],
                },
                properties: {
                    name: 'LONDON-ISLINGTON',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'LONDON-ISLINGTON',
                    uuid: 'b5f4218a-54ac-4431-acf4-fdb4f8712c0e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-13T13:48:11.000Z',
                    updated: '2023-08-09T07:02:10.787Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.954936, 59.928142],
                },
                properties: {
                    name: 'LORENSKOG',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'LORENSKOG',
                    uuid: '36d27d01-403a-4d9c-ac5b-7c23f1bd75a5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-24T07:33:45.000Z',
                    updated: '2022-08-29T10:34:57.330Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-91.1784686, 30.4116463],
                },
                properties: {
                    name: 'LOUISIANA-STATE-UNIVERSITY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'LOUISIANA-STATE-UNIVERSITY',
                    uuid: 'f5932e96-1593-4793-879e-efd78c78e66d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T00:06:11.263Z',
                    updated: '2023-05-21T01:01:16.568Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.6826016141303, 50.6690359262155],
                },
                properties: {
                    name: 'LUBLINIEC',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LUBLINIEC',
                    uuid: '2f0beefc-772a-4a78-a2a5-340a25da4fe3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-04T14:13:36.000Z',
                    updated: '2022-08-29T10:34:58.392Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.122149521828163, 52.029464194154116],
                },
                properties: {
                    name: 'LUBNA-TEMPSTORAGE',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LUBNA-TEMPSTORAGE',
                    uuid: '2721464b-8bbe-4c43-a715-161cce3e77ba',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-07T12:39:19.041Z',
                    updated: '2023-08-09T07:01:47.794Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.18631, 48.89239],
                },
                properties: {
                    name: 'LUDWIGSBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'LUDWIGSBURG',
                    uuid: 'c82e1097-3c30-4d6b-b6a8-af56f9810422',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-01T15:34:17.000Z',
                    updated: '2022-08-29T10:34:58.437Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.68729, 53.86893],
                },
                properties: {
                    name: 'LUEBECK',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'LUEBECK',
                    uuid: 'd7060691-f051-472c-a147-64826a2812a7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-14T09:05:25.000Z',
                    updated: '2022-08-29T10:34:58.691Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.380375376745235, 51.92814053941373],
                },
                properties: {
                    name: 'LUKOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'LUKOW',
                    uuid: 'c20486f4-6f3e-4b20-a41b-7cbb3654fd10',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-28T16:20:50.976Z',
                    updated: '2023-02-28T16:20:50.977Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.1677, 55.7131],
                },
                properties: {
                    name: 'LUND',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'LUND',
                    uuid: '3bc6022f-89e9-44e5-b424-53bc35761e1f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:57.343Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.4140519267168372, 51.88234249535432],
                },
                properties: {
                    name: 'LUTON',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'LUTON',
                    uuid: 'bcc357d4-7711-49c8-ad1c-a8652d5637f3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T14:35:02.251Z',
                    updated: '2023-08-09T07:02:08.385Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [4.85896, 45.7609],
                },
                properties: {
                    name: 'LYON',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'LYON',
                    uuid: '2591dfef-9442-4a9d-8838-14e8c7f96887',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:56.719Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-3.7, 40.41],
                },
                properties: {
                    name: 'MADRID',
                    isActive: true,
                    country: 'Spain',
                    timezone: 'Europe/Madrid',
                    id: 'MADRID',
                    uuid: '42fdc911-194a-4b3a-b050-f3c53b48570b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:29.000Z',
                    updated: '2022-08-29T10:34:58.703Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.626614, 52.130432],
                },
                properties: {
                    name: 'MAGDEBURG',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MAGDEBURG',
                    uuid: 'b144aa54-bbb9-4785-a691-010c631e5bc3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-10-29T14:19:59.000Z',
                    updated: '2023-08-09T07:01:25.695Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.259117, 50.001433],
                },
                properties: {
                    name: 'MAINZ',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MAINZ',
                    uuid: '36eba137-c989-4ae4-848d-7e03f083b1e7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-11-18T16:21:56.000Z',
                    updated: '2022-08-29T10:34:57.586Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-4.42, 36.72],
                },
                properties: {
                    name: 'MALAGA',
                    isActive: true,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'MALAGA',
                    uuid: '528af8e8-afc9-48fd-bb05-7912a12aec05',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:56.700Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.0337174637317, 54.0372908770187],
                },
                properties: {
                    name: 'MALBORK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MALBORK',
                    uuid: '629a17cd-89f3-45f2-8f5e-9eabb9b85010',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-29T12:44:31.000Z',
                    updated: '2022-08-29T10:34:56.711Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.0037, 55.6036],
                },
                properties: {
                    name: 'MALMO',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'MALMO',
                    uuid: '939a3469-8aac-41bd-9dec-6024b50483e2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-03-16T14:41:31.688Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.656757908057529, 63.43799918142594],
                },
                properties: {
                    name: 'MALVIK',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'MALVIK',
                    uuid: 'b59690ee-6584-437e-8334-2e703261c8a5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-06T09:49:12.731Z',
                    updated: '2023-08-09T07:01:52.951Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [50.565344, 26.218152],
                },
                properties: {
                    name: 'MANAMA',
                    isActive: false,
                    country: 'BAHRAIN',
                    timezone: 'Asia/Bahrain',
                    id: 'MANAMA',
                    uuid: '103904b9-93e6-43d9-bc66-e0779b46fc26',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-16T11:19:25.000Z',
                    updated: '2023-03-16T14:41:30.478Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-2.2548, 53.4831],
                },
                properties: {
                    name: 'MANCHESTER',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'MANCHESTER',
                    uuid: '728219d6-82ba-42b1-a5d3-be2cfe500efb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-08-27T09:50:22.000Z',
                    updated: '2023-08-09T07:01:22.636Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.469492, 49.479757],
                },
                properties: {
                    name: 'MANNHEIM-LUDWIGSHAFEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MANNHEIM-LUDWIGSHAFEN',
                    uuid: '1d7fb764-f5ff-4c81-8186-423a6af7ed51',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-23T10:01:17.000Z',
                    updated: '2022-08-29T10:34:56.742Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.37202, 43.2965],
                },
                properties: {
                    name: 'MARSEILLE',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'MARSEILLE',
                    uuid: '943074e7-d4ca-4579-8593-6aef3788ad67',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:53.528Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.921429033507565, 49.06269625653431],
                },
                properties: {
                    name: 'MARTIN',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'MARTIN',
                    uuid: '082edd17-7355-4935-8504-2dc5de37e1dc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-23T12:55:07.644Z',
                    updated: '2023-06-23T12:55:07.646Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.625062, 51.26959],
                },
                properties: {
                    name: 'MEERBUSCH',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MEERBUSCH',
                    uuid: '2c814316-5f7c-49cc-a75b-29a614422573',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-26T17:59:29.000Z',
                    updated: '2022-08-29T10:34:58.884Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.278180785259224, 63.28554485250396],
                },
                properties: {
                    name: 'MELHUS',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'MELHUS',
                    uuid: '33eb2402-fe97-4c1b-9d96-cfaf55c1bc93',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-06T09:47:04.195Z',
                    updated: '2023-08-09T07:02:14.500Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-104.84282, 39.55136],
                },
                properties: {
                    name: 'MERIDIAN',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'MERIDIAN',
                    uuid: '0114385d-a5d4-4b00-b38b-7826faf4c378',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T22:17:59.260Z',
                    updated: '2023-03-15T22:17:59.262Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.926225766599, 48.7550172655178],
                },
                properties: {
                    name: 'MICHALOVCE',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'MICHALOVCE',
                    uuid: 'a3734ab4-3c9d-439f-a7c6-3129726cfd67',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-07-05T08:19:15.000Z',
                    updated: '2022-08-29T10:34:58.379Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.54519, 42.73777],
                },
                properties: {
                    name: 'MICHIGAN-STATE-UNIVERSITY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'MICHIGAN-STATE-UNIVERSITY',
                    uuid: 'd06ddb08-891b-456d-afdf-4f1138dfcf19',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T21:44:15.734Z',
                    updated: '2023-03-15T21:44:15.737Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.7444214122136, 54.0219872788384],
                },
                properties: {
                    name: 'MIEDZYWODZIE-DZIWNOW-DZIWNOWEK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MIEDZYWODZIE-DZIWNOW-DZIWNOWEK',
                    uuid: 'c9db8e45-2e1c-4355-adc6-03a85794701e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-27T13:21:03.000Z',
                    updated: '2023-04-28T09:29:55.611Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.4329204559326, 53.9197158813477],
                },
                properties: {
                    name: 'MIEDZYZDROJE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MIEDZYZDROJE',
                    uuid: '12b1f852-f1f7-47c8-941a-c44720b335a8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T15:22:04.000Z',
                    updated: '2022-08-29T10:34:57.798Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.0604665724895, 54.2597007095878],
                },
                properties: {
                    name: 'MIELNO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MIELNO',
                    uuid: 'c9c10790-ce2e-4b4a-8598-4d0735ac94ff',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-22T10:58:37.000Z',
                    updated: '2023-06-20T09:57:24.558Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [27.272302, 61.689688],
                },
                properties: {
                    name: 'MIKKELI',
                    isActive: false,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'MIKKELI',
                    uuid: '8e5632de-d92f-40c5-9c58-001c85564c22',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-07-16T08:39:09.000Z',
                    updated: '2023-08-03T13:38:37.819Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.57369138701705, 53.80313195645624],
                },
                properties: {
                    name: 'MIKOLAJKI',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MIKOLAJKI',
                    uuid: '2c7f754e-35ff-46ce-a069-e2d43b5e66f7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:19:40.699Z',
                    updated: '2023-08-09T07:01:59.493Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.9014323995507, 50.1689924054752],
                },
                properties: {
                    name: 'MIKOLOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MIKOLOW',
                    uuid: 'f5d04fa0-3c07-454c-b757-11e51adc1dda',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-02T14:35:21.000Z',
                    updated: '2022-08-29T10:34:58.076Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.19312, 45.4699],
                },
                properties: {
                    name: 'MILAN',
                    isActive: true,
                    country: 'Italy',
                    timezone: 'Europe/Rome',
                    id: 'MILAN',
                    uuid: 'df49562c-4d79-4270-a7c2-74944da7322f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:56.754Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.746964676810052, 52.0248856181584],
                },
                properties: {
                    name: 'MILTON-KEYNES',
                    isActive: true,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'MILTON-KEYNES',
                    uuid: '1e3e162f-9b09-47ce-aab4-a1f34a335408',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-08T09:21:31.000Z',
                    updated: '2022-08-29T10:34:58.063Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-88.050185, 43.006745],
                },
                properties: {
                    name: 'MILWAUKEE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'MILWAUKEE',
                    uuid: '034a3762-cbaa-4654-9deb-a13198469155',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T19:26:58.444Z',
                    updated: '2023-03-23T19:26:58.445Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.91389, 52.291],
                },
                properties: {
                    name: 'MINDEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MINDEN',
                    uuid: '33328645-4912-422e-b5e1-423b7499c96c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-02T11:18:39.000Z',
                    updated: '2022-08-29T10:34:58.187Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-93.26896, 45.11061],
                },
                properties: {
                    name: 'MINNEAPOLIS',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'MINNEAPOLIS',
                    uuid: 'd6203eaf-46ab-4003-a819-9de25ab11865',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T21:48:13.145Z',
                    updated: '2023-03-15T21:48:13.145Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.5587372438058, 52.1826370107572],
                },
                properties: {
                    name: 'MINSK-MAZOWIECKI',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MINSK-MAZOWIECKI',
                    uuid: 'bfcf8506-a1dc-4872-a2cf-6f0598df4615',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-02T14:24:26.000Z',
                    updated: '2023-08-09T06:49:04.217Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.78936496824565, 48.103134594831005],
                },
                properties: {
                    name: 'MISKOLC',
                    isActive: true,
                    country: 'HUNGARY',
                    timezone: 'Europe/Budapest',
                    id: 'MISKOLC',
                    uuid: 'f88f54c4-56be-4aef-aa34-77f9ee5d0f3a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-01T13:23:50.235Z',
                    updated: '2023-06-01T13:23:50.239Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.380754936521043, 53.111580205953175],
                },
                properties: {
                    name: 'MLAWA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MLAWA',
                    uuid: 'e69a63d4-c164-4778-8d8e-b3fc641c11c4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T14:17:46.995Z',
                    updated: '2023-08-09T07:02:05.284Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.92, 44.62],
                },
                properties: {
                    name: 'MODENA',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'MODENA',
                    uuid: 'c18af4f9-5f4b-422f-9d00-094f3af9a2ed',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-10T08:29:54.000Z',
                    updated: '2022-08-29T10:34:57.810Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.2842161871841, 48.0857307069438],
                },
                properties: {
                    name: 'MOEDLING',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'MOEDLING',
                    uuid: '1d89c65e-447e-42b0-80c4-e0af3fc39e77',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-17T13:35:33.000Z',
                    updated: '2022-08-29T10:34:56.934Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.43959, 51.19223],
                },
                properties: {
                    name: 'MOENCHENGLADBACH',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MOENCHENGLADBACH',
                    uuid: '741296f6-8897-44ad-a359-53c3ff0b6cc0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-10T11:50:43.000Z',
                    updated: '2022-08-29T10:34:57.367Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.961823, 53.632019],
                },
                properties: {
                    name: 'MOIA_HH',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'MOIA_HH',
                    uuid: '9be532b9-868a-46d0-8339-db2c82a325b9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-10-15T14:24:21.000Z',
                    updated: '2023-08-09T07:01:45.498Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.895219, 51.097696],
                },
                properties: {
                    name: 'MONHEIM',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MONHEIM',
                    uuid: '3b4c0c31-ba19-4554-9e71-b451a2f95fe0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-20T08:00:14.000Z',
                    updated: '2022-08-29T10:34:58.914Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-77.3393341, 39.1408486],
                },
                properties: {
                    name: 'MONTGOMERY-COUNTY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'MONTGOMERY-COUNTY',
                    uuid: '425324cc-998b-4103-88f9-213e16d5ac64',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-24T17:19:58.205Z',
                    updated: '2023-03-24T17:19:58.207Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.2742681668832, 45.5881707625516],
                },
                properties: {
                    name: 'MONZA',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'MONZA',
                    uuid: 'b081487b-915f-4599-93ab-67bf146ed9da',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T09:00:24.000Z',
                    updated: '2023-08-04T07:54:20.483Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.449045257359131, 52.49309165016268],
                },
                properties: {
                    name: 'MOTIONLAB-BERLIN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MOTIONLAB-BERLIN',
                    uuid: '46cec7c4-f7e0-422d-99b4-796c1090579d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-22T10:18:24.416Z',
                    updated: '2023-08-09T07:01:44.532Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.30470345590362, 53.864265399132904],
                },
                properties: {
                    name: 'MRAGOWO',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MRAGOWO',
                    uuid: '7cc2cd1f-c8ba-4dc9-a381-fb5105f0c398',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:15:58.409Z',
                    updated: '2023-08-09T07:01:38.109Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.886389, 51.431389],
                },
                properties: {
                    name: 'MUELHEIM (RUHR)',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MUELHEIM-RUHR',
                    uuid: 'ae1b7197-3855-4c54-baf4-b207f1c0936f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-12T08:44:55.000Z',
                    updated: '2022-08-29T10:34:56.758Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.634955, 51.957247],
                },
                properties: {
                    name: 'MUENSTER',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MUENSTER',
                    uuid: '69f43679-1008-400b-95ea-5e9da2ef953a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-23T14:01:22.000Z',
                    updated: '2022-08-29T10:34:58.925Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.557766, 48.140458],
                },
                properties: {
                    name: 'MUNICH',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MUNICH',
                    uuid: '2da5e1c6-d555-4edd-b6f8-42456c8e7953',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-22T14:49:31.000Z',
                    updated: '2022-08-29T10:34:57.600Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.490346, 48.409466],
                },
                properties: {
                    name: 'MUNSINGEN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MUNSINGEN',
                    uuid: '7d91fe29-5d93-43be-bd59-994d8e323000',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-02T12:44:12.000Z',
                    updated: '2023-08-09T07:01:43.941Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.5312, 48.1718],
                },
                properties: {
                    name: 'MVG',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MVG',
                    uuid: '9a7751e8-68a2-4c13-bb37-73fb1cd4fe8d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:31.000Z',
                    updated: '2023-08-09T07:02:17.527Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.567266281043715, 48.75543459504874],
                },
                properties: {
                    name: 'MYJAVA',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'MYJAVA',
                    uuid: 'ce143f60-9ccb-4261-bfed-922f9fb1916d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-23T10:41:02.713Z',
                    updated: '2023-01-23T10:41:02.715Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.93808283146197, 49.83465816126335],
                },
                properties: {
                    name: 'MYSLENICE',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MYSLENICE',
                    uuid: '81d28840-f364-49b0-aca2-64244732e27d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-12T07:53:11.169Z',
                    updated: '2023-08-04T08:05:20.520Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.32988182146687, 50.574916299395994],
                },
                properties: {
                    name: 'MYSZKOW',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'MYSZKOW',
                    uuid: 'ac2610ce-7a54-48e1-b70b-4391bb078ca3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-12T09:24:41.233Z',
                    updated: '2023-08-04T08:05:39.708Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [0, 0],
                },
                properties: {
                    name: 'MYTIER',
                    isActive: false,
                    country: 'MyTier',
                    timezone: 'Europe/Berlin',
                    id: 'MYTIER',
                    uuid: '260f8a0b-af93-460f-b1b5-1ad8d92cb81f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-11-08T17:17:08.000Z',
                    updated: '2023-08-09T07:02:04.296Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.495606, 64.466252],
                },
                properties: {
                    name: 'NAMSOS',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'NAMSOS',
                    uuid: '02a6c7c4-90a0-49e8-8c85-9862d2aa0873',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-08-31T13:21:21.000Z',
                    updated: '2023-05-08T12:05:36.822Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-86.78197832184385, 36.16407444513561],
                },
                properties: {
                    name: 'NASHVILLE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'NASHVILLE',
                    uuid: 'a33b2ad0-c657-4368-8566-801de69d6936',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-08T19:24:50.774Z',
                    updated: '2022-12-08T19:24:50.776Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-6.683364257957805, 53.65186921202542],
                },
                properties: {
                    name: 'NAVAN',
                    isActive: true,
                    country: 'IRELAND',
                    timezone: 'Europe/Dublin',
                    id: 'NAVAN',
                    uuid: 'de427eae-9ff8-464d-9469-38f80d1574af',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-07T09:14:31.178Z',
                    updated: '2023-08-07T09:02:12.263Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-78.6079, 35.83251],
                },
                properties: {
                    name: 'NC-STATE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'NC-STATE',
                    uuid: '9d9148ae-4ba5-4dde-b591-564fdeab2d51',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-10T00:14:39.536Z',
                    updated: '2023-03-10T00:14:39.538Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.222904, 49.192937],
                },
                properties: {
                    name: 'NECKARSULM',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'NECKARSULM',
                    uuid: '254b4269-ccc6-42c2-85e6-b0b15e66f72b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-01-19T08:21:34.000Z',
                    updated: '2023-08-09T07:01:49.483Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.977945, 54.078389],
                },
                properties: {
                    name: 'NEUMUENSTER',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'NEUMUENSTER',
                    uuid: '5405975b-b19f-485c-8a6c-838e7caee631',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-21T13:12:15.000Z',
                    updated: '2022-08-29T10:34:57.580Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.69529, 51.19843],
                },
                properties: {
                    name: 'NEUSS',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'NEUSS',
                    uuid: '168390fc-7aad-46d3-a12d-acbf6f46d507',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-11T09:18:42.000Z',
                    updated: '2022-08-29T10:34:56.771Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.2155640688178, 50.0330296472922],
                },
                properties: {
                    name: 'NIEPOLOMICE',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'NIEPOLOMICE',
                    uuid: '8708443b-5481-48dd-b5b4-1b4b18a1a926',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-29T11:45:55.000Z',
                    updated: '2023-08-04T08:52:38.616Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.083827502917785, 52.0356994515964],
                },
                properties: {
                    name: 'NIEUWEGEIN',
                    isActive: true,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Amsterdam',
                    id: 'NIEUWEGEIN',
                    uuid: 'd9b0b917-cebd-40b7-a9eb-d2ca271c6495',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-18T12:16:42.917Z',
                    updated: '2023-04-18T12:16:42.918Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.0861238959726, 48.3170811846697],
                },
                properties: {
                    name: 'NITRA',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Berlin',
                    id: 'NITRA',
                    uuid: '8e3ec6a9-6d04-4290-8737-3bf6cb193702',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-06T08:43:31.000Z',
                    updated: '2022-08-29T10:34:57.835Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [23.501043, 61.480335],
                },
                properties: {
                    name: 'NOKIA',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'NOKIA',
                    uuid: 'be54b5a0-2d4a-4200-860c-69e76732080d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-23T12:03:43.000Z',
                    updated: '2023-04-14T04:33:29.494Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.1924, 58.5877],
                },
                properties: {
                    name: 'NORRKOPING',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'NORRKOPING',
                    uuid: '2d68a292-1a6c-4f27-bb9d-65f2a2628d56',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-26T12:36:46.000Z',
                    updated: '2022-08-29T10:34:58.952Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-1.15067133775815, 52.9548698405164],
                },
                properties: {
                    name: 'NOTTINGHAM',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'NOTTINGHAM',
                    uuid: 'f38422bb-832a-4130-bd4b-9887b96d9c5c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-26T12:12:35.000Z',
                    updated: '2023-08-09T07:02:06.495Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.709144049684966, 51.799872479535765],
                },
                properties: {
                    name: 'NOWA-SOL',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'NOWA-SOL',
                    uuid: 'c4fe4f24-b044-4d3e-918c-85cb776657dc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-27T09:53:52.431Z',
                    updated: '2023-03-27T09:53:52.432Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.7172535056412, 52.4293580469863],
                },
                properties: {
                    name: 'NOWY-DWOR-MAZOWIECKI',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'NOWY-DWOR-MAZOWIECKI',
                    uuid: '255681a3-8276-4748-81e8-5c9f505fa86b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-16T14:22:27.000Z',
                    updated: '2022-08-29T10:34:58.726Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.6917213497586, 49.6254325195537],
                },
                properties: {
                    name: 'NOWY-SACZ',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'NOWY-SACZ',
                    uuid: 'a9a725e0-7293-4c73-bfbf-68b8c46520e4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-05T08:20:35.000Z',
                    updated: '2022-08-29T10:34:58.736Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.03111524862291, 49.481962800394626],
                },
                properties: {
                    name: 'NOWY-TARG',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'NOWY-TARG',
                    uuid: '9a765b8a-c127-4adc-929d-f57a069e13f5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-17T12:23:04.650Z',
                    updated: '2023-01-17T12:23:04.652Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.082038, 49.446742],
                },
                properties: {
                    name: 'NUREMBERG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'NUREMBERG',
                    uuid: '35ef0386-8d22-4f65-820f-dcc46361a256',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-16T16:30:44.000Z',
                    updated: '2022-08-29T10:34:57.631Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.0110089951265, 58.7532445356615],
                },
                properties: {
                    name: 'NYKOEPING',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Berlin',
                    id: 'NYKOEPING',
                    uuid: 'f35d86ae-84ad-4738-8103-7b319e56e6e2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-23T10:32:51.000Z',
                    updated: '2023-08-09T07:02:07.438Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.3343991735007, 50.4724930012295],
                },
                properties: {
                    name: 'NYSA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'NYSA',
                    uuid: '3e296432-20c1-4b65-92f0-a36630a7612c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-07-07T13:13:56.000Z',
                    updated: '2022-08-29T10:34:57.052Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.866034, 51.490116],
                },
                properties: {
                    name: 'OBERHAUSEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'OBERHAUSEN',
                    uuid: 'adc244aa-dc99-4ae2-9ff1-1ec619dc1ddc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-15T17:01:40.000Z',
                    updated: '2022-08-29T10:34:58.461Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.384463, 55.401775],
                },
                properties: {
                    name: 'ODENSE',
                    isActive: false,
                    country: 'DENMARK',
                    timezone: 'Europe/Copenhagen',
                    id: 'ODENSE',
                    uuid: 'a342f2e8-85e2-4aa1-87f0-5f7d3d5758f5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-06-28T11:43:21.000Z',
                    updated: '2023-03-16T14:41:28.143Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.775483, 50.09638],
                },
                properties: {
                    name: 'OFFENBACH',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'OFFENBACH',
                    uuid: '9f2cb5e1-551f-44d9-94cf-dfb759936b65',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-11T14:44:03.000Z',
                    updated: '2023-08-09T07:01:41.722Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.2957443158463, 50.9427003638326],
                },
                properties: {
                    name: 'OLAWA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OLAWA',
                    uuid: '8a2ed9f7-c66e-445a-8b7a-1ffcacec1870',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-08T08:31:38.000Z',
                    updated: '2023-08-04T08:08:37.865Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.216673, 53.137733],
                },
                properties: {
                    name: 'OLDENBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'OLDENBURG',
                    uuid: '51c299e2-a6ca-4896-970d-e2d661ef8f33',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-06-16T14:00:01.000Z',
                    updated: '2022-08-29T10:34:58.212Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.384639143017296, 51.21180058175929],
                },
                properties: {
                    name: 'OLESNICA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OLESNICA',
                    uuid: 'd581a401-b74c-4616-bd46-b2dc17d35713',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-29T10:29:22.256Z',
                    updated: '2022-09-29T10:29:22.257Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.5610917073388, 50.2816371007171],
                },
                properties: {
                    name: 'OLKUSZ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OLKUSZ',
                    uuid: '1dd5f968-2e9a-430d-a0ad-24b8dfe6c379',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T12:49:47.000Z',
                    updated: '2023-08-04T08:50:04.755Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.601622, 38.823591],
                },
                properties: {
                    name: 'ONTINYENT',
                    isActive: false,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'ONTINYENT',
                    uuid: '8b58aec9-e0ae-42e9-a839-53ee9259f06b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-14T19:49:58.329Z',
                    updated: '2023-08-09T07:01:48.780Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.9245177821387, 50.6673054368],
                },
                properties: {
                    name: 'OPOLE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OPOLE',
                    uuid: 'ec75cb14-350a-4db4-b9b2-3b1d9767d795',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-18T09:28:18.000Z',
                    updated: '2022-08-29T10:34:59.322Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.2196397469226, 52.744006835717],
                },
                properties: {
                    name: 'ORANIENBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ORANIENBURG',
                    uuid: 'b07b70c2-bddd-4bc1-b5a5-0e6a213493a2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-23T11:33:22.000Z',
                    updated: '2022-08-29T10:34:59.297Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.21, 59.27],
                },
                properties: {
                    name: 'OREBRO',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'OREBRO',
                    uuid: 'e0f0f6d8-c840-455b-bdda-00ad6039684c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-25T14:03:05.000Z',
                    updated: '2022-08-29T10:34:56.815Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-81.38341, 28.52261],
                },
                properties: {
                    name: 'ORLANDO',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'ORLANDO',
                    uuid: '6c680552-e20d-49b9-94a9-8b6e18763dc8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T06:10:36.924Z',
                    updated: '2023-02-24T06:10:36.925Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.7522, 59.9139],
                },
                properties: {
                    name: 'OSLO',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'OSLO',
                    uuid: 'e907ff85-bd35-474d-babf-da4d3fda8b84',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-05-22T07:48:19.413Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.060499, 52.272814],
                },
                properties: {
                    name: 'OSNABRUECK',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'OSNABRUECK',
                    uuid: '928f2a5f-1902-469c-ad63-bf34642ac82b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-02-05T12:25:16.000Z',
                    updated: '2022-08-29T10:34:57.852Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.2625, 49.8209],
                },
                properties: {
                    name: 'OSTRAVA',
                    isActive: false,
                    country: 'CZECHIA',
                    timezone: 'Europe/Prague',
                    id: 'OSTRAVA',
                    uuid: '4212f995-3314-4cd0-8f87-6a7d15a1512d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-10T10:58:33.000Z',
                    updated: '2023-08-09T07:01:26.453Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.96212949997485, 53.69831262773138],
                },
                properties: {
                    name: 'OSTRODA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OSTRODA',
                    uuid: '9ef290d6-f93d-40fd-9603-fe26177d3b60',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-23T10:19:26.991Z',
                    updated: '2023-08-09T07:01:45.778Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.57266362909141, 53.08454506587847],
                },
                properties: {
                    name: 'OSTROLEKA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OSTROLEKA',
                    uuid: 'dbbd4f0e-24a6-40ab-8d0e-b350cee3bf9d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-10T10:04:34.432Z',
                    updated: '2023-08-09T07:01:55.570Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.89557049793325, 52.801706378539194],
                },
                properties: {
                    name: 'OSTROW-MAZOWIECKA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OSTROW-MAZOWIECKA',
                    uuid: 'ce2c483e-e338-4cc1-b8e1-c66929b3ae54',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:21:26.337Z',
                    updated: '2023-08-09T07:01:33.383Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.2366847222222, 50.05],
                },
                properties: {
                    name: 'OSWIECIM',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OSWIECIM',
                    uuid: '561818b1-5fc7-44df-a465-309d2fe46700',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-21T12:13:17.000Z',
                    updated: '2022-08-29T10:34:56.830Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.2627722815371, 52.10826448425],
                },
                properties: {
                    name: 'OTWOCK',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'OTWOCK',
                    uuid: '94afe368-ca22-402d-93be-c1b88559fa3c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-18T08:00:52.000Z',
                    updated: '2023-08-09T07:01:21.178Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [25.4738826113296, 65.0091472451404],
                },
                properties: {
                    name: 'OULU',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'OULU',
                    uuid: 'dc198f02-4798-4bdf-8d00-1a0f034d4c1e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-02-16T15:02:59.000Z',
                    updated: '2023-04-30T21:01:05.558Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.0564534542156, 50.4603194606101],
                },
                properties: {
                    name: 'PACZKOW-OTMUCHOW',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PACZKOW-OTMUCHOW',
                    uuid: 'f4757726-fb99-4312-971e-6f0ea449d650',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-07-07T13:17:46.000Z',
                    updated: '2023-07-11T18:12:07.134Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.752211, 51.717165],
                },
                properties: {
                    name: 'PADERBORN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'PADERBORN',
                    uuid: 'd1cab431-5486-44c6-84d4-a237391a651d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-12T10:52:36.000Z',
                    updated: '2022-08-29T10:34:58.225Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.3531600290633, 38.1266879445068],
                },
                properties: {
                    name: 'PALERMO',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'PALERMO',
                    uuid: '8e551503-081f-4cee-847e-6e0994835f34',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T08:59:05.000Z',
                    updated: '2023-03-16T14:41:30.062Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.65175, 39.5714],
                },
                properties: {
                    name: 'PALMA',
                    isActive: false,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'PALMA',
                    uuid: '0988babb-0920-4137-bc18-420a288163ba',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:02:17.822Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.33219, 48.8528],
                },
                properties: {
                    name: 'PARIS',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'PARIS',
                    uuid: 'e4215a2b-ce13-4811-af11-b8fac7c7865d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:59.004Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.506314, 48.977249],
                },
                properties: {
                    name: 'PARIS-NORD-DEUX',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'PARIS-NORD-DEUX',
                    uuid: 'dc6a7614-9c9e-4162-8cf7-ac3432ed45d2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-14T08:19:25.000Z',
                    updated: '2022-08-29T10:34:58.237Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.3260818714982, 44.8085158602758],
                },
                properties: {
                    name: 'PARMA',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'PARMA',
                    uuid: '36939afb-678b-4283-8c5c-1887f3160485',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T08:52:24.000Z',
                    updated: '2022-08-29T10:34:57.657Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.2324466893016, 52.3193129466498],
                },
                properties: {
                    name: 'PEINE',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'PEINE',
                    uuid: '3182432f-6b56-4924-8533-91e709777181',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-11T12:32:12.000Z',
                    updated: '2022-08-29T10:34:58.250Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-77.91552689357891, 40.81444595515893],
                },
                properties: {
                    name: 'PENN-STATE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'PENN-STATE',
                    uuid: 'fde44101-0378-4903-9430-4b22f2a89451',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-08T21:47:41.937Z',
                    updated: '2023-04-19T07:31:52.980Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.26750987597501, 48.29006649692499],
                },
                properties: {
                    name: 'PEZINOK',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'PEZINOK',
                    uuid: '1dad3104-c019-406c-a048-4309d4a35d45',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-01T11:36:07.602Z',
                    updated: '2023-02-01T11:36:07.604Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.703099, 48.894181],
                },
                properties: {
                    name: 'PFORZHEIM',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'PFORZHEIM',
                    uuid: '401f2f51-19af-45e0-a52b-596a85ea9e21',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-02-04T13:51:54.000Z',
                    updated: '2023-08-09T07:01:57.596Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-112.08307, 33.441],
                },
                properties: {
                    name: 'PHOENIX',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'PHOENIX',
                    uuid: 'bbda7686-e65e-4aab-8316-0de770c709df',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T00:38:59.829Z',
                    updated: '2023-03-16T00:38:59.830Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.945760377779, 50.3833814170469],
                },
                properties: {
                    name: 'PIEKARY-SLASKIE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PIEKARY-SLASKIE',
                    uuid: '6e4c2d6d-2e29-48cc-821e-80817ee97ed0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-08T08:25:16.000Z',
                    updated: '2022-09-08T08:25:16.511Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.798375, 53.650769],
                },
                properties: {
                    name: 'PINNEBERG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'PINNEBERG',
                    uuid: '9902677b-a881-445f-8c05-c3a9987c2e91',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-16T11:38:30.000Z',
                    updated: '2022-08-29T10:34:58.422Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.802348784897475, 53.62967856208874],
                },
                properties: {
                    name: 'PISZ',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PISZ',
                    uuid: 'a8c91ba7-5610-457f-9516-10bd178afe6e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-24T10:58:38.972Z',
                    updated: '2023-04-24T10:58:38.973Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-80.02445, 40.4506],
                },
                properties: {
                    name: 'PITTSBURGH',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'PITTSBURGH',
                    uuid: '7a55cad0-bb1a-4504-8b92-3968c9023f1c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T06:13:32.552Z',
                    updated: '2023-07-10T03:37:28.382Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.697873, 52.545555],
                },
                properties: {
                    name: 'PLOCK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PLOCK',
                    uuid: '380e668e-9990-40ad-a425-b042a660fabe',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-30T12:38:10.000Z',
                    updated: '2022-08-29T10:34:58.796Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.0204130130165, 54.0822211376388],
                },
                properties: {
                    name: 'POBIEROWO-REWAL-NIECHORZE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'POBIEROWO-REWAL-NIECHORZE',
                    uuid: '2ff11df8-79a0-4907-b027-6c2db7089aff',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-22T11:14:42.000Z',
                    updated: '2022-08-29T10:34:57.381Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.51166195660707, 50.407547299079624],
                },
                properties: {
                    name: 'POLANICA-ZDROJ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'POLANICA-ZDROJ',
                    uuid: 'ff037e57-04b6-415e-9d7c-86664a20634a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:30:39.418Z',
                    updated: '2023-08-04T08:09:50.699Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.5653007884001, 53.5494892743467],
                },
                properties: {
                    name: 'POLICE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'POLICE',
                    uuid: 'b07c1b35-1a1a-4fd7-aec1-3d40f98c2815',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-08T16:12:21.000Z',
                    updated: '2022-08-29T10:34:58.816Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.0686080918038, 51.5033067877569],
                },
                properties: {
                    name: 'POLKOWICE',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'POLKOWICE',
                    uuid: '752876d1-98f3-471d-9db9-e635ab3be35c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-06T17:48:31.000Z',
                    updated: '2023-08-04T08:09:14.303Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.300608575531868, 49.054734114809065],
                },
                properties: {
                    name: 'POPRAD',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'POPRAD',
                    uuid: '50d45f48-933d-47df-9765-fce0e49fab34',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-18T07:51:41.148Z',
                    updated: '2023-04-18T07:51:41.149Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.608043, 45.233321],
                },
                properties: {
                    name: 'POREC',
                    isActive: false,
                    country: 'CROATIA',
                    timezone: 'Europe/Berlin',
                    id: 'POREC',
                    uuid: '12fb1c83-a658-47db-899c-09ebe6f74bb4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-01T09:35:33.000Z',
                    updated: '2023-03-16T14:41:31.987Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-122.6957522, 45.5393047],
                },
                properties: {
                    name: 'PORTLAND',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'PORTLAND',
                    uuid: '407c8b25-e0c7-4866-95ba-c5fd3ea07159',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-08T20:27:30.412Z',
                    updated: '2023-03-08T20:27:30.415Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.0924070385275, 46.62274333303],
                },
                properties: {
                    name: 'PORTSCHACH-VELDEN',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'PORTSCHACH-VELDEN',
                    uuid: 'ca7fc71b-6cc5-4529-92c5-f5c90b8aff82',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-17T10:32:23.000Z',
                    updated: '2022-08-29T10:34:58.828Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13, 52],
                },
                properties: {
                    name: 'PORTUGAL',
                    isActive: false,
                    country: 'PORTUGAL',
                    timezone: 'Europe/Berlin',
                    id: 'PORTUGAL',
                    uuid: 'f5b31d07-58d3-4208-ba5c-9b36fd98f087',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:31.561Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.05854, 52.39615],
                },
                properties: {
                    name: 'POTSDAM',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'POTSDAM',
                    uuid: '4f7be82c-7ee5-43d5-a94a-343547f7ad04',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-09-10T11:51:56.000Z',
                    updated: '2022-08-29T10:34:58.261Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [51.50432, -0.0784],
                },
                properties: {
                    name: 'POTTERS_FIELD',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'POTTERS_FIELD',
                    uuid: '57f535bb-0475-4b67-921b-6782125deade',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-12T13:15:31.000Z',
                    updated: '2023-08-09T07:01:30.854Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.932214, 52.401388],
                },
                properties: {
                    name: 'POZNAN',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'POZNAN',
                    uuid: '67039f49-1824-4311-b8e9-1a330eb67432',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-07-07T17:49:34.000Z',
                    updated: '2023-08-04T08:54:55.597Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.2344170972708, 48.993942519791],
                },
                properties: {
                    name: 'PRESOV',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'PRESOV',
                    uuid: '6991b02c-cb09-49b1-bd0c-97b9246cd38a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-07-11T11:32:02.000Z',
                    updated: '2022-08-29T10:34:57.606Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.623418026375504, 48.77188520785427],
                },
                properties: {
                    name: 'PRIEVIDZA',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'PRIEVIDZA',
                    uuid: 'a68b3363-4a24-413b-b958-df99fd4ca6ca',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-23T10:34:36.615Z',
                    updated: '2023-05-23T10:34:36.616Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-71.41512937554393, 41.829171601881654],
                },
                properties: {
                    name: 'PROVIDENCE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'PROVIDENCE',
                    uuid: '2bd1382b-8510-4f30-aca2-2559dc120aba',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-19T18:06:19.712Z',
                    updated: '2023-07-17T07:40:32.636Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.57842039189603, 50.321837626061125],
                },
                properties: {
                    name: 'PRUDNIK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PRUDNIK',
                    uuid: '552e73e1-b08c-40af-a7a3-432ab5e62cc9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-21T10:29:06.500Z',
                    updated: '2023-07-07T05:22:14.463Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.6507061578734, 54.2630448087217],
                },
                properties: {
                    name: 'PRUSZCZ-GDANSKI',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Berlin',
                    id: 'PRUSZCZ-GDANSKI',
                    uuid: '5d0e8cdb-eae6-42f0-86fe-cccbb3f649f9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-20T14:44:32.000Z',
                    updated: '2022-08-29T10:34:57.397Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.8002226294974, 52.1679765191954],
                },
                properties: {
                    name: 'PRUSZKOW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PRUSZKOW',
                    uuid: 'e4d2e503-4160-46dc-ab12-81d116fb110f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-16T14:21:08.000Z',
                    updated: '2022-08-29T10:34:57.669Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.885056842562705, 53.018835106220536],
                },
                properties: {
                    name: 'PRZASNYSZ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PRZASNYSZ',
                    uuid: '434f8b95-4ae8-48c8-a085-b35a2e00d611',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:23:06.095Z',
                    updated: '2023-08-09T07:01:42.577Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.9472103234738, 49.9758576423626],
                },
                properties: {
                    name: 'PSZCZYNA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'PSZCZYNA',
                    uuid: 'db965652-aec2-433f-ae89-2dec1aeb78f0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-04T14:15:23.000Z',
                    updated: '2023-03-25T15:02:50.142Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.854066, 44.864892],
                },
                properties: {
                    name: 'PULA',
                    isActive: true,
                    country: 'CROATIA',
                    timezone: 'Europe/Berlin',
                    id: 'PULA',
                    uuid: 'b9a4e003-c243-48ac-982b-d445b6ac0c2b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-05T14:27:49.000Z',
                    updated: '2023-06-07T18:32:04.819Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.962216839670532, 49.60633040952706],
                },
                properties: {
                    name: 'RABKA-ZDROJ',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'RABKA-ZDROJ',
                    uuid: '79ca7d7b-0e38-44e9-81ba-7e9e29ba1eeb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-20T12:13:09.679Z',
                    updated: '2023-04-20T12:13:09.680Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.219702223435405, 50.09197906857631],
                },
                properties: {
                    name: 'RACIBORZ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'RACIBORZ',
                    uuid: 'b73cf7cd-e85b-4b93-bf1d-0531e767f9ec',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-20T12:11:14.678Z',
                    updated: '2023-08-04T08:07:57.118Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.156027, 51.403204],
                },
                properties: {
                    name: 'RADOM',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'RADOM',
                    uuid: 'c57df270-9f94-40f6-a9e3-74100cb01578',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-30T12:34:45.000Z',
                    updated: '2023-03-16T14:41:32.044Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.1636989274234, 60.487970417758],
                },
                properties: {
                    name: 'RAISIO',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'RAISIO',
                    uuid: 'a504b753-46f0-425a-b3b9-ad983d4d8886',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-05T11:15:07.000Z',
                    updated: '2022-08-29T10:34:56.799Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-78.6079, 35.83251],
                },
                properties: {
                    name: 'RALEIGH',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'RALEIGH',
                    uuid: '80c611b9-c481-46ab-9dfd-a594d9eada0a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-09T22:44:57.941Z',
                    updated: '2023-03-09T22:44:57.942Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [34.8248, 32.0684],
                },
                properties: {
                    name: 'RAMAT-GAN',
                    isActive: false,
                    country: 'ISRAEL',
                    timezone: 'Europe/Athens',
                    id: 'RAMAT-GAN',
                    uuid: '82b3f28b-3dc5-45c0-ac72-67a7dfc37e6f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-31T11:08:18.000Z',
                    updated: '2023-08-09T07:02:19.711Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [55.9754689530189, 25.792682738936],
                },
                properties: {
                    name: 'RAS-AL-KHAIMAH',
                    isActive: true,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'RAS-AL-KHAIMAH',
                    uuid: '2179f848-bf6a-4631-a025-8da6f26ed284',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-08T10:13:11.000Z',
                    updated: '2022-09-08T10:13:11.757Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.214895, 48.860681],
                },
                properties: {
                    name: 'RASTATT',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'RASTATT',
                    uuid: '69ac0eba-3fa8-429c-b9e9-9b969d566db3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-20T14:48:06.000Z',
                    updated: '2023-04-30T09:00:26.542Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.858027264287387, 51.609038681516495],
                },
                properties: {
                    name: 'RAWICZ',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'RAWICZ',
                    uuid: 'cea8cd20-034e-46c3-9df8-dbce42145f5c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-23T10:03:41.738Z',
                    updated: '2023-08-04T08:09:35.320Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.203333, 51.616389],
                },
                properties: {
                    name: 'Recklinghausen',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'RECKLINGHAUSEN',
                    uuid: '40707d31-993f-4340-a64b-a604ecb0ea05',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-09-18T08:57:21.000Z',
                    updated: '2022-08-29T10:34:57.694Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.098249209186406, 49.01963396956481],
                },
                properties: {
                    name: 'REGENSBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'REGENSBURG',
                    uuid: '90052e19-b39b-4665-8778-e8d42ff22018',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-11-18T08:44:57.782Z',
                    updated: '2022-11-18T08:44:57.783Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.6310564721339, 44.7066104308607],
                },
                properties: {
                    name: 'REGGIO-EMILIA',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'REGGIO-EMILIA',
                    uuid: '2ecb5517-d5ab-4f32-a2b4-34a7b8725cc8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T08:50:44.000Z',
                    updated: '2022-08-29T10:34:57.215Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.209963, 48.495828],
                },
                properties: {
                    name: 'Reutlingen',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'REUTLINGEN',
                    uuid: 'a34615b5-65a9-4140-9904-c5b0ec7174de',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-11-11T10:00:28.000Z',
                    updated: '2022-08-29T10:34:57.408Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.29657, 51.85257],
                },
                properties: {
                    name: 'RHEDA-WIEDENBRUCK',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'RHEDA-WIEDENBRUCK',
                    uuid: '1bf40c02-e3ab-443a-8143-3f5222288e36',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-02T11:22:06.000Z',
                    updated: '2022-08-29T10:34:57.419Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.442561, 52.279251],
                },
                properties: {
                    name: 'RHEINE',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'RHEINE',
                    uuid: '87693d13-33dc-4ffa-8916-d4e646d92748',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-01T07:58:30.000Z',
                    updated: '2022-08-29T10:34:58.494Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.03904, 45.53263],
                },
                properties: {
                    name: 'RHO',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'RHO',
                    uuid: '9b5b2972-7e8a-442c-a84c-e55a07de1a21',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-28T09:12:28.247Z',
                    updated: '2022-09-28T09:12:28.248Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-77.42757, 37.58532],
                },
                properties: {
                    name: 'RICHMOND',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'RICHMOND',
                    uuid: '5a3e7380-8d2a-41e6-8bd4-63ad204bf7f3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:26:32.167Z',
                    updated: '2023-02-21T20:26:32.168Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.4497206845735, 45.3299741913831],
                },
                properties: {
                    name: 'RIJEKA',
                    isActive: true,
                    country: 'CROATIA',
                    timezone: 'Europe/Berlin',
                    id: 'RIJEKA',
                    uuid: '5207ecbc-9059-4f8f-8334-bcb9d89c49cd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-15T15:03:12.000Z',
                    updated: '2022-08-29T10:34:59.078Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.9095461934045, 59.1873917958762],
                },
                properties: {
                    name: 'RIKSTEN',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'RIKSTEN',
                    uuid: 'a61e3bad-38f5-41e9-bc84-b905123abbb8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-18T17:14:42.000Z',
                    updated: '2023-08-09T07:01:24.967Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.5703715988557, 44.0591058093302],
                },
                properties: {
                    name: 'RIMINI',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'RIMINI',
                    uuid: '124b6f0e-70ce-40cd-8dd0-aef7721831bd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-15T11:43:24.000Z',
                    updated: '2023-08-04T07:55:51.358Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [46.72819, 24.721034],
                },
                properties: {
                    name: 'RIYADH',
                    isActive: true,
                    country: 'SAUDI ARABIA',
                    timezone: 'Asia/Riyadh',
                    id: 'RIYADH',
                    uuid: '128d0398-e0bf-402b-981f-85107f020edf',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-08-26T11:03:33.000Z',
                    updated: '2022-08-29T10:34:59.017Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-80.06797, 37.28804],
                },
                properties: {
                    name: 'ROANOKE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'ROANOKE',
                    uuid: '58273d61-1cb7-4707-a07d-46904c572d5c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:29:45.914Z',
                    updated: '2023-02-21T20:29:45.917Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.182388, 50.901281],
                },
                properties: {
                    name: 'ROESRATH',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'ROESRATH',
                    uuid: 'b784a870-69ad-43da-8d1c-59fbdcfc0540',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-08T11:02:58.000Z',
                    updated: '2023-08-09T07:01:47.128Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.656509, 48.789992],
                },
                properties: {
                    name: 'ROISSY-EN-BRIE',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'ROISSY-EN-BRIE',
                    uuid: '583fa996-9a03-4e64-b74b-32f07a3f5944',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-15T14:24:08.000Z',
                    updated: '2022-08-29T10:34:57.895Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.378604797804286, 47.56550109474529],
                },
                properties: {
                    name: 'ROMANSHORN',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'ROMANSHORN',
                    uuid: 'a335344b-a1eb-4655-a8bd-6a03c899e751',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-08T21:18:32.201Z',
                    updated: '2023-06-08T21:18:32.203Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.506466, 41.890821],
                },
                properties: {
                    name: 'ROME',
                    isActive: true,
                    country: 'Italy',
                    timezone: 'Europe/Rome',
                    id: 'ROME',
                    uuid: '130c2edd-c9ff-42e6-9029-7416cd3f19eb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-03-11T05:44:35.000Z',
                    updated: '2022-08-29T10:34:57.906Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.49970231093302, 47.47778667217105],
                },
                properties: {
                    name: 'RORSCHACH',
                    isActive: false,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Berlin',
                    id: 'RORSCHACH',
                    uuid: 'd3cf618e-6d4b-4bdd-8515-737ca4ac261b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-11-30T09:24:50.921Z',
                    updated: '2023-03-16T14:41:32.238Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.096233, 54.098672],
                },
                properties: {
                    name: 'ROSTOCK',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ROSTOCK',
                    uuid: '21b5a62d-93ca-4fef-a232-0bdf9a9e9ed4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-07-27T11:44:30.000Z',
                    updated: '2022-08-29T10:34:58.305Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.4316, 47.1413],
                },
                properties: {
                    name: 'ROTKREUZ',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'ROTKREUZ',
                    uuid: 'ff3cce2e-b953-4366-b060-35a8c40299cd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-21T10:53:53.000Z',
                    updated: '2022-08-29T10:34:58.863Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [3.176387, 50.690971],
                },
                properties: {
                    name: 'ROUBAIX',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'ROUBAIX',
                    uuid: 'e8a7461e-35df-4c75-a770-e1b5e9c464d6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-07-20T12:27:29.000Z',
                    updated: '2023-06-29T08:28:19.774Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.563423586716482, 53.64928918883356],
                },
                properties: {
                    name: 'RUCIANE-NIDA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'RUCIANE-NIDA',
                    uuid: '345a23cf-a832-43e5-9ee3-9cd332aaeac8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-15T15:24:57.898Z',
                    updated: '2023-05-15T15:24:57.899Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.41203932315639, 49.9949327493142],
                },
                properties: {
                    name: 'RUESSELSHEIM',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'RUESSELSHEIM',
                    uuid: 'db7e1f56-f980-4aea-a88d-401ae6e3a47e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-21T10:52:46.000Z',
                    updated: '2022-08-29T10:34:57.918Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [52.6722901695252, 24.0833274369472],
                },
                properties: {
                    name: 'RUWAIS',
                    isActive: true,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'RUWAIS',
                    uuid: '448c8d47-5f5a-4d5e-8985-33ee2897a9ec',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-14T13:50:14.000Z',
                    updated: '2022-08-29T10:34:59.029Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.04249, 49.2564],
                },
                properties: {
                    name: 'SAARBRUCKEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SAARBRUCKEN',
                    uuid: 'a236a22f-b5f0-4ba5-a5d9-ac6e81f332c3',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:57.930Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-121.49539050891315, 38.58076145213283],
                },
                properties: {
                    name: 'SACRAMENTO',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'SACRAMENTO',
                    uuid: '0b3f9769-cd50-40a2-9b42-cabf61e7dbc2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-08T19:57:00.299Z',
                    updated: '2023-08-09T07:02:16.550Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.0360157, 48.7738734],
                },
                properties: {
                    name: 'SAINT_QUENTIN_EN_YVELINES',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'SAINT_QUENTIN_EN_YVELINES',
                    uuid: 'b032ec77-89df-4a57-90ae-50e6b92f4864',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-29T09:55:49.000Z',
                    updated: '2022-08-29T10:34:58.516Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.542607, 47.603436],
                },
                properties: {
                    name: 'SAINT-LOUIS',
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'SAINT-LOUIS',
                    uuid: 'b99cc644-cc69-46ca-97b4-d2f1beb75a8a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-19T12:32:08.000Z',
                    updated: '2023-08-09T07:01:29.214Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.02136486881463, 48.8597718488415],
                },
                properties: {
                    name: 'SAINT-NOM-BRETECHE',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'SAINT-NOM-BRETECHE',
                    uuid: '45b62811-f3b6-47e6-be95-8a21e9492cb8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-11T08:07:01.000Z',
                    updated: '2022-08-29T10:34:58.412Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-111.8942417, 40.7152771],
                },
                properties: {
                    name: 'SALT-LAKE-CITY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'SALT-LAKE-CITY',
                    uuid: '15be292a-1423-4e12-994d-cf3f8ac041aa',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T19:23:23.970Z',
                    updated: '2023-03-23T19:23:23.972Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.0486, 47.8059],
                },
                properties: {
                    name: 'SALZBURG',
                    isActive: false,
                    country: 'Austria',
                    timezone: 'Europe/Vienna',
                    id: 'SALZBURG',
                    uuid: '715bca47-07eb-4df4-8acd-b275230e1414',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:33.031Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.3283498049211, 52.160542805171],
                },
                properties: {
                    name: 'SALZGITTER',
                    isActive: true,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'SALZGITTER',
                    uuid: '90a396a3-1259-4445-b8ec-acf3ebf67a63',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-11T11:03:14.000Z',
                    updated: '2022-08-29T10:34:57.941Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-117.2252371, 32.8192745],
                },
                properties: {
                    name: 'SAN-DIEGO',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'SAN-DIEGO',
                    uuid: '68bd3968-836f-471c-a34c-e3fc38b18d21',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-07T20:32:43.109Z',
                    updated: '2023-06-22T23:57:42.350Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-122.409010207471, 37.7885479977918],
                },
                properties: {
                    name: 'SAN-FRANCISCO',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'SAN-FRANCISCO',
                    uuid: '4ce1ed10-c8d6-4165-891c-09925fecfb16',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-18T14:39:20.000Z',
                    updated: '2022-08-29T10:34:57.227Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-121.88974581129214, 37.336493425200786],
                },
                properties: {
                    name: 'SAN-JOSE',
                    isActive: false,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'SAN-JOSE',
                    uuid: 'f9997bda-9f7c-4cc9-a036-c1ac2741796d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-08T19:55:29.664Z',
                    updated: '2023-08-09T07:01:44.844Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-97.9098556, 29.8952058],
                },
                properties: {
                    name: 'SAN-MARCOS',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'SAN-MARCOS',
                    uuid: '71a139aa-db6f-46c5-b0bd-c529f0d4f1ad',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-09T00:07:13.190Z',
                    updated: '2023-03-09T00:07:13.192Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.73626230757662, 58.8532820062704],
                },
                properties: {
                    name: 'SANDNES',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'SANDNES',
                    uuid: '524e2177-cc82-41e0-9a88-9cb777ef8d22',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-30T13:56:47.000Z',
                    updated: '2023-08-09T07:01:45.156Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.1860411, 50.7768812],
                },
                properties: {
                    name: 'SANKT-AUGUSTIN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SANKT-AUGUSTIN',
                    uuid: '5786be65-7ef2-48ce-ad74-dfebba0c697f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-22T12:52:55.000Z',
                    updated: '2022-08-29T10:34:59.042Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.2659873764491, 47.1290386296072],
                },
                properties: {
                    name: 'SANKTANTONAMARLBERG',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'SANKTANTONAMARLBERG',
                    uuid: '46925ced-8fd1-47e6-a107-b0282583fe57',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-08T08:55:01.000Z',
                    updated: '2023-05-31T14:00:19.204Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.07664, 41.4915],
                },
                properties: {
                    name: 'SANT_CUGAT',
                    isActive: false,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'SANT_CUGAT',
                    uuid: '228e2a59-b58a-4ba1-8596-30b51211454f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:02:00.525Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-118.48311, 34.01884],
                },
                properties: {
                    name: 'SANTA-MONICA',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'SANTA-MONICA',
                    uuid: 'da219fcd-60e4-4ad3-a3e0-03540e424431',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T01:26:35.404Z',
                    updated: '2023-03-16T01:26:35.405Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.63272865586535, 47.69805289791799],
                },
                properties: {
                    name: 'SCHAFFHAUSEN',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'SCHAFFHAUSEN',
                    uuid: '0f9cd666-fa70-4e19-88a9-7a20b9cef920',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-20T11:43:11.594Z',
                    updated: '2023-06-20T11:43:11.595Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.81708, 51.783044],
                },
                properties: {
                    name: 'SCHLANGEN',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'SCHLANGEN',
                    uuid: '4c29072c-80df-42d2-9879-bb8b376c2d80',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-07-15T08:24:37.000Z',
                    updated: '2023-08-09T07:01:37.191Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.016511, 49.328802],
                },
                properties: {
                    name: 'SCHWABACH',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SCHWABACH',
                    uuid: '00519c98-82c3-42f1-9275-b4934b1c7c93',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-03T11:06:17.000Z',
                    updated: '2023-03-16T14:41:30.359Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.4001394430571, 53.628253971722],
                },
                properties: {
                    name: 'SCHWERIN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SCHWERIN',
                    uuid: 'eaf7aedd-96a5-4b79-8c3c-a502878417f4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-04T10:09:52.000Z',
                    updated: '2023-08-09T07:01:58.546Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-112.08307, 33.441],
                },
                properties: {
                    name: 'SCOTTSDALE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'SCOTTSDALE',
                    uuid: '74524184-0c4e-48ea-aa5a-94292a41dae6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T00:49:51.718Z',
                    updated: '2023-03-16T00:49:51.720Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.8344925253966, 62.7883297285294],
                },
                properties: {
                    name: 'SEINAJOKI',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'SEINAJOKI',
                    uuid: '7bcceb05-3d4a-4675-9ace-4dc4b9f82ac8',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-20T12:48:23.000Z',
                    updated: '2023-04-14T10:57:03.045Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.39739394377918, 48.2201693559677],
                },
                properties: {
                    name: 'SENEC',
                    isActive: false,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'SENEC',
                    uuid: '4ba0ace8-b69f-4403-afdc-612442dc5364',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-16T10:59:44.055Z',
                    updated: '2023-08-09T07:02:18.489Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.6056087384565, 47.0398037041437],
                },
                properties: {
                    name: 'SERFAUS',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'SERFAUS',
                    uuid: '29e70abb-684f-4bc5-92ee-daf1d2c74e95',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-25T16:41:17.000Z',
                    updated: '2023-06-07T06:57:12.965Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-5.99942, 37.3929],
                },
                properties: {
                    name: 'SEVILLA',
                    isActive: false,
                    country: 'Spain',
                    timezone: 'Europe/Madrid',
                    id: 'SEVILLA',
                    uuid: '39d3955f-0f1b-4ab4-8b9d-93828e39a0b2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-09T07:01:54.151Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [55.42723631367778, 25.35633307407734],
                },
                properties: {
                    name: 'SHARJAH',
                    isActive: true,
                    country: 'UNITED ARAB EMIRATES',
                    timezone: 'Asia/Dubai',
                    id: 'SHARJAH',
                    uuid: 'fc309e0f-c009-410a-8fed-66a40a323bbe',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-09T09:38:38.690Z',
                    updated: '2023-02-09T09:38:38.691Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-87.7355488, 43.747233],
                },
                properties: {
                    name: 'SHEBOYGAN',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'SHEBOYGAN',
                    uuid: 'b75d5170-0c3f-460d-9e3e-8c2cb0b3fe03',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-24T17:17:45.970Z',
                    updated: '2023-03-24T17:17:45.971Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.2774759362476, 52.1713871426393],
                },
                properties: {
                    name: 'SIEDLCE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SIEDLCE',
                    uuid: 'a7aa55a6-9d86-43b4-8f7b-feaf253a4e6e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-29T10:35:35.000Z',
                    updated: '2022-08-29T10:34:58.539Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.20431360796346, 50.7934192531656],
                },
                properties: {
                    name: 'SIEGBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SIEGBURG',
                    uuid: '8df76215-119e-4b42-8730-90b0129db778',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-24T15:59:30.000Z',
                    updated: '2022-08-29T10:34:57.979Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.75418, 48.8673],
                },
                properties: {
                    name: 'SIEMU',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'SIEMU',
                    uuid: '56485716-0974-4f8d-9a4d-d08c8c416b01',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-05T10:43:27.668Z',
                    updated: '2022-12-05T10:43:27.669Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.00347040805789, 48.7111697128413],
                },
                properties: {
                    name: 'SINDELFINGEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SINDELFINGEN',
                    uuid: 'edf12cfb-0edc-40b5-9823-8ff55d450bc5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-15T06:44:01.000Z',
                    updated: '2022-08-29T10:34:57.452Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.0589063987024, 46.909890550189],
                },
                properties: {
                    name: 'SIOFOK',
                    isActive: true,
                    country: 'HUNGARY',
                    timezone: 'Europe/Budapest',
                    id: 'SIOFOK',
                    uuid: 'f744f35e-8f20-4b87-b2ee-76115ee14b53',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-08T12:26:00.000Z',
                    updated: '2022-08-29T10:34:57.554Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [1.81, 41.24],
                },
                properties: {
                    name: 'SITGES',
                    isActive: false,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'SITGES',
                    uuid: 'd497539d-aa26-45b4-8638-c9d0c9142017',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-15T08:38:12.000Z',
                    updated: '2023-05-08T12:06:21.881Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.5114, 48.0432],
                },
                properties: {
                    name: 'SIXT',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SIXT',
                    uuid: 'c87b1498-c390-49f7-8189-c8d62897df6d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:31.000Z',
                    updated: '2022-08-29T10:34:57.715Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.7, 55.63],
                },
                properties: {
                    name: 'SJOBO',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'SJOBO',
                    uuid: '7fd42504-2f64-4fa1-9a71-fe4e94490480',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-10T10:36:13.000Z',
                    updated: '2023-05-08T12:06:22.514Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.8238679687292, 49.9770417464497],
                },
                properties: {
                    name: 'SKAWINA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SKAWINA',
                    uuid: '5de108fc-5de6-4eb2-a26c-2fd1d4364697',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-29T11:43:36.000Z',
                    updated: '2022-08-29T10:34:58.365Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.835023, 59.719067],
                },
                properties: {
                    name: 'SKI',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'SKI',
                    uuid: 'c44b17ee-80cb-4023-b434-5c831e6ec824',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-14T09:12:35.000Z',
                    updated: '2023-04-25T12:29:57.050Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.036373809714, 54.4692613756109],
                },
                properties: {
                    name: 'SLUPSK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SLUPSK',
                    uuid: '298f57e3-28a1-496e-85e8-7e0d7a25cb45',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-30T09:56:33.000Z',
                    updated: '2022-08-29T10:34:56.833Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-1.24001217790448, 50.862127278603],
                },
                properties: {
                    name: 'SOLENT-REGION',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'SOLENT-REGION',
                    uuid: '345edfc8-a7cc-46f0-99d0-7c20cd814f64',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-19T12:06:22.000Z',
                    updated: '2023-08-09T07:02:13.865Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.083323, 51.170287],
                },
                properties: {
                    name: 'SOLINGEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'SOLINGEN',
                    uuid: '4278b45e-f89a-4c97-96a0-598bb781d23b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-29T09:59:21.000Z',
                    updated: '2022-08-29T10:34:57.728Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.1267315010818, 50.2787606877176],
                },
                properties: {
                    name: 'SOSNOWIEC',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SOSNOWIEC',
                    uuid: 'e5ebd264-64b7-4f02-a616-7d1929029e6f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-08-11T14:15:08.000Z',
                    updated: '2022-08-29T10:34:56.924Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-113.612329, 37.12417],
                },
                properties: {
                    name: 'ST-GEORGE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'ST-GEORGE',
                    uuid: 'ecbbfd2a-3bdc-4e4a-af58-5adbc61b9c82',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T19:29:23.383Z',
                    updated: '2023-03-23T19:29:23.383Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-93.26896, 45.11061],
                },
                properties: {
                    name: 'ST-PAUL',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'ST-PAUL',
                    uuid: 'bab1aed7-927a-4156-9e6c-77a2498a6ea5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-15T21:59:20.352Z',
                    updated: '2023-03-15T21:59:20.354Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.0441292852228, 53.337862625952],
                },
                properties: {
                    name: 'STARGARD',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'STARGARD',
                    uuid: '8ed0cced-38f8-4636-94e1-e5bc3fd8226b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-29T14:58:45.000Z',
                    updated: '2023-08-04T09:06:08.607Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.5277944258303, 53.9663890986495],
                },
                properties: {
                    name: 'STAROGARD-GDANSKI',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'STAROGARD-GDANSKI',
                    uuid: '3f424ae0-2756-43c5-b286-d8396b6eb758',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-11T13:46:32.000Z',
                    updated: '2022-08-29T10:34:58.016Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.732779, 58.971516],
                },
                properties: {
                    name: 'STAVANGER',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Berlin',
                    id: 'STAVANGER',
                    uuid: '658a6525-4dc2-4413-89d8-8d1bb70fc60f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-04-17T14:05:19.000Z',
                    updated: '2022-08-29T10:34:58.896Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.113060081023065, 54.32907961476005],
                },
                properties: {
                    name: 'STEGNA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'STEGNA',
                    uuid: '7c118225-563f-4b47-907b-6f682201965f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:47:55.738Z',
                    updated: '2023-08-09T07:02:17.199Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.4233711407044, 48.0446611823165],
                },
                properties: {
                    name: 'STEYR',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'STEYR',
                    uuid: 'd7156743-1c53-44b2-8cfb-63363bec8b00',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-05-04T11:59:19.000Z',
                    updated: '2023-05-02T08:35:34.082Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.3684672, 47.4239851],
                },
                properties: {
                    name: 'STGALLEN',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'STGALLEN',
                    uuid: '05a8908d-a0b1-4c10-af1f-5aa5ed427972',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-05-29T11:49:47.000Z',
                    updated: '2022-08-29T10:34:57.463Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.033120719262342, 52.135961500079986],
                },
                properties: {
                    name: 'STICHTSE-VECHT',
                    isActive: true,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Amsterdam',
                    id: 'STICHTSE-VECHT',
                    uuid: '971eed22-bca1-40b0-b7f9-26810da59791',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-21T16:12:02.792Z',
                    updated: '2023-04-21T16:12:02.793Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-97.111797, 36.146274],
                },
                properties: {
                    name: 'STILLWATER',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'STILLWATER',
                    uuid: 'd1f07173-3d8f-4069-ab81-1173801cec1d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T06:18:03.833Z',
                    updated: '2023-02-24T06:18:03.834Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.92364333412803, 63.470478235747805],
                },
                properties: {
                    name: 'STJORDAL',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'STJORDAL',
                    uuid: 'aec1cccb-89bc-462c-8f72-63b07a462b22',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-07-06T09:43:54.855Z',
                    updated: '2023-08-09T07:01:39.457Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.0701, 59.3361],
                },
                properties: {
                    name: 'STOCKHOLM',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'STOCKHOLM',
                    uuid: 'e8146e2f-ab01-4822-844d-c733ec439bac',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:58.566Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.0852927506853, 54.3097877449109],
                },
                properties: {
                    name: 'STRALSUND',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'STRALSUND',
                    uuid: '2031f8b4-7627-47c6-8d36-2b4c844ede80',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-20T12:50:03.000Z',
                    updated: '2023-03-16T14:41:30.462Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.181876, 48.783435],
                },
                properties: {
                    name: 'STUTTGART',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'STUTTGART',
                    uuid: '55e0dbc1-24d3-49ae-bc98-cdca44488ceb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-22T16:33:03.000Z',
                    updated: '2022-08-29T10:34:57.251Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.487323585914, 50.8425505554865],
                },
                properties: {
                    name: 'SWIDNICA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SWIDNICA',
                    uuid: 'a2560ae9-e59a-4269-a609-9c0ddb48b468',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-08T08:27:55.000Z',
                    updated: '2022-09-08T08:27:55.280Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.45651913209568, 53.403933126264405],
                },
                properties: {
                    name: 'SWIECIE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SWIECIE',
                    uuid: 'ee7ff4f2-0f4f-4f2e-b552-daf6b27f5538',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:50:02.322Z',
                    updated: '2023-02-21T20:50:02.323Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.2533513888889, 53.9166667],
                },
                properties: {
                    name: 'SWINOUJSCIE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SWINOUJSCIE',
                    uuid: 'f93d1a8d-4dd3-4897-bb2d-ce5c80281f6f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-21T12:03:34.000Z',
                    updated: '2022-08-29T10:34:56.845Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.548056, 53.4325],
                },
                properties: {
                    name: 'SZCZECIN',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SZCZECIN',
                    uuid: 'a8ae3b3b-67bc-405c-a99c-cf98431f1a74',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-09T14:50:42.000Z',
                    updated: '2022-08-29T10:34:58.908Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.660057937229283, 53.38342391512535],
                },
                properties: {
                    name: 'SZCZECIN-PRAWOBRZEZE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SZCZECIN-PRAWOBRZEZE',
                    uuid: '0a9e2bff-075b-4d21-bea5-3038e27f4e5e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-06-12T10:18:02.928Z',
                    updated: '2023-06-12T10:18:02.936Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.69298769710707, 53.70951042946328],
                },
                properties: {
                    name: 'SZCZECINEK',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'SZCZECINEK',
                    uuid: '13caaafd-6b6c-4ab8-984a-befc948b081a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T14:43:56.042Z',
                    updated: '2023-03-30T14:43:56.045Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [121.5654, 25.033],
                },
                properties: {
                    name: 'TAIPEI',
                    isActive: false,
                    country: 'TAIWAN, PROVINCE OF CHINA',
                    timezone: 'Africa/Abidjan',
                    id: 'TAIPEI',
                    uuid: '979cf5d6-b5f9-426e-89f2-6b7f4af11a70',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-17T13:02:36.000Z',
                    updated: '2023-03-23T11:52:56.609Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-84.28069406446247, 30.43806072637811],
                },
                properties: {
                    name: 'TALLAHASSEE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'TALLAHASSEE',
                    uuid: '0777a490-0bb1-414b-93da-ee77b75dd398',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-01-23T15:39:29.946Z',
                    updated: '2023-01-23T15:39:29.947Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-82.45868908147449, 27.946621492028996],
                },
                properties: {
                    name: 'TAMPA',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'TAMPA',
                    uuid: 'bb0beaff-5264-4ea5-8a11-c347dfac83f4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-12-08T19:35:57.645Z',
                    updated: '2022-12-08T19:35:57.647Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [23.7596, 61.4987],
                },
                properties: {
                    name: 'TAMPERE',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'TAMPERE',
                    uuid: '80cb2fae-6428-480c-953c-567889f33985',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:31.000Z',
                    updated: '2023-04-11T07:56:23.244Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.85575411398969, 50.44472948923581],
                },
                properties: {
                    name: 'TARNOWSKIE-GORY',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'TARNOWSKIE-GORY',
                    uuid: 'c5d7697d-0de9-47c9-8595-1b8015dba434',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-14T10:25:49.777Z',
                    updated: '2023-02-14T10:25:49.779Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [1.25546669026539, 41.1173665156395],
                },
                properties: {
                    name: 'TARRAGONA',
                    isActive: true,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'TARRAGONA',
                    uuid: '180e06c7-34e2-4f26-aee9-897b718bba22',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-07T09:20:06.000Z',
                    updated: '2022-08-29T10:34:58.920Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.7786954469705, 54.0931825190363],
                },
                properties: {
                    name: 'TCZEW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'TCZEW',
                    uuid: 'cb020884-9789-4dad-ac57-9f1e29428c62',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-29T12:37:36.000Z',
                    updated: '2022-08-29T10:34:58.331Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [34.7891, 32.0857],
                },
                properties: {
                    name: 'TEL_AVIV',
                    isActive: false,
                    country: 'Israel',
                    timezone: 'Asia/Jerusalem',
                    id: 'TEL_AVIV',
                    uuid: 'b7c0d15a-681b-4edb-82b2-6ec0325e3f2f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-07-20T08:52:13.561Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.872292750066009, 51.4461841584789],
                },
                properties: {
                    name: 'TEMP-WH-GER',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TEMP-WH-GER',
                    uuid: '2e695c29-f1d9-4507-8866-a2b0914e1e1d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-13T08:52:29.027Z',
                    updated: '2023-05-22T08:47:52.191Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-112.08307, 33.441],
                },
                properties: {
                    name: 'TEMPE',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'TEMPE',
                    uuid: 'c2c45697-055e-488e-b9cd-7b9ac66292a2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T00:46:13.530Z',
                    updated: '2023-03-16T00:46:13.532Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.404954, 52.520008],
                },
                properties: {
                    name: 'TEST',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TEST',
                    uuid: '47b35ac6-f6ad-4a3b-a4b6-71d6dde4b61b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-08-01T11:36:12.141Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.0402, 47.4979],
                },
                properties: {
                    name: 'TEST-BUDAPEST',
                    isActive: false,
                    country: 'HUNGARY',
                    timezone: 'Europe/Budapest',
                    id: 'TEST-BUDAPEST',
                    uuid: '2f798e34-3f57-4da0-8b26-26489904df93',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-20T15:32:58.000Z',
                    updated: '2023-08-09T07:01:48.476Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [52.520008, 13.404954],
                },
                properties: {
                    name: 'TEST-ROOT-ZONE',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TEST-ROOT-ZONE-3EAI7U3XB2',
                    uuid: '5749aea2-c8e3-44f2-99b8-6c2bcbe8595d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-07T15:37:28.000Z',
                    updated: '2023-03-29T08:36:25.511Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.404954, 52.520008],
                },
                properties: {
                    name: 'TEST-ROOT-ZONE',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TEST-ROOT-ZONE',
                    uuid: 'eabca750-5674-43a6-8bb5-13cbb5234341',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-07T15:14:31.000Z',
                    updated: '2022-08-29T10:34:59.093Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.404954, 52.520008],
                },
                properties: {
                    name: 'TEST-ROOT-ZONE-2',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TEST-ROOT-ZONE-2',
                    uuid: '95496b86-8247-45e5-9737-7a13a2a775f7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-10T12:59:19.000Z',
                    updated: '2022-08-29T10:34:56.905Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.2681776, 59.413832],
                },
                properties: {
                    name: 'TEST2',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'HAUGESUND-MJBSQOQQ0',
                    uuid: '8edc7e3f-4375-40b7-ae44-a2b13a5e709e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-10-01T13:08:26.000Z',
                    updated: '2022-08-29T10:34:56.610Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.3737387560558, 63.3614081573701],
                },
                properties: {
                    name: 'TILLER',
                    isActive: false,
                    country: 'NORWAY',
                    timezone: 'Europe/Oslo',
                    id: 'TILLER',
                    uuid: 'dacbfb26-9c59-41b4-afef-4d6f2a421a7d',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-10T10:32:52.000Z',
                    updated: '2023-05-08T12:06:36.592Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [139.6503, 35.6762],
                },
                properties: {
                    name: 'TOKYO',
                    isActive: false,
                    country: 'JAPAN',
                    timezone: 'Asia/Tokyo',
                    id: 'TOKYO',
                    uuid: '7613c495-d154-4ffe-add8-39d2827a3134',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-09T13:53:09.000Z',
                    updated: '2023-03-23T11:32:36.469Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.95, 55.54],
                },
                properties: {
                    name: 'TOMELILLA',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'TOMELILLA',
                    uuid: 'b8c0638f-846a-47a5-9d1c-186eaa421450',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-10T10:37:19.000Z',
                    updated: '2023-03-16T14:41:28.481Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.17204743448596, 48.56282575095784],
                },
                properties: {
                    name: 'TOPOLCANY',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'TOPOLCANY',
                    uuid: 'ff8357a5-4589-4603-ba4b-7729a81c07dc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T21:06:46.919Z',
                    updated: '2023-02-21T21:06:46.921Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [11.1265336078937, 46.0757960365672],
                },
                properties: {
                    name: 'TRENTO',
                    isActive: true,
                    country: 'ITALY',
                    timezone: 'Europe/Berlin',
                    id: 'TRENTO',
                    uuid: '21636b19-7d1c-4173-8d1f-3c66e6d8c9e0',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T08:57:45.000Z',
                    updated: '2022-08-29T10:34:56.917Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.5837928799689, 48.3767942090382],
                },
                properties: {
                    name: 'TRNAVA',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Bucharest',
                    id: 'TRNAVA',
                    uuid: '2adb14a4-650c-4fb9-b47b-cdd6d7ebe6a7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-06T08:25:19.000Z',
                    updated: '2022-08-29T10:34:56.929Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.14325114718999, 50.8176493959489],
                },
                properties: {
                    name: 'TROISDORF',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TROISDORF',
                    uuid: '6ff90553-4fe3-416f-b710-9bce1167f705',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-12-01T14:21:22.000Z',
                    updated: '2022-08-29T10:34:57.298Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.28624046054918, 58.28099959697494],
                },
                properties: {
                    name: 'TROLLHATTAN',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'TROLLHATTAN',
                    uuid: '2a037356-de70-4e7f-b7c0-b06da122b7e7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-09T08:04:54.447Z',
                    updated: '2023-05-09T08:04:54.448Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.395019, 63.431173],
                },
                properties: {
                    name: 'TRONDHEIM',
                    isActive: true,
                    country: 'NORWAY',
                    timezone: 'Europe/Berlin',
                    id: 'TRONDHEIM',
                    uuid: '336db1b5-f2ea-4849-b1d5-48d29e8ba268',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-04-17T14:02:04.000Z',
                    updated: '2023-04-04T07:47:44.368Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.05721158260431, 51.30718571813267],
                },
                properties: {
                    name: 'TRZEBNICA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'TRZEBNICA',
                    uuid: '0cc23d13-c4a6-4934-8c73-bc03f62b7ecc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-22T08:46:46.795Z',
                    updated: '2023-05-22T08:46:46.797Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.062186, 48.514352],
                },
                properties: {
                    name: 'TUBINGEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'TUBINGEN',
                    uuid: '73e183e5-ba90-4eba-b2ee-bcd2459b052f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-02T14:05:10.000Z',
                    updated: '2022-08-29T10:34:56.941Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-110.88758354118278, 32.19984121926228],
                },
                properties: {
                    name: 'TUCSON',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Phoenix',
                    id: 'TUCSON',
                    uuid: '0e631ec8-97f2-468c-8369-3df878d4cad7',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-08T21:35:31.672Z',
                    updated: '2023-02-08T21:35:31.673Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.685668, 45.070732],
                },
                properties: {
                    name: 'TURIN',
                    isActive: false,
                    country: 'ITALY',
                    timezone: 'Europe/Rome',
                    id: 'TURIN',
                    uuid: '2de60e46-4bc8-4f6d-aa36-4dca2b6da1ad',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-12-12T10:47:46.000Z',
                    updated: '2023-08-09T07:02:03.584Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.265583, 60.447417],
                },
                properties: {
                    name: 'TURKU',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'TURKU',
                    uuid: 'ab782713-1282-49f3-b0cc-9567b9247b40',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-06-25T12:25:54.000Z',
                    updated: '2023-04-03T03:04:39.859Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.0238199842453, 50.1254600056532],
                },
                properties: {
                    name: 'TYCHY',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'TYCHY',
                    uuid: '4523fd03-ab55-4c41-8cee-e5be4fcdb350',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-09-29T12:46:09.000Z',
                    updated: '2022-08-29T10:34:58.028Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-117.2381334, 32.8800604],
                },
                properties: {
                    name: 'UC-SAN-DIEGO',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'UC-SAN-DIEGO',
                    uuid: '7fffae37-8faa-4f2a-9cc3-f7ace331241c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-24T21:07:31.724Z',
                    updated: '2023-03-24T21:07:31.725Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-81.197358, 28.607234],
                },
                properties: {
                    name: 'UCF',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'UCF',
                    uuid: 'e8cbd0c7-06b6-42b3-9037-aec7f8fa200b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-06T22:11:22.035Z',
                    updated: '2023-03-06T22:11:22.036Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.168829, 47.766309],
                },
                properties: {
                    name: 'UEBERLINGEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'UEBERLINGEN',
                    uuid: 'd8e158b6-a494-4302-a0db-1b28d407d117',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-04-06T15:55:27.000Z',
                    updated: '2023-06-30T19:30:53.312Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [9.983811, 48.399513],
                },
                properties: {
                    name: 'ULM',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ULM',
                    uuid: 'f26ec58e-5ecd-4113-b7f0-62cc96cea48b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-09-01T08:48:08.000Z',
                    updated: '2022-08-29T10:34:58.342Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-78.8842282, 36.0209682],
                },
                properties: {
                    name: 'UNIVERSITY-NORTH-CAROLINA',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'UNIVERSITY-NORTH-CAROLINA',
                    uuid: 'cb7bd81a-bdb8-4d2a-9735-a0da7c1cc264',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T01:33:51.468Z',
                    updated: '2023-03-16T01:33:51.469Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-83.376398, 33.959768],
                },
                properties: {
                    name: 'UNIVERSITY-OF-GEORGIA',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'UNIVERSITY-OF-GEORGIA',
                    uuid: '8e3d315e-d929-4116-9ef7-bee24b71947c',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-24T05:54:04.487Z',
                    updated: '2023-02-24T05:54:04.489Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-93.26896, 45.11061],
                },
                properties: {
                    name: 'UNIVERSITY-OF-MINNESOTA',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Chicago',
                    id: 'UNIVERSITY-OF-MINNESOTA',
                    uuid: '261d92f2-086c-4097-8b3f-81f3ac522a8b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-20T19:36:52.218Z',
                    updated: '2023-03-20T19:36:52.219Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-111.8444621, 40.762443],
                },
                properties: {
                    name: 'UNIVERSITY-OF-UTAH',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Denver',
                    id: 'UNIVERSITY-OF-UTAH',
                    uuid: '55dc8a05-3de5-424c-801b-d78ec2f7cc23',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-23T23:00:33.582Z',
                    updated: '2023-03-23T23:00:33.583Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.646165, 59.858255],
                },
                properties: {
                    name: 'UPPSALA',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'UPPSALA',
                    uuid: '77fd8a35-b8b8-4a88-848f-44a7646e1af5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-02-05T12:35:18.000Z',
                    updated: '2023-03-29T07:46:59.377Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.8673341670138, 54.5778049950253],
                },
                properties: {
                    name: 'USTKA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'USTKA',
                    uuid: 'da4c447d-003e-4ad6-93f1-32b6873f87bb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-06-02T14:07:14.000Z',
                    updated: '2022-08-29T10:34:57.321Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.110537, 52.085847],
                },
                properties: {
                    name: 'UTRECHT',
                    isActive: true,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Amsterdam',
                    id: 'UTRECHT',
                    uuid: '49ec5908-57a5-4557-9865-4ada645dead4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-23T12:17:03.000Z',
                    updated: '2022-08-29T10:34:59.118Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.615783, 63.095893],
                },
                properties: {
                    name: 'VAASA',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'VAASA',
                    uuid: '118293f3-3aae-4066-bd1c-3fd08e460004',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-08-26T10:03:09.000Z',
                    updated: '2023-04-17T06:10:28.141Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [6.97746, 45.449245],
                },
                properties: {
                    name: "VAL D'ISERE",
                    isActive: false,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'VAL-DISERE',
                    uuid: 'b8e56ba7-0905-43b1-8412-07c401350ea4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T16:07:59.000Z',
                    updated: '2023-08-09T07:02:13.539Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [25.04, 60.29],
                },
                properties: {
                    name: 'VANTAA',
                    isActive: true,
                    country: 'FINLAND',
                    timezone: 'Europe/Helsinki',
                    id: 'VANTAA',
                    uuid: 'ab2aabaf-ae9d-428b-854a-4786fe78a558',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-06-21T12:04:31.000Z',
                    updated: '2023-04-08T05:28:12.806Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.2521, 57.1071],
                },
                properties: {
                    name: 'VARBERG',
                    isActive: true,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'VARBERG',
                    uuid: '3a221a22-21ba-4c50-915d-015d8c76d796',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-31T11:03:20.000Z',
                    updated: '2023-04-23T20:40:51.700Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.54, 59.61],
                },
                properties: {
                    name: 'VASTERAS',
                    isActive: false,
                    country: 'SWEDEN',
                    timezone: 'Europe/Stockholm',
                    id: 'VASTERAS',
                    uuid: '56971cc5-57f0-4df9-a760-a86587767e5f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-25T13:57:39.000Z',
                    updated: '2023-03-16T14:41:23.272Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.042851, 51.339097],
                },
                properties: {
                    name: 'VELBERT',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'VELBERT',
                    uuid: '19b952c9-5897-441d-8858-fd0a9bbd73bd',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-11T12:29:22.000Z',
                    updated: '2023-08-09T07:01:56.909Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [5.4060209, 51.4176337],
                },
                properties: {
                    name: 'VELDHOVEN',
                    isActive: true,
                    country: 'NETHERLANDS',
                    timezone: 'Europe/Berlin',
                    id: 'VELDHOVEN',
                    uuid: '94c0a5c0-0835-40df-a03f-c4b948943b6e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-12-02T18:05:09.000Z',
                    updated: '2022-08-29T10:34:56.952Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [16.36, 48.21],
                },
                properties: {
                    name: 'VIENNA',
                    isActive: false,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'VIENNA',
                    uuid: '42f9ddbf-b59d-4ec1-9c02-9b36b3bcc313',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:29.000Z',
                    updated: '2023-07-10T18:49:31.946Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.848617, 46.618044],
                },
                properties: {
                    name: 'VILLACH',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'VILLACH',
                    uuid: '01decefd-9114-4711-ad83-27941da9a724',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-03T10:16:51.000Z',
                    updated: '2022-08-29T10:34:57.331Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [2.372321, 48.667839],
                },
                properties: {
                    name: 'VIRY-CHATILLON',
                    isActive: true,
                    country: 'FRANCE',
                    timezone: 'Europe/Paris',
                    id: 'VIRY-CHATILLON',
                    uuid: 'fca60f01-14b3-40eb-817c-087500dc2b40',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-01-06T14:09:40.000Z',
                    updated: '2022-08-29T10:34:58.039Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.683162166177254, 48.89010290974335],
                },
                properties: {
                    name: 'VRANOV-NAD-TOPLOU',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'VRANOV-NAD-TOPLOU',
                    uuid: 'fb53db37-ae78-475b-86c8-64a1f00e5b28',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-29T08:44:20.214Z',
                    updated: '2023-03-29T08:44:20.216Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.49273139994716, 49.883405655951634],
                },
                properties: {
                    name: 'WADOWICE',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WADOWICE',
                    uuid: '6b83a050-bf7a-40ea-9362-25ef6b927b25',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-14T12:44:03.099Z',
                    updated: '2023-08-04T08:08:23.203Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.776088, 47.960379],
                },
                properties: {
                    name: 'WAIDHOFEN-AN-DER-YBBS',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Vienna',
                    id: 'WAIDHOFEN-AN-DER-YBBS',
                    uuid: '496f8c89-8fbd-4be2-b10e-6d212d89e9ae',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-28T05:29:35.000Z',
                    updated: '2022-08-29T10:34:59.145Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [21.0122, 52.2297],
                },
                properties: {
                    name: 'WARSAW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WARSAW',
                    uuid: 'e1ace62b-f755-4b6e-a58a-c213931f15cf',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:57.345Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-76.98931, 38.91348],
                },
                properties: {
                    name: 'WASHINGTON-DC',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'WASHINGTON-DC',
                    uuid: '66681216-472e-48e4-a9c5-8e0ac259c8e5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-17T17:19:14.421Z',
                    updated: '2023-03-17T17:19:14.423Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-117.00693, 46.69846],
                },
                properties: {
                    name: 'WASHINGTON-STATE-UNIVERSITY',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/Los_Angeles',
                    id: 'WASHINGTON-STATE-UNIVERSITY',
                    uuid: '57f5bab4-efc8-4dcd-8e12-e66bb94ef0f9',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T00:30:29.654Z',
                    updated: '2023-03-16T00:30:29.655Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.61874258106696, 47.60409483878],
                },
                properties: {
                    name: 'WEIL-AM-RHEIN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'WEIL-AM-RHEIN',
                    uuid: '712be5d6-bb2b-4110-82f2-81050ebdcb96',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-02-16T13:58:55.000Z',
                    updated: '2023-08-09T07:02:15.090Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [14.0225493, 48.1660456],
                },
                properties: {
                    name: 'WELS',
                    isActive: true,
                    country: 'AUSTRIA',
                    timezone: 'Europe/Berlin',
                    id: 'WELS',
                    uuid: 'e92cdb18-f171-4136-b8f6-f62d24c23e6b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-03-06T14:54:27.000Z',
                    updated: '2022-08-29T10:34:59.157Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [35.28475973807604, 32.1814059078649],
                },
                properties: {
                    name: 'WEST-BANK',
                    isActive: false,
                    country: 'ISRAEL',
                    timezone: 'Asia/Jerusalem',
                    id: 'WEST-BANK',
                    uuid: 'b5dcc591-deb1-4cd3-a968-2c398b7aa1db',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-17T11:46:56.785Z',
                    updated: '2023-03-16T14:41:30.773Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [20.0565582782064, 49.9856282761049],
                },
                properties: {
                    name: 'WIELICZKA',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WIELICZKA',
                    uuid: '08ef6f80-a6d3-4f2b-858b-f8e82dbd67cb',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-03-10T11:11:06.000Z',
                    updated: '2022-08-29T10:34:58.601Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.247132, 50.020202],
                },
                properties: {
                    name: 'WIESBADEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'MAINZ-WIESBADEN',
                    uuid: 'cb89909c-e71c-45d7-92c3-f01ae4899e75',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-07-23T10:01:59.000Z',
                    updated: '2022-08-29T10:34:57.166Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.1069, 53.5323],
                },
                properties: {
                    name: 'WILHELMSHAVEN',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'WILHELMSHAVEN',
                    uuid: 'af0069ee-7b6e-43ad-9e30-a03844c1abab',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-04-22T06:12:13.000Z',
                    updated: '2023-05-31T06:59:48.744Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-80.264263, 36.14862],
                },
                properties: {
                    name: 'WINSTON-SALEM',
                    isActive: true,
                    country: 'UNITED STATES OF AMERICA',
                    timezone: 'America/New_York',
                    id: 'WINSTON-SALEM',
                    uuid: '7daa6981-19dc-4d32-b33f-a629cf9ea479',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-16T01:28:27.844Z',
                    updated: '2023-03-16T01:28:27.845Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.724082, 47.501011],
                },
                properties: {
                    name: 'WINTERTHUR',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Berlin',
                    id: 'WINTERTHUR',
                    uuid: '35b9a526-9dc7-4401-9834-dab47c6c5f93',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-06-10T10:23:36.000Z',
                    updated: '2022-08-29T10:34:58.612Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [7.33823239669323, 51.4388450215715],
                },
                properties: {
                    name: 'WITTEN',
                    isActive: false,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'WITTEN',
                    uuid: '2e9177d4-e32c-436a-9007-ccf113b855ed',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-10-04T12:36:33.000Z',
                    updated: '2023-08-09T07:01:52.234Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.315556, 54.833889],
                },
                properties: {
                    name: 'WLADYSLAWOWO',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WLADYSLAWOWO',
                    uuid: '7954917a-67b1-4749-a8e8-37205c1687e6',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-29T09:57:04.000Z',
                    updated: '2022-08-29T10:34:57.783Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.463219139411677, 50.002457340544765],
                },
                properties: {
                    name: 'WODZISLAW-SLASKI',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WODZISLAW-SLASKI',
                    uuid: 'f016441e-18cc-4e94-a9a7-7e3dc09bdd92',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-01T11:33:11.759Z',
                    updated: '2023-08-09T07:02:11.511Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.5402214257876, 52.1628415883447],
                },
                properties: {
                    name: 'WOLFENBUTTEL',
                    isActive: false,
                    country: 'Germany',
                    timezone: 'Europe/Berlin',
                    id: 'WOLFENBUTTEL',
                    uuid: '86aa80ff-af4b-401e-bb3d-b011a847734e',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-03-11T11:06:06.000Z',
                    updated: '2023-08-09T07:01:50.691Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [10.787889, 52.429021],
                },
                properties: {
                    name: 'WOLFSBURG',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'WOLFSBURG',
                    uuid: 'b6d3fa09-9af5-4196-a8f7-3970281e3006',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-01-10T12:30:23.000Z',
                    updated: '2022-08-29T10:34:57.369Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [17.034699, 51.111439],
                },
                properties: {
                    name: 'WROCLAW',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WROCLAW',
                    uuid: '31923926-b770-4792-8bc2-491e7c118da2',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-29T16:27:23.000Z',
                    updated: '2022-08-29T10:34:59.189Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.87872794458127, 54.34073570683779],
                },
                properties: {
                    name: 'WYSPA-SOBIESZEWSKA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'WYSPA-SOBIESZEWSKA',
                    uuid: '0377a8d8-a8f7-4ec1-8597-5c2979317065',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-02-21T20:50:55.668Z',
                    updated: '2023-08-09T07:01:42.051Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-1.07411301877852, 53.956455680959],
                },
                properties: {
                    name: 'YORK',
                    isActive: true,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'YORK',
                    uuid: '47bf9ef0-7bcf-4ec5-be63-125c6072028f',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-08-25T13:33:53.000Z',
                    updated: '2022-08-29T10:34:56.963Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-1.040869, 53.947813],
                },
                properties: {
                    name: 'YORK_UNIVERSITY',
                    isActive: false,
                    country:
                        'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND',
                    timezone: 'Europe/London',
                    id: 'YORK-UNIVERSITY',
                    uuid: '5f8c0764-24d4-4157-9d5e-8a5272e932be',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-09-25T14:08:53.000Z',
                    updated: '2023-08-09T07:01:54.434Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.320331514993512, 51.61491250128279],
                },
                properties: {
                    name: 'ZAGAN',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZAGAN',
                    uuid: '2f77e693-c0f5-47a1-815f-73b5d2183005',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-18T16:01:08.405Z',
                    updated: '2023-08-04T08:10:35.126Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.88, 41.65],
                },
                properties: {
                    name: 'ZARAGOZA',
                    isActive: false,
                    country: 'SPAIN',
                    timezone: 'Europe/Madrid',
                    id: 'ZARAGOZA',
                    uuid: '8164ef79-d1d8-4087-b471-2bd9f45ba5c4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2023-06-01T13:14:39.609Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.1435061089187, 51.63844745547701],
                },
                properties: {
                    name: 'ZARY',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZARY',
                    uuid: 'fe6e5f82-ac8f-4649-980c-e48ebf6d0b42',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-18T15:53:58.480Z',
                    updated: '2023-08-04T08:10:47.527Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.4333887, 50.4844179],
                },
                properties: {
                    name: 'ZAWIERCIE',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZAWIERCIE',
                    uuid: '688c2e1d-774a-42a0-8632-dd7a20968ca4',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-05-26T15:26:16.000Z',
                    updated: '2023-06-20T09:55:36.474Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [22.0030588177962, 48.8010493486648],
                },
                properties: {
                    name: 'ZEMPLINSKA-SIRAVA',
                    isActive: false,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'ZEMPLINSKA-SIRAVA',
                    uuid: '431e77d4-bb79-4d9a-b90f-6183daba4aae',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-07-01T09:30:48.000Z',
                    updated: '2023-05-08T12:06:55.876Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.020147532750144, 51.15464380006685],
                },
                properties: {
                    name: 'ZGORZELEC',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZGORZELEC',
                    uuid: '5c746a0c-b9d0-4836-9bff-058f971d0e9b',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-04-25T14:55:37.778Z',
                    updated: '2023-08-04T08:11:01.518Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.859623636740736, 48.58689661953608],
                },
                properties: {
                    name: 'ZIAR-NAD-HRONOM',
                    isActive: true,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'ZIAR-NAD-HRONOM',
                    uuid: '665ffd85-abca-4bbe-b13f-2cd4d8749dc5',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-10-13T08:48:31.917Z',
                    updated: '2022-10-13T08:48:31.918Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [15.5049798671081, 51.9381290098579],
                },
                properties: {
                    name: 'ZIELONA-GORA',
                    isActive: false,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZIELONA-GORA',
                    uuid: 'b390bf03-10da-4dfc-921e-eed1666611de',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-06T05:23:07.000Z',
                    updated: '2023-06-02T08:29:12.456Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [18.694452285652744, 50.04556536536367],
                },
                properties: {
                    name: 'ZORY',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZORY',
                    uuid: '1923246f-6ad5-4e5d-b791-44f11f8b8668',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2022-09-21T17:13:06.567Z',
                    updated: '2022-09-21T17:13:06.570Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.5153, 47.1735],
                },
                properties: {
                    name: 'ZUG',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'ZUG',
                    uuid: '2da1f88e-07ce-4273-8fa3-848b3d25d366',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2020-06-26T14:28:42.000Z',
                    updated: '2022-08-29T10:34:56.912Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [8.53563, 47.3728],
                },
                properties: {
                    name: 'ZURICH',
                    isActive: true,
                    country: 'SWITZERLAND',
                    timezone: 'Europe/Zurich',
                    id: 'ZURICH',
                    uuid: 'b354cc01-e713-4d3a-b6ce-0996d0b78a6a',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2019-05-21T10:00:30.000Z',
                    updated: '2022-08-29T10:34:58.623Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.12557121419303, 48.57663693645415],
                },
                properties: {
                    name: 'ZVOLEN',
                    isActive: false,
                    country: 'SLOVAKIA',
                    timezone: 'Europe/Prague',
                    id: 'ZVOLEN',
                    uuid: '61b82260-865b-409f-b04a-9b4910843b44',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-03-30T17:18:34.565Z',
                    updated: '2023-08-09T07:02:01.787Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [12.4793609746631, 50.7167727315705],
                },
                properties: {
                    name: 'ZWICKAU',
                    isActive: true,
                    country: 'GERMANY',
                    timezone: 'Europe/Berlin',
                    id: 'ZWICKAU',
                    uuid: 'da6f5a7d-4fd3-41c1-90d9-3d8094ed7f69',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2021-07-07T17:51:08.000Z',
                    updated: '2022-08-29T10:34:58.355Z',
                    isDynamic: false,
                },
            },
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [19.202978653485985, 49.6891362640298],
                },
                properties: {
                    name: 'ZYWIEC',
                    isActive: true,
                    country: 'POLAND',
                    timezone: 'Europe/Warsaw',
                    id: 'ZYWIEC',
                    uuid: '15be7a7f-fc0e-41e1-a26e-6baf5890fffc',
                    zoneType: 'root',
                    isThrottlingEnabled: false,
                    maxSpeed: 20,
                    created: '2023-05-12T08:00:13.852Z',
                    updated: '2023-05-12T08:00:13.854Z',
                    isDynamic: false,
                },
            },
        ],
    };
    return tempData.data.map(i => ({
        value: i.properties.id,
        label: i.properties.id,
        contry: i.properties.country,
        isActive: i.properties.isActive,
    }));
};
