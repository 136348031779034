import { DataGrid, Text, Box } from "components";
import { mockDataTemp } from "./RequestsMockData";

export const PriceRequests = ({ ...props }) => {

  return (
    <div className="content-wrapper  container" {...props}>
      <Box d="flex" mt={2}>
        <Text bold variant="title_3" flex={1}>
          Pricing Requests
        </Text>
      </Box>
      <Text variant="caption" spaceY={4} flex={1}>
        The Price Request feature is available for commercial managers on the City Configuration page, designed to facilitate change requests at the zone level. Managers can utilize this feature to make modifications either by selecting a predefined price band or by specifying custom prices.
      </Text>
      <Text bold variant="title_4" flex={1}>
        List of Requests
      </Text>
      <DataGrid
        noFilter
        data={mockDataTemp} />
    </div>
  );
};