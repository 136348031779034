import { GridStyled } from './Grid.styled';
import { GridProps } from './Grid.interface';
import React from 'react';
export const Grid: React.FC<GridProps> = ({
    columns = 12,
    ...props
}: GridProps) => {
    return <GridStyled columns={columns} {...props} />;
};

export const Col: React.FC<GridProps> = ({
    columns = 12,
    ...props
}: GridProps) => {
    return <Grid columns={columns} item {...props} />;
};
export const Row: React.FC<GridProps> = ({
    columns = 12,
    ...props
}: GridProps) => {
    return <Grid {...props} columns={columns} container />;
};
