import { DataGrid, Text, Box } from 'components';
import moment from 'moment';

import { usePriceBands } from 'services/priceBandsSetter';

export const PriceBands = ({ ...props }) => {
    const { priceBandsData, priceBandsupdateDate } = usePriceBands();

    return (
        <div className="content-wrapper  container" {...props}>
            <Box d="flex" mt={2}>
                <Text bold variant="title_3" flex={1}>
                    Pricing bands
                </Text>
            </Box>
            <Text spaceY={2} bold variant="caption" flex={1}>
                Below is a list of available price bands that can be selected as
                options for price changes in the 'Edit Price Plans' or when
                requesting changes.
            </Text>
            <Text variant="caption" flex={1}>
                Price bands function as pre-set, drafted values that are just a
                autocompletion and do not directly relate to the price values
                saved in the database. For example, if any zone or periodic
                price bands are updated (edited) after selecting them, the
                corresponding zones or periods will not receive the update. They
                will continue to operate with the previous set of values and
                will be excluded from the price band in the detailed view of the
                price when viewing zone prices."
            </Text>
            <Text bold variant="caption">
                {' '}
                (last update:{' '}
                {moment(priceBandsupdateDate, 'DD-MM-YYYY').format('MMM Do YY')}
                )
            </Text>
            <DataGrid noFilter data={priceBandsData} />
        </div>
    );
};
