import { TokenId } from '../utils/tokenId';
import useSWR from 'swr';
export const baseUrl = () => window.API_URL || '';

const getPayload = response => {
    const contentType = response.headers.get('Content-Type');
    if (
        contentType &&
        contentType.includes('json') &&
        ![204, 205, 201].includes(response.status)
    ) {
        return response.json();
    }
    if (
        contentType &&
        contentType.includes('text') &&
        ![204, 205, 201].includes(response.status)
    ) {
        return response.text();
    }
    return null;
};

export const fetcher = async (props: any, options: any) => {
    const tokenId = await TokenId();
    const data = await fetch(props.url, {
        ...options,
        headers: { Authorization: `Jwt ${tokenId}`, ...options?.headers },
    });
    const status: number = Number(data.status);
    const res = await getPayload(data);

    let mappedRes: any = { status: '' }; 
    if (props.mapper) {
        mappedRes = await props.mapper(res);
    } else {
        mappedRes = res || {};
    }
    mappedRes = Object.assign(mappedRes, { status: status });

    return mappedRes;
};

export const useGet = (url: string, mapper: any = null) =>
    useSWR({ url, mapper }, fetcher);
