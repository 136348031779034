import { BadgeStyled } from './Badge.styled';
import { BadgeProps } from './Badge.interface';
import React from 'react';
import { Tooltip } from 'antd';
// import { SVG } from 'components';
export const Badge: React.FC<BadgeProps> = ({
    color = 'primaryBlue',
    variant = 'contained',
    size = 'medium',
    icon = '',
    endIcon = '',
    children,
    disabled = false,
    fullWidth = false,
    as = 'div',
    info = '',
    label,
    ...props
}: BadgeProps) => {
    return (
        (children || label) ?
            <Tooltip placement="bottom" title={info}>
                <BadgeStyled
                    {...props}
                    as={as}
                    variant={variant}
                    size={size}
                    fullWidth={fullWidth}
                    color={color}
                    disabled={disabled}
                >
                    {/* {info && (
        
  
       
      )} */}
                    {/* {icon && <SVG size={size} icon={icon} />} */}
                    {children || label}
                    {/* {endIcon && <SVG size={size} icon={endIcon} />} */}
                </BadgeStyled>
            </Tooltip>
            : <span />
    );
};
