import { DataGrid, Text, Box, Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { hasRole } from 'utils';
export const PricingPlansTemplate = ({
    type,
    pricePlans,
    roles = [],
    pricePlansIsLoading,
    changeFilter,
    ...props
}) => {
    const navigate = useNavigate();
    const editable = hasRole(roles, ['admin', 'pricing-manager']);

    return (
        <div className="content-wrapper  container" {...props}>
            <Box d="flex" mt={2}>
                <Text bold variant="title_3" flex={1}>
                    Pricing configurations
                </Text>
                <Box d="flex" gap={2}>
                    <Button
                        size="small"
                        onClick={() => navigate('ab-test-add')}
                        icon="FaPlus"
                        hidden={!editable}
                        // disabled
                    >
                        A/B Test
                    </Button>
                    <Button
                        size="small"
                        onClick={() => navigate('periodic-add')}
                        icon="FaPlus"
                        hidden={!editable}
                    >
                        Periodic
                    </Button>
                    {/* <Button
                        size="small"
                        onClick={() => navigate('periodic-add-ical')}
                        icon="FaPlus"
                        hidden={!editable} 
                    >
                        Periodic iCal
                    </Button> */}
                    {/* <Button
                        size="small"
                        onClick={() => navigate('price-change-request')}
                        icon="FaPlus"
                        hidden={!editable} 
                    >
                        Change request
                    </Button> */}
                </Box>
            </Box>
            <DataGrid
                type={type}
                changeFilter={changeFilter}
                loading={pricePlansIsLoading}
                data={pricePlans}
            />
        </div>
    );
};
