import { Fragment } from "react";
import * as FaIcons from "react-icons/fa";
import { TbTestPipe } from "react-icons/tb";
import { IconProps } from "./Icon.interface";

const Icons = { ...FaIcons, TbTestPipe }

export const Icon: React.FC<IconProps> = ({
    color = 'primaryBlue',
    size = 'medium',
    icon = '',
    ...props
}: IconProps) => {
    const Tag = Icons[icon] || Fragment
    return (<Tag {...props} />);
};