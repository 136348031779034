import { authActions } from '@tierpark/auth-client';

export const TokenId = async () => {
    const idToken: string = (await authActions.getCurrentToken()) || '';
    return idToken;
};
export const CurrentUser = async () => {
    const CurrentUser: any = (await authActions.getCurrentUser()) || '';
    return CurrentUser.multiFactor.user;
};
