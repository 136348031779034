import React from 'react';
import { ModalStyled, ModalBackdropStyled } from './Modal.styled';
import { ModalProps } from './Modal.interface';
import { useEffect, useState } from 'react';
import { Button, Card, LoadingSpinner } from 'components';
export const Modal: React.FC<ModalProps> = ({
    isOpen = false,
    onClickClose = () => {},
    title,
    children,
    disableOnBackdropClick = false,
    HeaderRightSide,
    withoutCloseButton = false,
    withExpandButton = true,
    footer,
    FooterLeftSide,
    isLoading = false,
    ...props
}: ModalProps) => {
    const [isShow, setIsShow] = useState<undefined | boolean>(undefined);
    const [expanded, setExpanded] = useState<undefined | boolean>(undefined);

    useEffect(() => {
        if (undefined != 'undefined') setIsShow(isOpen);
    }, [isOpen]);

    //useEffect added EventListener and handled pressing ESC from closing PopUp in desktop mode
    useEffect(() => {
        window.addEventListener(
            'keydown',
            event => {
                if (event.code === 'Escape' || event.which === 27) {
                    setIsShow(false);
                }
            },
            true
        );
    }, []);

    // This useEffect handel close on click Close button.
    useEffect(() => {
        if (isShow === false) {
            setTimeout(() => {
                onClickClose();
            }, 200);
        }
    }, [isShow]);

    return isShow ? (
        <ModalBackdropStyled
            id={`back-drop-${props.id}`}
            onClick={(e: any) =>
                !disableOnBackdropClick &&
                e.target.id === `back-drop-${props.id}` &&
                setIsShow(false)
            }
            show={!!isShow}
            key={props.key}
        >
            <ModalStyled
                bodyProps={{
                    style: {
                        flex: 1,
                        maxHeight: !expanded ? '80vh' : '100vh',
                        overflow: 'scroll',
                    },
                }}
                as={Card}
                show={!isShow}
                expanded={expanded}
                {...props}
                footer={footer}
                FooterLeftSide={FooterLeftSide}
                HeaderRightSide={
                    <>
                        {HeaderRightSide}
                        {withExpandButton && (
                            <Button
                                m={'-8px'}
                                ml={0.5}
                                h={8}
                                w={8}
                                icon={expanded ? 'FaCompress' : 'FaCompress'}
                                rounded
                                size="small"
                                variant="outlined"
                                onClick={() => setExpanded(!expanded)}
                                sx={{ border: 'none' }}
                            />
                        )}
                        {!withoutCloseButton && (
                            <Button
                                m={'-8px'}
                                ml={0.5}
                                h={8}
                                w={8}
                                icon="clear"
                                rounded
                                size="small"
                                variant="outlined"
                                onClick={() => setIsShow(false)}
                                sx={{ border: 'none' }}
                                type="button"
                            >
                                ✕
                            </Button>
                        )}
                    </>
                }
            >
                <LoadingSpinner
                    isLoading={isLoading}
                    xs={{
                        background: '#a3a3a32b',
                        zIndex: '99',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: 'calc(100%)',
                        pointerEvents: 'none',
                        height: 'calc(100%)',
                    }}
                />

                {children}
            </ModalStyled>
        </ModalBackdropStyled>
    ) : (
        <div />
    );
};
