import styled, { css } from 'styled-components';
import { defaultTheme } from 'utils/theming';

export const InputStyled = styled.select<any>`
    font-weight: 700;
    font-size: 14px;
    flex: 1;
    line-height: ${({ placeholder, theme }) =>
        placeholder ? theme.space[8] : theme.space[5]};
    height: ${({ theme }) => theme.space[10]};
    padding: ${({ theme }) => theme.space[0]};
    margin: ${({ theme }) => theme.space[0]};
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primaryBlue};
    border: none;
    :focus {
        box-shadow: none;
        outline: none;
    }
    padding-left: ${({ theme, hasIcon }) =>
        hasIcon ? theme.space[10] : theme.space[2]};
`;
export const LabelStyled = styled.label<any>`
    padding: 0;
    margin: 0;
    ${({ hide }) =>
        hide
            ? css`
                  display: none;
              `
            : css`
                  display: flex;
              `}
    font-weight: 700;
    font-size: 12px;
    line-height: ${({ theme }) => theme.space[5]};
    color: ${({ theme }) => theme.palette.gray6};
`;
export const ContainerStyled = styled.div<any>`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 4px;
`;
export const InputAndIconsContainerStyled = styled.div<any>`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    .icon {
        position: absolute;
        overflow: visible;
        left: 12px;
    }
    background: ${({ theme }) => theme.palette.gray2};
    border: 1px solid ${({ theme }) => theme.palette.gray2};
    border-radius: ${({ theme }) => theme.space[2]};
`;
export const TextAfterStyled = styled.div<any>`
    padding-right: 8px;
`;

InputAndIconsContainerStyled.defaultProps = defaultTheme;
InputStyled.defaultProps = defaultTheme;
LabelStyled.defaultProps = defaultTheme;
