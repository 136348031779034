import React from 'react';
import { ToggleSwitchStyled } from './ToggleSwitch.styled';
import { ToggleSwitchProps } from './ToggleSwitch.interface';
import {
    Icon,
    // SVG,
    Text
} from 'components';


export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    children,
    className,
    icon,
    offStateIcon,
    label,
    ...props
}) => {
    const IconOffState = offStateIcon && (
        <Icon className="icon-off" icon={offStateIcon} color="primaryBlue" p={1}/>
    );
    const IconSwitch = icon && (
        <Icon className="icon" icon={icon} color="primaryBlue" p={1}/>
    );
    return (
        <ToggleSwitchStyled className={className} as="label" {...props}>
            <input className="checkbox" type="checkbox" {...props} />
            <div className="switch">
                {IconSwitch}
                {IconOffState}
            </div>
            <Text className="label" bold variant="title_5">
                {label || children}
            </Text>
        </ToggleSwitchStyled>
    );
};
