import React from 'react';
import { LoadingSpinnerStyled } from './LoadingSpinner.styled';
import { LoadingSpinnerProps } from './LoadingSpinner.interface';

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
    isLoading,
    ...props
}) => {
    return (
        <>
            {isLoading ? (
                <LoadingSpinnerStyled {...props}>
                    <svg className="spinner" viewBox="0 0 40 40">
                        <circle cx="20" cy="20" r="18"></circle>
                    </svg>
                </LoadingSpinnerStyled>
            ) : null}
        </>
    );
};
