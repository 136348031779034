import { ButtonStyled } from './Button.styled';
import { ButtonProps } from './Button.interface';
import React from 'react';
import { Icon } from 'components';
export const Button: React.FC<ButtonProps> = ({
    color = 'primaryBlue',
    variant = 'contained',
    size = 'medium',
    icon = '',
    endIcon = '',
    children,
    disabled = false,
    fullWidth = false,
    as = 'button',
    label,
    ...props
}: ButtonProps) => {
    return (
        <ButtonStyled
            as={as}
            variant={variant}
            textOverflowShowDot
            size={size}
            fullWidth={fullWidth}
            color={color}
            disabled={disabled}
            noPrint={as === 'button'}
            hasText={Boolean(children || label)}
            type="button"
            {...props}
        >
            {icon && <Icon size={size} icon={icon} />}
            {children || label}
            {endIcon && <Icon size={size} icon={endIcon} />}
        </ButtonStyled>
    );
};


export default Button