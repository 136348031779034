export const csvData = `id,country,vehicleType,name,UnlockFee,MinuteFee,IdleFee,updateDate
53976c6e-0b53-11ee-be56-0242ac120002,Poland,eScooter,A,5,1.1,1.1,23-10-2023
57fc0ada-0b53-11ee-be56-0242ac120002,Poland,eScooter,B,3.9,0.89,0.89,
5d027e06-0b53-11ee-be56-0242ac120002,Poland,eScooter,C,3.5,0.79,0.79,
61198ef8-0b53-11ee-be56-0242ac120002,Poland,eScooter,D,3,0.69,0.69,
657c2082-0b53-11ee-be56-0242ac120002,Poland,eScooter,E,2.5,0.59,0.59,
59ad4014-0929-11ee-be56-0242ac120002,Germany,eScooter,A,1.2,0.27,0.27,
77b78584-0928-11ee-be56-0242ac120002,Germany,eScooter,B,1.2,0.25,0.25,
e736aa34-0928-11ee-be56-0242ac120002,Germany,eScooter,C,1.2,0.23,0.23,
54ae8870-0b54-11ee-be56-0242ac120002,Germany,eScooter,D,1.2,0.2,0.2,
58a74a52-0b54-11ee-be56-0242ac120002,Germany,eScooter,E,1,0.2,0.2,
ff34f164-4c15-46e2-be9e-51fe72723e72,Hungary,eScooter,A,1.22,0.27,0.27,
29e3292c-afe4-4984-acb4-cbc612a28ff2,Hungary,eScooter,B,0.95,0.24,0.24,
b5f148ea-9d09-45ce-a688-961551f3ba3e,Hungary,eScooter,C,0.81,0.22,0.22,
c37d87b9-c0f5-4a66-9f44-269cd400b61c,Hungary,eScooter,D,0.81,0.19,0.19,
0af1ffb9-59cf-4a98-ace6-49b1588afb79,Hungary,eScooter,E,0.68,0.16,0.16,
ea2ab811-0410-4e72-b139-ed78bef198bf,Hungary,eScooter,F,0.00,0.16,0.16,
b7e953dc-9d3b-48df-9dc6-edb553afc3a4,Slovakia,eScooter,A,1.2,0.27,0.27,
96419a56-7a8f-4c1e-9b4b-f2277fe91b32,Slovakia,eScooter,B,1.2,0.25,0.25,
90db0667-cfc3-4687-a122-e7145c719542,Slovakia,eScooter,C,1,0.25,0.25,
70537e62-c5c0-42d6-9d98-ce152ca3094d,Slovakia,eScooter,D,1,0.23,0.23,
cfe5413b-4f8c-4cf0-8ce3-595dd9a89abe,Slovakia,eScooter,E,0.1,0.2,0.2,
2e47b828-ed2c-4f45-ad73-ddd464d70812,Slovakia,eScooter,F,0,0.2,0.2,
44678de4-b961-436f-b0f4-edf1d3e90565,Austria,eScooter,A,1.2,0.4,0.4,
54945645-bc33-4b72-ba3f-f82464691f72,Austria,eScooter,B,1.2,0.35,0.35,
ea15023b-7e24-40e2-8840-97d0ac9e8ae0,Austria,eScooter,C,1.2,0.32,0.32,
fb949643-ff29-4842-9aac-b4a5b86858f0,Austria,eScooter,D,1,0.28,0.28,
a4a6eb17-267c-4788-8bc8-96f1b9a6c789,Austria,eScooter,E,0.5,0.2,0.2,
25c9ef31-1bf2-4fd6-bfb2-ab89b71a2281,Austria,eScooter,F,0,0.3,0.3,
ad1e312f-1af9-4f6e-a575-24fe888e8736,Switzerland,eScooter,A,2,0.55,0.55,
5228e891-f564-4c8e-bfae-a2c800df8390,Switzerland,eScooter,B,1.5,0.55,0.55,
97094f26-879e-4352-b96a-258752437fd7,Switzerland,eScooter,C,1.5,0.45,0.45,
321a3b9b-4c98-49f8-ae69-73537bc832ec,Switzerland,eScooter,D,1.5,0.4,0.4,
bc35e367-920f-40f3-81be-14eaae860e9d,Switzerland,eScooter,E,1,0.35,0.35,
16c49d37-d637-4d30-917f-c42929196811,Switzerland,eScooter,F,1,0.3,0.3,`