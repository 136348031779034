import csv from 'csvtojson';
import { useEffect, useState } from 'react';
import { csvData } from 'services/PriceBandsFile';

export const priceBandsData = async () => 
    csv().fromString(csvData)


export const priceBandsSetter = (setCsvRows = ({}) => {}) => {
    csv()
        .fromString(csvData)
        .then(data => {
            let priceBandsupdateDate = '';
            if (data.length > 0) {
                priceBandsupdateDate = data[0].updateDate;
                // Remove the "updateDate" property from the first record
                delete data[0].updateDate;
            }
            setCsvRows({ priceBandsData: data, priceBandsupdateDate });
        });
};

export function usePriceBands() {
    const [csvData, setCsvRows]: any = useState([]);
    useEffect(() => {
        priceBandsSetter(setCsvRows); // Call your service function here
    }, []);

    //   const updateDate = csvRows[0].updateDate || ''

    return csvData;
}
