import { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
    Input,
    Text,
    Modal,
    Button,
    Box,
    Divider,
    Select,
    Autocomplete,
    ToggleSwitch,
    Icon,
} from 'components';

import { submitForm } from './submitForm';
import { mockData as zoneOptions } from 'components/Molecules/Autocomplete/Autocomplete.mock';
import { mockData } from '../../Pages/HomePage/pricingMockData';

interface FormValues {
    OBAFee?: number;
    holdFee?: number;
    unlockPriceEScooter?: number;
    minutePriceEScooter?: number;
    pausePriceEScooter?: number;
    idlePriceEScooter?: number;
    unlockPriceEBike?: number;
    minutePriceEBike?: number;
    pausePriceEBike?: number;
    idlePriceEBike?: number;
}

const initialValues: FormValues = {
    OBAFee: 0,
    holdFee: 0,
    unlockPriceEScooter: 0,
    minutePriceEScooter: 0,
    pausePriceEScooter: 0,
    idlePriceEScooter: 0,
    unlockPriceEBike: 0,
    minutePriceEBike: 0,
    pausePriceEBike: 0,
    idlePriceEBike: 0,
};

const TextInput = ({ ...props }: any) => <Field {...props} as={Input} />;

export const PeriodicAdd = ({ edit = false, ...props }) => {
    const [values, setValues] = useState<FormValues>(initialValues);
    const navigate = useNavigate();
    const [rules, setRules] = useState<any>([]);
    const [typeOfpriceEntry, setTypeOfpriceEntry] = useState<any>(1);
    const [zones, setZones] = useState<any>([]);
    const [name, setName] = useState<any>('');
    const [idPrice, setIdPrice] = useState<any>('');
    const [asDiscount, setAsDiscount] = useState<any>('');

    const handleSubmit = (values: FormValues) => {
        submitForm(values).then(handleClose);
    };

    const handleClose = () => {
        navigate(-1);
    };

    const eventName = `${name.replace(/\s+/g, '-')}_${zones
        .map(i => i.value)
        .join('-')}_${asDiscount ? 'discount' : 'periodic'}_${idPrice}`;
    return (
        <Formik initialValues={values} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <Modal
                        w={'700px'}
                        isOpen={true}
                        header="Add Periodic to Google Calendar"
                        onClickClose={handleClose}
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </>
                        }
                    >
                        <Text bold mb={4} mr={16}>
                            To streamline the process of generating event names
                            in format for Google Calendar, we have here.
                        </Text>
                        <Text variant="caption" mb={4} mr={16}>
                            Follow the steps below to ensure you provide all the
                            necessary information for your event. Once you've
                            filled in the required fields, you'll see a green
                            checkmark, indicating at the end that you're ready
                            to add the event to your Google Calendar. Simply
                            click the link provided to proceed to Google
                            Calendar and setup a perodic furthermore step there.
                        </Text>
                        <Text variant="caption" mb={4} mr={16}>
                            Note: Any edits or deletions could be applied
                            directly in Google Calendar and wiht the link provid
                            in disciption of event you can back to prcie values
                            to edit.
                        </Text>
                        <TextInput
                            required
                            label="Pricing name"
                            mb={8}
                            w={52}
                            name="pricingName"
                            onChange={e => {
                                setName(e.target.value);
                            }}
                        />
                        <Autocomplete
                            placeholder="Zones"
                            label="Zones"
                            name="zones"
                            {...zoneOptions}
                            onChange={setZones}
                            isMulti
                        />
                        <Divider spaceX="-16px" mt={8} />
                        <Select
                            options={[
                                {
                                    label: 'Select from exist prices planes',
                                    value: 1,
                                },
                                { label: 'Add new prices', value: 2 },
                            ]}
                            onChange={e => setTypeOfpriceEntry(e.target.value)}
                        ></Select>
                        {typeOfpriceEntry == 1 && (
                            <Select
                                mt={2}
                                options={[
                                    { label: 'Not Selected', value: '' },
                                    ...mockData.data.map(i => ({
                                        label: i.ID,
                                        value: i.ID,
                                    })),
                                ]}
                                onChange={e => setIdPrice(e.target.value)}
                            ></Select>
                        )}
                        <Divider spaceX="-16px" mt={4} />
                        <Text variant="caption" mb={2} mr={10}>
                            To mark the changes in consumer apps and show to
                            offer discount periodically, toggle it.{' '}
                        </Text>
                        <ToggleSwitch
                            onChange={e => {
                                setAsDiscount(e.target.checked);
                            }}
                            mb={2}
                        >
                            As Discount
                        </ToggleSwitch>

                        {typeOfpriceEntry == 2 && (
                            <>
                                <Divider
                                    header="Pricing EScooter"
                                    spaceX="-16px"
                                    mt={8}
                                />
                                <Box
                                    d="flex"
                                    gap={4}
                                    mt={2}
                                    xs={{ flexWrap: 'wrap' }}
                                >
                                    <TextInput
                                        w={32}
                                        label="Unlock price"
                                        name="unlockPriceEScooter"
                                        textAfter="EUR"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Minute price"
                                        name="minutePriceEScooter"
                                        textAfter="EUR/min"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Pause price"
                                        name="pausePriceEScooter"
                                        textAfter="EUR/min"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Idle price"
                                        name="idlePriceEScooter"
                                        textAfter="EUR"
                                        type="number"
                                    />
                                </Box>
                                <Divider
                                    header="Pricing EBike"
                                    spaceX="-16px"
                                    mt={8}
                                />
                                <Box
                                    d="flex"
                                    gap={4}
                                    mt={2}
                                    xs={{ flexWrap: 'wrap' }}
                                >
                                    <TextInput
                                        w={32}
                                        label="Unlock price"
                                        name="unlockPriceEBike"
                                        textAfter="EUR"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Minute price"
                                        name="minutePriceEBike"
                                        textAfter="EUR/min"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Pause price"
                                        name="pausePriceEBike"
                                        textAfter="EUR/min"
                                        type="number"
                                    />
                                    <TextInput
                                        w={32}
                                        label="Idle price"
                                        name="idlePriceEBike"
                                        textAfter="EUR"
                                        type="number"
                                    />
                                </Box>
                                <Divider spaceX="-16px" mt={8} />
                                <Button mt={4} type="submit">
                                    Save prices to generat priceId
                                </Button>
                            </>
                        )}
                        <Divider
                            header="Add to i Calender"
                            spaceX="-16px"
                            mt={8}
                        />
                        <Text variant="caption" spaceY={2}>
                            Event name for google calcalander
                            (format:Name_Zones_Type_Id):
                        </Text>
                        <Text
                            bold
                            as={zones.length && name && idPrice && 'a'}
                            target="_blank"
                            href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${eventName}&details=https://tierpark.tier-services.io/pricing/price/${idPrice}`}
                        >
                            {eventName}
                        </Text>
                        <br />
                        {zones.length && name && idPrice ? (
                            <Icon
                                w={20}
                                h={20}
                                color="primaryVehicleGreen"
                                icon="FaCheck"
                            />
                        ) : (
                            ''
                        )}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
