import { Badge, Text, Box, Button, Icon } from 'components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { isDateOlderThanSixWeeks } from './helper';

const iconMapper = {
    zones: 'FaCity',
    countries: 'FaGlobeAfrica',
    periodic: 'FaCalendarAlt',
    experiment: 'TbTestPipe',
};

const convertRRuleToStr = recurrence => {
    const rule = rrulestr(recurrence);
    return rule.toText();
};

export const PricingItem = ({ row, ...props }) => {
    const navigate = useNavigate();
    const {
        id,
        name,
        timezone,
        currency,
        company,
        countryId,
        country,
        countryName,
        type,
        updatedAt,
        priceCommsEnabled,
        pricing = [],
        abTests = [],
        periodics = [],
        recurrence = '',
        editedBy,
        createdBy,
        createdAt,
        validFrom,
        validTo,
        zoneId,
        internalName,
        key,
        referenceId,
        referenceType,
        isActive,
        pricesBand,
        ...rowOtherItems
    } = row;

    return (
        <Box
            withBorder
            round={2}
            p={4}
            m={3}
            key={key}
            bgColor={isActive || type == 'countries' ? 'white' : 'gray2'}
            d="flex"
            fadeIn
            {...props}
        >
            <Box flex={1} d="flex">
                <Box d="flex" mw={type != 'country' ? 80 : 52}>
                    <Button
                        size="lg"
                        bold
                        mr={2}
                        mt="-8px"
                        mb="-8px"
                        hidden={!name}
                        variant="text"
                        onClick={() => {
                            navigate(type + '-edit/' + id);
                        }}
                    >
                        {type == 'countries' ? (
                            <img
                                loading="lazy"
                                width="32"
                                srcSet={`https://flagcdn.com/32x24/${country.toLowerCase()}.webp`}
                                src={`https://flagcdn.com/32x24/${country.toLowerCase()}.webp`}
                                alt=""
                            />
                        ) : (
                            <Icon
                                // mr={2}
                                xs={{ fontSize: '23px' }}
                                icon={iconMapper[type]}
                            />
                        )}
                        {name}{' '}
                    </Button>
                    {country && (
                        <Text
                            hidden={type == 'country'}
                            variant="caption"
                            // mr={4}
                            mt={0.5}
                            bold
                            color="primaryOrange"
                            info="Country"
                        >
                            {' '}
                            ({country})
                        </Text>
                    )}
                </Box>

                <Badge
                    size="small"
                    // mr={4}
                    color="primaryGreen"
                    hidden={!company}
                    info={company && company.name}
                    variant=""
                >
                    Co.
                </Badge>
                <Badge
                    size="small"
                    // mr={4}
                    color="primaryBlue"
                    variant="texted"
                    hidden={!currency}
                    title="currency"
                >
                    {currency}
                </Badge>
                {pricing
                    .filter(i => i.vehicleType != 'emoped')
                    .map(i => (
                        <>
                            {i.pricesBand && (
                                <Badge
                                    size="small"
                                    // mr={4}
                                    color="secondaryOrange"
                                    hidden={!i.pricesBand}
                                    title="Prices Band"
                                    info="Prices Band"
                                    xs={{ borderRadius: '10px 0 0 10px' }}
                                >
                                    {i.pricesBand}
                                </Badge>
                            )}
                            <Badge
                                xs={
                                    i.pricesBand && i.vehicleType == 'escooter'
                                        ? { borderRadius: '0 10px 10px 0' }
                                        : {}
                                }
                                key={id + '-' + i.vehicleType}
                                size="small"
                                mr={1}
                                color={
                                    i.vehicleType == 'escooter'
                                        ? 'primaryGreen'
                                        : 'primaryBlue'
                                }
                                info={`${i.vehicleType} Pricing (Unlock + Minute)`}
                            >
                                {`${i.unlockFee} + ${i.runningFee}`}
                            </Badge>
                        </>
                    ))}
                <div>
                    {recurrence &&
                        recurrence != 'None' &&
                        recurrence.split('\n').map(i => (
                            <Badge
                                size="small"
                                mr={4}
                                mb={1}
                                color={
                                    i.split(':')[0] != 'RRULE'
                                        ? 'primaryBlue'
                                        : 'red'
                                }
                                variant="outlined"
                                hidden={!recurrence}
                                title="recurrence"
                                info={i.split(':')[0]}
                            >
                                {i.includes('EXDATE') || i.includes('RDATE')
                                    ? moment(i.split(':')[1]).format(
                                          'YYYY-MM-DD'
                                      )
                                    : convertRRuleToStr(i || '')}
                            </Badge>
                        ))}
                </div>
                <Box ml={2} gap={1} flex={1} d="flex" sx={{ flexWrap: 'wrap' }}>
                    {Object.keys(rowOtherItems).map(key => {
                        const value = rowOtherItems[key];
                        if (value != (undefined || ''))
                            return Array.isArray(value)
                                ? value.map(i => (
                                      <Badge
                                          key={key + id}
                                          hidden={!rowOtherItems[key]}
                                          size="small"
                                          info={key}
                                          color="primaryOrange"
                                          variant="outlined"
                                      >
                                          {i}
                                      </Badge>
                                  ))
                                : value != (undefined || '') && (
                                      <Badge
                                          key={key + id}
                                          hidden={!rowOtherItems[key]}
                                          size="small"
                                          info={key}
                                          color="primaryOrange"
                                          variant="outlined"
                                      >
                                          {key
                                              .replace('Fee', '')
                                              .replace('At', '')
                                              .replace('internalName', '')
                                              .replace('countryName', '')
                                              .replace('Percentage', '')
                                              .replace('targetZones', '')
                                              .replace('vehicleType', '')
                                              .replace('experimentKey', '')
                                              .replace('experimentName', '')
                                              .replace(
                                                  'convenience',
                                                  'CNV'
                                              )}{' '}
                                          {rowOtherItems[key]}
                                      </Badge>
                                  );
                    })}
                </Box>
            </Box>
            <Box d="flex" ml={2} gap={2}>
                {abTests.map(i => (
                    <Badge
                        key={'abTest' + id}
                        xs={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('ab-test-edit/pricing-plans/undefined');
                        }}
                        size="small"
                        color="primaryBlue"
                        // mr={2}
                        info=" Has A/B Test"
                    >
                        A/B
                    </Badge>
                ))}
                {periodics.map(i => (
                    <Badge
                        key={'periodics' + id}
                        xs={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('periodic-edit/pricing-plans/undefined');
                        }}
                        size="small"
                        color="primaryBlue"
                        // mr={2}
                        info="This Periodic prciing efectting here"
                    >
                        Periodic
                    </Badge>
                ))}
                {isActive && (
                    <Badge
                        size="small"
                        color="primaryBlue"
                        variant="texted"
                        // mr={2}
                        info="is Active"
                    >
                        act.
                    </Badge>
                )}
                {priceCommsEnabled && (
                    <Badge
                        size="small"
                        color="primaryBlue"
                        // mr={2}
                        info="This Periodic prciing efectting here as asDiscount"
                    >
                        As Discount
                    </Badge>
                )}
                <Badge
                    hidden={!updatedAt}
                    size="small"
                    color={
                        isDateOlderThanSixWeeks(updatedAt)
                            ? 'gray5'
                            : 'secondaryOrange'
                    }
                    // mr={2}
                    variant={
                        isDateOlderThanSixWeeks(updatedAt)
                            ? 'outlined'
                            : 'contained'
                    }
                    info={`Last edit ${
                        isDateOlderThanSixWeeks(updatedAt)
                            ? ', change date is more than 6 weeks ago'
                            : ', it recently change'
                    }`}
                >
                    {/* @ts-ignore */}
                    {moment(updatedAt).format('MMM Do YY')}
                </Badge>
                {/* @ts-ignore */}
                <Badge
                    hidden={!createdAt}
                    size="small"
                    color="gray5"
                    // mr={2}
                    variant="outlined"
                    // @ts-ignore
                    info={`Created at ${moment(createdAt).format(
                        ' YYYY/MM/DD'
                    )} by ${createdBy}`}
                >
                    {/* @ts-ignore */}
                    {moment(createdAt).format('MMM  YYYY')}
                </Badge>
                <Badge
                    hidden={!validFrom}
                    size="small"
                    color={isActive ? 'primaryGreen' : 'gray4'}
                    variant={isActive ? 'contained' : 'outlined'}
                    // mr={2}
                    info={`start and end, ${
                        isActive ? 'Active' : 'Deactivate'
                    }`}
                >
                    {/* @ts-ignore */}
                    {`${moment(validFrom).format('DD MMM  YYYY')} to ${moment(
                        validTo
                    ).format('DD MMM  YYYY')}`}
                </Badge>
            </Box>
        </Box>
    );
};
