import styled, { css } from "styled-components";
import { defaultTheme } from "utils/theming";

export const CardStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.gray3};
  border-radius: 16px !important;
  transition: all 0.3s ease-in-out;
  ${({ collapsible, collapsed, height }) =>
    collapsible &&
    css`
      max-height: ${collapsed ? "48px" : height + 800 + "px"};
      flex: none;
      overflow: ${collapsed ? "hidden" : "unset"};
      .subheader {
        transition: all 0.6s;
        opacity: ${collapsed ? "0" : "1"};
      }
    `};
  .subheader {
    white-space: pre-wrap;
  }
`;
export const HeaderStyled = styled.div<any>`
  transition: all 0.6s;
  cursor: ${({ collapsible }) => (collapsible ? "pointer" : "default")};
  border-radius: 16px 16px 0 0;
  /* border-radius: ${({ collapsed }) =>
    collapsed ? "16px" : "16px 16px 0 0"}; */
  background-color: ${({ theme }) => theme.palette.gray2};
  padding: ${({ theme }) => `${theme.space[3.5]} ${theme.space[4]}`};
  white-space: nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray3};
  display: flex;

  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
  .mainHeader {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;
export const FooterStyled = styled.div`
  border-radius: 0 0 16px 16px;
  background-color: ${({ theme }) => theme.palette.gray2};
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[4]}`};
  white-space: nowrap;
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
  border-top: 1px solid ${({ theme }) => theme.palette.gray3};
  .mainFooter {
    gap: ${({ theme }) => theme.space[2]};
    display: flex;
  }
`;
export const BodyStyled = styled.div<any>`
  padding: ${({ theme }) => theme.space[4]};
  transition: all 0.2s ease-out;
  opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};
`;

export const HeaderRightSideStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[3]};
`;
export const HeaderBlockStyled = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;
export const FooterLeftSideStyled = styled.div`
  display: flex;
  flex: 1;
  gap: ${({ theme }) => theme.space[2]};
`;

export const GroupedCard = styled.div<any>`
  > :not(:first-of-type):not(:last-child) {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    border-radius: 0 !important;

    border-top: 0 !important;

    > :last-child {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      border-radius: 0 !important;
      border-top: 0 !important;
    }
    > :first-of-type {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      border-radius: 0 !important;
      border-top: 0 !important;
    }
  }
  > :first-of-type {
    border-radius: 16px 16px 0 0 !important;
    margin-bottom: 0 !important;

    > :last-child {
      margin-bottom: 0 !important;
      border-radius: 0 !important;
    }
  }

  > :last-child {
    margin-bottom: 0 !important;
    border-radius: 0 0 16px 16px !important;
    border-top: 0 !important;
    > :first-of-type {
      margin-bottom: 0 !important;
      border-radius: 0 !important;
      border-top: 0 !important;
    }
  }
  ${({ noRadius }) =>
    noRadius &&
    css`
      > :last-child ,> :first-of-type { {
        border-radius: 0 !important;
      }
    `};
`;

CardStyled.defaultProps = defaultTheme;
HeaderStyled.defaultProps = defaultTheme;
FooterStyled.defaultProps = defaultTheme;
BodyStyled.defaultProps = defaultTheme;
HeaderBlockStyled.defaultProps = defaultTheme;
HeaderRightSideStyled.defaultProps = defaultTheme;
FooterLeftSideStyled.defaultProps = defaultTheme;
