import { RouterProvider } from 'react-router-dom';
import './style.css';
import { router } from './router';
import { NotificationsProvider } from '@mantine/notifications';

const App = () => (
    <NotificationsProvider>
        <RouterProvider router={router} />
    </NotificationsProvider>
);
export default App;
