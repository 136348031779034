import { DataGrid, Text, Box, Button, Card, HomeTemplate } from 'components';
import { useNavigate } from 'react-router-dom';
import { mockDataTemp as priceRequests } from 'components/Pages/PriceRequests/RequestsMockData';
import { csvData } from 'services/PriceBandsFile';
import { useState } from 'react';
import csv from 'csvtojson';
import { useCurrentUser } from 'services';
import { useLocalStorage } from 'usehooks-ts';
import { usePriceBands } from 'services';

export const HomePage = ({ ...props }) => {
    const [config, setConfig]: any = useLocalStorage('config-pricing-hub', {
        bookmarks: [],
    });
    const { priceBandsData, priceBandsupdateDate } = usePriceBands();
    const { data: userData } = useCurrentUser();

    const navigate = useNavigate();
    return (
        <HomeTemplate
            priceBands={priceBandsData}
            priceRequests={priceRequests}
            bookmark={config.bookmarks.map(i => ({ name: i.id,...i }))}
        />
    );
};
