import { showNotification, updateNotification } from '@mantine/notifications';
import {
    getCurrentUserMapper,
    getPricingPlansMapper,
    getPricingPlanMapper,
    getRootZoneMapper,
} from './mappers';
import { mockData } from './rootZoneMock';
import { baseUrl, fetcher, useGet } from './servicesHelpers';
import { Icon } from 'components';

export const useGetPricingPlans = type => {
    const typeURL = type.replace('periodic', 'pricing/periodic');
    return useGet(
        `${baseUrl()}/api/pricing-hub/${typeURL}`,
        getPricingPlansMapper[type]
    );
};

const isLocalhosting = () => baseUrl().includes('localhost');
export const useGetPricingPlan = (id: string = '', type: string) =>
    useGet(
        `${baseUrl()}/api/pricing-hub/${type}/${id}`,
        getPricingPlanMapper[type]
    );

export const updateBasePricing = async ({
    id = '',
    type,
    body={},
    method="PUT",
    callback = () => {},
}) => {
    showNotification({
        id: 'Submit-data-1',
        loading: true,
        title: 'Please wait',
        message: 'Data will be submiting',
        autoClose: false,
    });
    const res: any = await fetcher(
        { url: `${baseUrl()}/api/pricing-hub/${type}/${id}` },
        {
            method: method || 'PUT',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        }
    );
    if (res?.status < 300) {
        updateNotification({
            id: 'Submit-data-1',
            loading: false,
            title: 'Success!',
            color: 'teal',
            icon: <Icon icon="FaCheck" />,
            message: 'Your data has been submitted successfully!',
            autoClose: true,
        });
    } else {
        updateNotification({
            id: 'Submit-data-1',
            loading: false,
            title: `Error ${res.status}`,
            icon: <Icon icon="FaTimesCircle" />,
            color: 'red',
            message: `Something appears to have gone wrong!`,
            autoClose: true,
        });
    }
    callback();
    return res;
};

export const updatePricing = async ({
    id = '',
    type,
    body,
    callback = () => {},
}) => {
    showNotification({
        id: 'Submit-data',
        loading: true,
        title: 'Please wait',
        message: 'Data will be submiting',
        autoClose: false,
    });
    const res: any = await fetcher(
        {
            url: `${baseUrl()}/api/pricing-hub/${type}/${id}${
                type == 'pricing' ? '' : '/pricing'
            }`,
        },
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (res?.status < 300) {
        updateNotification({
            id: 'Submit-data',
            loading: false,
            title: 'Success!',
            color: 'teal',
            icon: <Icon icon="FaCheck" />,
            message: 'Your data has been submitted successfully!',
            autoClose: true,
        });
    } else {
        updateNotification({
            id: 'Submit-data',
            loading: false,
            title: `Error ${res.status}`,
            icon: <Icon icon="FaTimesCircle" />,
            color: 'red',
            message: `Something appears to have gone wrong!`,
            autoClose: true,
        });
    }
    callback();
    return res;
};

export const createPricing = (
    id: string = '',
    type: string,
    callback = () => {}
) =>
    fetcher(
        { url: `${baseUrl()}/api/pricing-hub/${type}/${id}` },
        {
            method: 'POST',
        }
    ).then(res => {
        callback();
        if (res?.status == (200 || 201))
            updateNotification({
                id: 'Submit-data',
                loading: false,
                title: 'Success!',
                color: 'teal',
                icon: <Icon icon="FaCheck" />,
                message: 'Your data has been submitted successfully',
                autoClose: true,
            });
        return res;
    });

export const useGetHistoryZone = (zoneId: string = '') =>
    useGet(
        `${baseUrl()}/api/pricing-hub/zones/${zoneId}/pricing-history`,
        getPricingPlanMapper.history
    );

export const useCurrentUser = () =>
    useGet(`${baseUrl()}/api/user/current`, getCurrentUserMapper);
export const useRootZone = () =>
    useGet(
        `${baseUrl()}/api/root-zone`,
        isLocalhosting() ? mockData : getRootZoneMapper
    );
