export const mockDataTemp = [
    {
        id: "febd2340-3bba-4daf-861a-6b51c95f10e3",
        country: "Germany",
        name: "PADERBORN",
        vehicleType: 'escooter',
        request: "1.20 + 0.22",
        pricing: [{ vehicleType: "escooter", internalName: null, runningFee: 0.22, unlockFee: 1.2, pausedFee: 0.22 }],
        createdAt: "2022-08-04T10:20:00.290262Z",
        createdBy: "alexander.kirchner@tier.app",
        timezone: "Europe/Berlin",
        periodics: [{ id: "******-922e-43c4-8ad6-****82783a75", name: 'name' }],
    },
    {
        id: "fe62d22b-922e-43c4-8ad6-bdea82783a75",
        country: "Netherlands",
        name: "EINDHOVEN",
        vehicleType: 'escooter',
        request: "1.20 + 0.22",
        createdAt: "2022-08-04T10:20:00.290262Z",
        createdBy: "alexander.kirchner@tier.app",
        pricing: [{ vehicleType: "escooter", internalName: null, runningFee: 0.22, unlockFee: 1.2, pausedFee: 0.22 }],
        timezone: "Europe/Amsterdam",
        OBAFee: "25.00€",
        holdFee: "3.00€",
        convenienceFee: "",
    },
    {
        id: "fe404921-caef-40ce-9b7c-e6b33fd8a5de",
        country: "France",
        name: "ROUBAIX",
        vehicleType: 'escooter',
        request: "1.20 + 0.22",
        createdAt: "2022-08-04T10:20:00.290262Z",
        createdBy: "alexander.kirchner@tier.app",
        pricing: [{ vehicleType: "escooter", internalName: null, runningFee: 0.22, unlockFee: 1.2, pausedFee: 0.22 }],
        timezone: "Europe/Paris",
        OBAFee: "25.00€",
        holdFee: "7.00€",
        convenienceFee: "",
    },
];

export const mockData = mockDataTemp.map((i: any) => ({
    id: i.id || "******-922e-43c4-8ad6-****82783a75",
    type: "zone",
    name: i.name || 'unknown',
    timezone: i.timezone || "Europe/Amsterdam",
    company: i.company || "Tier mobilityes SE",
    currency: i.currency || "Euro",
    lastEdit: i.lastEdit || "02.13.2023, 10:08 UTC",
    editedBy: i.editedBy || "Mohammad Chorakchi",
    country: i.country || "Netherlands", // if type is not country
    obaFee: i.obaFee || "25", // for country and zone
    holdFee: i.holdFee || "3", // for country and zone
    convenienceFee: i.convenienceFee || "0", // for country and zone
    pricing: [{
        vehicleType: 'escooter',
        unlockPrice: '1.00',
        minutePrice: '0.19',
        pauseFee: "0",
        idleFee: "25",
        active: true, // if neede to show in view I'm not sure
    }, {
        vehicleType: 'ebike',
        unlockPrice: '1.25',
        minutePrice: '0.25',
        pauseFee: "0",
        idleFee: "25",
        active: true, // if neede to show in view I'm not sure
    }],
    abTests: i.abTests,
    periodics: i.periodics,

}));
