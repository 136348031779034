export const mockData = {
  data: [
    {
      "ID": "29300316-984b-4b76-b3f0-ae171e573a55",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "306ca33f-0ddd-4e2d-b52c-9eb282d6061c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TEST",
      "BASE PRICING": "c6d3eb38-8316-4785-aa5f-115cb9024d7c, active, zone:TEST, vehicle_type: E Scooter>reference_type: z> reference_id: f75cba22-abe7-40c0-8fff-d3d79fb0fb2d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.57€",
      "RUNNING_FEE": "2.10€",
      "PAUSED_FEE": "3.00€",
      "IDLE_FEE": "4.00€"
    },
    {
      "ID": "abc3bf9c-2aa2-47c8-8791-097260e380b3",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "306ca33f-0ddd-4e2d-b52c-9eb282d6061c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TEST",
      "BASE PRICING": "c6d3eb38-8316-4785-aa5f-115cb9024d7c, active, zone:TEST, vehicle_type: E Scooter>reference_type: z> reference_id: f75cba22-abe7-40c0-8fff-d3d79fb0fb2d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.57€",
      "RUNNING_FEE": "2.00€",
      "PAUSED_FEE": "3.00€",
      "IDLE_FEE": "4.00€"
    },
    {
      "ID": "40f60ff8-d210-460d-9863-f62261a80192",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "306ca33f-0ddd-4e2d-b52c-9eb282d6061c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TEST",
      "BASE PRICING": "c6d3eb38-8316-4785-aa5f-115cb9024d7c, active, zone:TEST, vehicle_type: E Scooter>reference_type: z> reference_id: f75cba22-abe7-40c0-8fff-d3d79fb0fb2d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.56€",
      "RUNNING_FEE": "2.00€",
      "PAUSED_FEE": "3.00€",
      "IDLE_FEE": "4.00€"
    },
    {
      "ID": "6dddee0c-35e8-4bbe-b589-5df06279f0c0",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "306ca33f-0ddd-4e2d-b52c-9eb282d6061c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TEST",
      "BASE PRICING": "c6d3eb38-8316-4785-aa5f-115cb9024d7c, active, zone:TEST, vehicle_type: E Scooter>reference_type: z> reference_id: f75cba22-abe7-40c0-8fff-d3d79fb0fb2d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.55€",
      "RUNNING_FEE": "2.00€",
      "PAUSED_FEE": "3.00€",
      "IDLE_FEE": "4.00€"
    },
    {
      "ID": "e7c19ac1-10fd-44d8-87ca-c3c7722725fd",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "306ca33f-0ddd-4e2d-b52c-9eb282d6061c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TEST",
      "BASE PRICING": "c6d3eb38-8316-4785-aa5f-115cb9024d7c, active, zone:TEST, vehicle_type: E Scooter>reference_type: z> reference_id: f75cba22-abe7-40c0-8fff-d3d79fb0fb2d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.50€",
      "RUNNING_FEE": "2.00€",
      "PAUSED_FEE": "3.00€",
      "IDLE_FEE": "4.00€"
    },
    {
      "ID": "677e6b8c-20df-42d6-9b0f-4bac56b00619",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "306ca33f-0ddd-4e2d-b52c-9eb282d6061c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TEST",
      "BASE PRICING": "c6d3eb38-8316-4785-aa5f-115cb9024d7c, active, zone:TEST, vehicle_type: E Scooter>reference_type: z> reference_id: f75cba22-abe7-40c0-8fff-d3d79fb0fb2d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "2.00€",
      "PAUSED_FEE": "3.00€",
      "IDLE_FEE": "4.00€"
    },
    {
      "ID": "246e3b33-3c85-4066-8518-6719731a7285",
      "REFERENCE": "zone",
      "REFERENCE _ID": "19d89879-3b8b-48df-8731-77f74e499c3a",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "KIRCHBERG-AM-WAGRAM",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.28€",
      "PAUSED_FEE": "0.28€",
      "IDLE_FEE": "0.28€"
    },
    {
      "ID": "7d9d318e-ee08-4f71-b877-55e00d888194",
      "REFERENCE": "zone",
      "REFERENCE _ID": "19d89879-3b8b-48df-8731-77f74e499c3a",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "KIRCHBERG-AM-WAGRAM",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.29€",
      "PAUSED_FEE": "0.29€",
      "IDLE_FEE": "0.29€"
    },
    {
      "ID": "e5a5336c-7b58-4998-8411-d6534e26c6f5",
      "REFERENCE": "zone",
      "REFERENCE _ID": "19d89879-3b8b-48df-8731-77f74e499c3a",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "KIRCHBERG-AM-WAGRAM",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.28€",
      "PAUSED_FEE": "0.28€",
      "IDLE_FEE": "0.28€"
    },
    {
      "ID": "40b7f8fb-ea33-47e6-96b5-da0207d70e15",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "c5cea1ac-da36-4ec1-9a0f-4e483e5fd9bf",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "SWINOUJSCIE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.40 zł",
      "RUNNING_FEE": "1.60 zł",
      "PAUSED_FEE": "1.60 zł",
      "IDLE_FEE": "1.60 zł"
    },
    {
      "ID": "b4396e27-6e34-4f2a-b584-c36d535a9442",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "c5cea1ac-da36-4ec1-9a0f-4e483e5fd9bf",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "SWINOUJSCIE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.40 zł",
      "RUNNING_FEE": "1.60 zł",
      "PAUSED_FEE": "1.60 zł",
      "IDLE_FEE": "1.60 zł"
    },
    {
      "ID": "bb64d012-3da9-4eed-8523-052026095e6e",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "KOLOBRZEG",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "604474e1-1c2e-4cea-8225-54b5aa7cf45c",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "LEBA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "d8e6e123-c8eb-4dd8-9461-53891607bb9e",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIEDZYWODZIE-DZIWNOW-DZIWNOWEK",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "e6def518-65b0-43c4-ae8b-2c1deef0895c",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIEDZYZDROJE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "af47ea0e-5247-4400-87cb-3df10d9ba713",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIELNO",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "6ea83529-6a5d-45c4-abb6-21ade92f2125",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "USTKA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "b491a8e5-1817-484b-9383-8b2b14236223",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "KOLOBRZEG",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "f68cba03-d977-42d5-ad43-4242f4e716e3",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "LEBA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "16c38754-8da7-47f0-9732-b3b2db4da062",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIEDZYWODZIE-DZIWNOW-DZIWNOWEK",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "8b0dc3d7-33fa-40a1-8748-ff9d5704ce14",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIEDZYZDROJE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "df16e6be-12d4-4172-856d-e385aae14d6d",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIELNO",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "8844bf28-95e7-4a4f-ae1d-74d320ea4859",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "8882c330-44f9-4662-8323-f84942646a16",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "USTKA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "ec4371ae-dd0d-43fd-9f57-182c68a7eed7",
      "REFERENCE": "zone",
      "REFERENCE _ID": "ea525b9a-8ee6-41d8-b87c-a5c8ed0b2356",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "WROCLAW",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "2.50 zł",
      "RUNNING_FEE": "0.59 zł",
      "PAUSED_FEE": "0.59 zł",
      "IDLE_FEE": "0.59 zł"
    },
    {
      "ID": "c84a1d05-e912-45c8-a3e8-8b057dd86233",
      "REFERENCE": "zone",
      "REFERENCE _ID": "606cc174-dc92-4c5a-bdb2-f5a648d956b4",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "MISKOLC",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "Ft300.00",
      "RUNNING_FEE": "Ft59.00",
      "PAUSED_FEE": "Ft59.00",
      "IDLE_FEE": "Ft59.00"
    },
    {
      "ID": "fd03654f-af8f-43af-b591-30302213090f",
      "REFERENCE": "zone",
      "REFERENCE _ID": "606cc174-dc92-4c5a-bdb2-f5a648d956b4",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "MISKOLC",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "Ft300.00",
      "RUNNING_FEE": "Ft59.00",
      "PAUSED_FEE": "Ft59.00",
      "IDLE_FEE": "Ft59.00"
    },
    {
      "ID": "8531f796-30bc-49b6-bc59-a678475765ae",
      "REFERENCE": "zone",
      "REFERENCE _ID": "606cc174-dc92-4c5a-bdb2-f5a648d956b4",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "MISKOLC",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "Ft250.00",
      "RUNNING_FEE": "Ft60.00",
      "PAUSED_FEE": "Ft60.00",
      "IDLE_FEE": "Ft60.00"
    },
    {
      "ID": "5f2a3a72-aec8-4f11-9868-64b40de4c4da",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "9a23863c-20b0-439f-bb28-52a18cdccbe9",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "JOENSUU",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.26€",
      "PAUSED_FEE": "0.26€",
      "IDLE_FEE": "0.26€"
    },
    {
      "ID": "c4f05d4f-ec1d-487c-995a-35356b2bbf9e",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "9a23863c-20b0-439f-bb28-52a18cdccbe9",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "JOENSUU",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.26€",
      "PAUSED_FEE": "0.26€",
      "IDLE_FEE": "0.26€"
    },
    {
      "ID": "61775505-1ef7-4fca-8e0e-d516e9ad91ed",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "0802415b-b12c-491a-83ed-d81329872c3c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "OULU",
      "BASE PRICING": "e3de96af-98f4-441b-9f8f-2fa2dcf641bb, active, zone:OULU, vehicle_type: E Bicycle>reference_type: z> reference_id: 15fad634-e1d1-44c2-ad10-e1bcf37d71aa>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "255b4f50-4e0e-4137-9394-39ea9d1357aa",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "0802415b-b12c-491a-83ed-d81329872c3c",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "OULU",
      "BASE PRICING": "00d48999-3762-4b2f-858f-a51d60234c0e, active, zone:OULU, vehicle_type: E Scooter>reference_type: z> reference_id: 15fad634-e1d1-44c2-ad10-e1bcf37d71aa>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "fa12e577-b4d4-4bf1-9055-5a45494cd547",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "1ee382c6-97bb-40c7-9d7b-0a5fdadbf9a3",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "RAISIO",
      "BASE PRICING": "79836802-a762-462b-9996-ac0e95486b1d, active, zone:RAISIO, vehicle_type: E Bicycle>reference_type: z> reference_id: b2253699-9cf7-4d65-841a-9c898d68a802>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "87dc1578-b3bd-4989-a96e-bf967f6d0bc0",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "1ee382c6-97bb-40c7-9d7b-0a5fdadbf9a3",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "RAISIO",
      "BASE PRICING": "76bb1c92-6534-4bd0-8cc1-77d90749c775, active, zone:RAISIO, vehicle_type: E Scooter>reference_type: z> reference_id: b2253699-9cf7-4d65-841a-9c898d68a802>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "d4b32b4f-b7e1-4441-8a1c-5ceadb99efa1",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "1b5ce816-ab48-42ea-9dcb-af3faa3ed1e6",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TURKU",
      "BASE PRICING": "d06df66c-36ce-4471-97bd-1bae856ce0f5, active, zone:TURKU, vehicle_type: E Bicycle>reference_type: z> reference_id: d43c6f58-005b-40c9-b757-f3f47ec2ff44>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "891181f5-0e80-4bd0-8770-38a15e05917b",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "1b5ce816-ab48-42ea-9dcb-af3faa3ed1e6",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TURKU",
      "BASE PRICING": "986c0687-2969-4c07-9057-24312d6b86fe, active, zone:TURKU, vehicle_type: E Scooter>reference_type: z> reference_id: d43c6f58-005b-40c9-b757-f3f47ec2ff44>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "55876c5d-029b-4323-97ff-70079573b5f9",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a6f0ccde-6b7f-4a00-8e43-dfe18b01e478",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "VANTAA",
      "BASE PRICING": "736a71c2-c3e8-4d7b-a0c6-8855fa232bac, active, zone:VANTAA, vehicle_type: E Bicycle>reference_type: z> reference_id: a14cc1fb-be5a-4728-9a7c-5d0054ff6831>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "ce1247bd-78b6-40a1-bd0f-36020f4f945f",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a6f0ccde-6b7f-4a00-8e43-dfe18b01e478",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "VANTAA",
      "BASE PRICING": "54c39b9b-36db-436d-a024-974dd4414989, active, zone:VANTAA, vehicle_type: E Scooter>reference_type: z> reference_id: a14cc1fb-be5a-4728-9a7c-5d0054ff6831>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "d1b13c7b-b819-4b60-a8ca-590fafda3aa1",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "b07c001b-55f5-4ed7-a5f0-8495f1bca9a7",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "HELSINKI",
      "BASE PRICING": "16bfd729-e066-4e28-9dc7-0893c729250e, active, zone:HELSINKI, vehicle_type: E Bicycle>reference_type: z> reference_id: 9695cda5-5be3-4609-b75a-bb406c65d975>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "bf074edb-f1e8-4ac8-873f-6e392565dbe9",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "b07c001b-55f5-4ed7-a5f0-8495f1bca9a7",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "HELSINKI",
      "BASE PRICING": "d9d6fed4-52c3-4b77-9776-437858228e0b, active, zone:HELSINKI, vehicle_type: E Scooter>reference_type: z> reference_id: 9695cda5-5be3-4609-b75a-bb406c65d975>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "1b6bd97c-6dd1-42fa-9f8f-08947eaaa3b3",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "4e2cfa9a-c6e5-4bf5-ab3e-fa6c8f84b27d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "ESPOO",
      "BASE PRICING": "45934113-68dd-4b17-8fb3-f8e0212d9879, active, zone:ESPOO, vehicle_type: E Bicycle>reference_type: z> reference_id: 1989f60f-0fcf-46c7-8bdc-6bc8dd25c970>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "a1277632-7d35-45e7-826e-ee7475efb42a",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "4e2cfa9a-c6e5-4bf5-ab3e-fa6c8f84b27d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "ESPOO",
      "BASE PRICING": "a2f99313-1d0f-44a5-a542-5a76fea2d240, active, zone:ESPOO, vehicle_type: E Scooter>reference_type: z> reference_id: 1989f60f-0fcf-46c7-8bdc-6bc8dd25c970>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "671b2f0a-2588-43e7-98c7-5085e09c96a4",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "4e2cfa9a-c6e5-4bf5-ab3e-fa6c8f84b27d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "ESPOO",
      "BASE PRICING": "45934113-68dd-4b17-8fb3-f8e0212d9879, active, zone:ESPOO, vehicle_type: E Bicycle>reference_type: z> reference_id: 1989f60f-0fcf-46c7-8bdc-6bc8dd25c970>",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "caff17de-f708-4453-b729-727ffc52424d",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "4e2cfa9a-c6e5-4bf5-ab3e-fa6c8f84b27d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "ESPOO",
      "BASE PRICING": "a2f99313-1d0f-44a5-a542-5a76fea2d240, active, zone:ESPOO, vehicle_type: E Scooter>reference_type: z> reference_id: 1989f60f-0fcf-46c7-8bdc-6bc8dd25c970>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "eb951964-69bd-403d-adff-fb5ac98c2d71",
      "REFERENCE": "zone",
      "REFERENCE _ID": "3be6a392-a281-43e2-8235-575ef14c9256",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PRIEVIDZA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    },
    {
      "ID": "c85e52ed-f3f8-4521-b5cc-1117238f5a24",
      "REFERENCE": "zone",
      "REFERENCE _ID": "3be6a392-a281-43e2-8235-575ef14c9256",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PRIEVIDZA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.16€",
      "PAUSED_FEE": "0.16€",
      "IDLE_FEE": "0.16€"
    },
    {
      "ID": "49683a1f-9d29-4851-bb1e-91acf703001f",
      "REFERENCE": "zone",
      "REFERENCE _ID": "3be6a392-a281-43e2-8235-575ef14c9256",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PRIEVIDZA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.16€",
      "PAUSED_FEE": "0.16€",
      "IDLE_FEE": "0.16€"
    },
    {
      "ID": "db6e85e4-f4d1-468a-bff3-c6c34939c2e7",
      "REFERENCE": "zone",
      "REFERENCE _ID": "3be6a392-a281-43e2-8235-575ef14c9256",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PRIEVIDZA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.16€",
      "PAUSED_FEE": "0.16€",
      "IDLE_FEE": "0.16€"
    },
    {
      "ID": "563afa0d-43ab-4e5e-be08-79433b971a2a",
      "REFERENCE": "zone",
      "REFERENCE _ID": "7485110b-5768-427e-a43e-f8d57ebca775",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RUCIANE-NIDA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "08a322e9-6e4c-4e39-a987-040bd430bd75",
      "REFERENCE": "zone",
      "REFERENCE _ID": "7485110b-5768-427e-a43e-f8d57ebca775",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RUCIANE-NIDA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "3c131e54-2a81-46ce-81b9-a95c3e922a3a",
      "REFERENCE": "zone",
      "REFERENCE _ID": "7485110b-5768-427e-a43e-f8d57ebca775",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RUCIANE-NIDA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "adbd59a7-8dd0-4eae-a5a1-6d6de160c706",
      "REFERENCE": "zone",
      "REFERENCE _ID": "041066a9-5c77-4ba8-be6b-a6997805334e",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RABKA-ZDROJ",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "3.50 zł",
      "RUNNING_FEE": "0.79 zł",
      "PAUSED_FEE": "0.79 zł",
      "IDLE_FEE": "0.79 zł"
    },
    {
      "ID": "c2910d62-cd35-4884-b1cd-c2befebc4ed4",
      "REFERENCE": "zone",
      "REFERENCE _ID": "041066a9-5c77-4ba8-be6b-a6997805334e",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RABKA-ZDROJ",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "51cad6a0-b097-4266-91c2-04f08fa58b35",
      "REFERENCE": "zone",
      "REFERENCE _ID": "041066a9-5c77-4ba8-be6b-a6997805334e",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RABKA-ZDROJ",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "0fcf0597-b024-4dad-a816-7a756a8f3985",
      "REFERENCE": "zone",
      "REFERENCE _ID": "041066a9-5c77-4ba8-be6b-a6997805334e",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "RABKA-ZDROJ",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "0bf8c8b9-6800-412f-9b70-d536a3dcab8a",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "6387202c-dd07-4280-914c-6d4125682d56",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "BADEN-BEI-WIEN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "0.00€",
      "RUNNING_FEE": "0.20€",
      "PAUSED_FEE": "0.20€",
      "IDLE_FEE": "0.20€"
    },
    {
      "ID": "0a4b549b-30be-47cc-bec2-41522116406a",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "6387202c-dd07-4280-914c-6d4125682d56",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "BADEN-BEI-WIEN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "0.00€",
      "RUNNING_FEE": "0.20€",
      "PAUSED_FEE": "0.20€",
      "IDLE_FEE": "0.20€"
    },
    {
      "ID": "2005de70-39d9-4f4e-9256-4598842118f2",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f2fecac2-95a1-4cd4-b333-1c616b2a06d8",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "POBIEROWO-REWAL-NIECHORZE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "3b33d265-c6c0-4796-a4d0-cb18cd12ea3b",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f2fecac2-95a1-4cd4-b333-1c616b2a06d8",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "POBIEROWO-REWAL-NIECHORZE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "b350f8e1-cd18-4df9-894f-fe0fa6c712d5",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f2fecac2-95a1-4cd4-b333-1c616b2a06d8",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "POBIEROWO-REWAL-NIECHORZE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "71113267-c38e-4a98-84fb-1ca4c6826855",
      "REFERENCE": "zone",
      "REFERENCE _ID": "c4d9e1a1-7a4e-458b-b914-f4df6e645338",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "LEBA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "0d8fb171-c4f6-41a2-b94b-0f26c8c34b5d",
      "REFERENCE": "zone",
      "REFERENCE _ID": "c4d9e1a1-7a4e-458b-b914-f4df6e645338",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "LEBA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "a41ad57b-dde9-4f20-9ed1-7295ebd910b7",
      "REFERENCE": "zone",
      "REFERENCE _ID": "c4d9e1a1-7a4e-458b-b914-f4df6e645338",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "LEBA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "3.90 zł",
      "RUNNING_FEE": "0.89 zł",
      "PAUSED_FEE": "0.89 zł",
      "IDLE_FEE": "0.89 zł"
    },
    {
      "ID": "78e6ae1e-0986-4e30-a178-6c007bdd8d15",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a76d1e67-ca98-443f-bd34-0be042b3b06d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "KOLOBRZEG",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "955a9aa4-6b16-4a6a-b594-429fef685a31",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a76d1e67-ca98-443f-bd34-0be042b3b06d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIEDZYZDROJE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "ee1fc16b-a6b0-459f-9d7d-3b8f5270c01f",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a76d1e67-ca98-443f-bd34-0be042b3b06d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "SWINOUJSCIE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "2aa83c3a-f61e-4e06-bd60-d3402191aab3",
      "REFERENCE": "zone",
      "REFERENCE _ID": "edf48a89-035b-4211-9fea-a70d61fdfa44",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "MILTON-KEYNES",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "GB£1.00",
      "RUNNING_FEE": "GB£0.21",
      "PAUSED_FEE": "GB£0.21",
      "IDLE_FEE": "GB£0.21"
    },
    {
      "ID": "3e29adc0-ff48-4e6c-b0ed-090372533b2e",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "d711dda9-6f1e-4236-ac13-50bcc725bc21",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "BRUSSEL",
      "BASE PRICING": "a358b642-0c1d-4907-ac56-4221144494e4, active, zone:BRUSSEL, vehicle_type: E Scooter>reference_type: z> reference_id: d56b3e9b-f142-4233-b120-3310387d3b9d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.19€",
      "PAUSED_FEE": "0.19€",
      "IDLE_FEE": "0.19€"
    },
    {
      "ID": "e17981f8-306f-4d0a-9e03-89f6af4dda6d",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "d711dda9-6f1e-4236-ac13-50bcc725bc21",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "BRUSSELS",
      "BASE PRICING": "aa82914b-22c5-4cc1-88d8-3aeb50b5bc15, active, zone:BRUSSELS, vehicle_type: E Scooter>reference_type: z> reference_id: c49a06d4-ceeb-475a-85f6-ce9fc0918c0d>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.19€",
      "PAUSED_FEE": "0.19€",
      "IDLE_FEE": "0.19€"
    },
    {
      "ID": "0326e567-495e-43e2-ab21-af79b4c89646",
      "REFERENCE": "zone",
      "REFERENCE _ID": "5d5ce6ca-3cbc-40b1-adbf-3786e58ec362",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PORTSCHACH-VELDEN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.20€",
      "RUNNING_FEE": "0.35€",
      "PAUSED_FEE": "0.35€",
      "IDLE_FEE": "0.35€"
    },
    {
      "ID": "80b65fb0-ec96-4c72-8fc9-4a25ad3d643b",
      "REFERENCE": "zone",
      "REFERENCE _ID": "66ac9271-761c-446c-bf74-8c035f1081d5",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "MYJAVA",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.20€",
      "PAUSED_FEE": "0.20€",
      "IDLE_FEE": "0.20€"
    },
    {
      "ID": "45dfb770-593d-48d9-aaeb-8a5e42ab430f",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "12.00 Skr",
      "RUNNING_FEE": "2.80 Skr",
      "PAUSED_FEE": "2.80 Skr",
      "IDLE_FEE": "2.80 Skr"
    },
    {
      "ID": "ce89b754-c7b8-472a-b269-b50a071519d5",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "12.00 Skr",
      "RUNNING_FEE": "2.80 Skr",
      "PAUSED_FEE": "2.80 Skr",
      "IDLE_FEE": "2.80 Skr"
    },
    {
      "ID": "706b02c8-9549-4d21-ad1b-c85a23acff7c",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "34e861a3-68b9-4e68-b928-e691e9d46ae3",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TAMPERE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.26€",
      "PAUSED_FEE": "0.26€",
      "IDLE_FEE": "0.26€"
    },
    {
      "ID": "773f98f0-9271-471a-b8bd-47ced1dae0cf",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "34e861a3-68b9-4e68-b928-e691e9d46ae3",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TAMPERE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.26€",
      "PAUSED_FEE": "0.26€",
      "IDLE_FEE": "0.26€"
    },
    {
      "ID": "91e01557-d5c7-431a-9809-0bfcb291284d",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a76d1e67-ca98-443f-bd34-0be042b3b06d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "KOLOBRZEG",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "b8da76e2-e52f-4ce2-b3f0-987c2fdaa326",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a76d1e67-ca98-443f-bd34-0be042b3b06d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "MIEDZYZDROJE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "94100876-996b-4805-8c92-7a2fe6981188",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "a76d1e67-ca98-443f-bd34-0be042b3b06d",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "SWINOUJSCIE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "5.00 zł",
      "RUNNING_FEE": "1.10 zł",
      "PAUSED_FEE": "1.10 zł",
      "IDLE_FEE": "1.10 zł"
    },
    {
      "ID": "d909e351-f845-47eb-a83d-9a1dc9b0fb86",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "4d4e7369-3b82-4a13-b12b-4ce076259d1a",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "OSWIECIM",
      "BASE PRICING": "60951cf7-191d-402a-8c34-1d63baac47a4, active, zone:OSWIECIM, vehicle_type: E Scooter>reference_type: z> reference_id: 1fa8ce32-735e-4353-94ff-f648752018d6>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "3.00 zł",
      "RUNNING_FEE": "0.35 zł",
      "PAUSED_FEE": "0.35 zł",
      "IDLE_FEE": "0.35 zł"
    },
    {
      "ID": "d16fec14-4c0d-4958-9924-2a3c1395cf10",
      "REFERENCE": "zone",
      "REFERENCE _ID": "114c158b-6df1-441c-a671-98470cd6fb43",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "GODOLLO",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "Ft300.00",
      "RUNNING_FEE": "Ft70.00",
      "PAUSED_FEE": "Ft70.00",
      "IDLE_FEE": "Ft70.00"
    },
    {
      "ID": "1a2302f8-bab6-4e65-bff8-c0b2d64b9a9a",
      "REFERENCE": "zone",
      "REFERENCE _ID": "1bc5eabc-8e99-48b3-9a98-bd3c6c40c074",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "GYOR",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "Ft350.00",
      "RUNNING_FEE": "Ft90.00",
      "PAUSED_FEE": "Ft90.00",
      "IDLE_FEE": "Ft90.00"
    },
    {
      "ID": "4a5ffd65-abc8-442a-9d6b-832397e52130",
      "REFERENCE": "zone",
      "REFERENCE _ID": "1bc5eabc-8e99-48b3-9a98-bd3c6c40c074",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "GYOR",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "Ft350.00",
      "RUNNING_FEE": "Ft90.00",
      "PAUSED_FEE": "Ft90.00",
      "IDLE_FEE": "Ft90.00"
    },
    {
      "ID": "5ae54105-8196-4a8d-9d01-902c772eb510",
      "REFERENCE": "zone",
      "REFERENCE _ID": "09fd2cb0-5c16-4dd2-9c9c-091de7fd257a",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "SIOFOK",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "Ft450.00",
      "RUNNING_FEE": "Ft100.00",
      "PAUSED_FEE": "Ft100.00",
      "IDLE_FEE": "Ft100.00"
    },
    {
      "ID": "9fcca7d8-5494-4d35-b272-0ebf29d80fa1",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "f3adc0db-f6ae-464f-84b9-a4f975b97f5a",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "b3fb92ad-3b37-477d-a5bc-5029f806cf5c, not-active, zone:TROLLHATTAN, vehicle_type: E Scooter>reference_type: z> reference_id: f1ee3b25-a636-4729-90fa-069f31fc6c20>",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "0.00 Skr",
      "RUNNING_FEE": "2.50 Skr",
      "PAUSED_FEE": "2.50 Skr",
      "IDLE_FEE": "2.50 Skr"
    },
    {
      "ID": "844730da-0837-4a0f-b3de-1ec70eccc092",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "10.00 Skr",
      "RUNNING_FEE": "2.80 Skr",
      "PAUSED_FEE": "2.80 Skr",
      "IDLE_FEE": "2.80 Skr"
    },
    {
      "ID": "b3fb92ad-3b37-477d-a5bc-5029f806cf5c",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "10.00 Skr",
      "RUNNING_FEE": "2.80 Skr",
      "PAUSED_FEE": "2.80 Skr",
      "IDLE_FEE": "2.80 Skr"
    },
    {
      "ID": "cc8f6b81-5701-4405-8219-ac73730c2f6d",
      "REFERENCE": "periodic",
      "REFERENCE _ID": "f3adc0db-f6ae-464f-84b9-a4f975b97f5a",
      "REFERENCE_TYPE": "periodic",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "0.00 Skr",
      "RUNNING_FEE": "2.50 Skr",
      "PAUSED_FEE": "2.50 Skr",
      "IDLE_FEE": "2.50 Skr"
    },
    {
      "ID": "edb2fd29-2990-4a5c-90b6-800033e98a4b",
      "REFERENCE": "zone",
      "REFERENCE _ID": "e62bd0fd-39a9-4ce5-b31b-f9b2b932599c",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "KRAPKOWICE-GOGOLIN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "3.50 zł",
      "RUNNING_FEE": "0.79 zł",
      "PAUSED_FEE": "0.79 zł",
      "IDLE_FEE": "0.79 zł"
    },
    {
      "ID": "d6c01688-1aad-4229-896b-5923a01e5055",
      "REFERENCE": "zone",
      "REFERENCE _ID": "60fb72b9-4601-482a-a177-ce95fee7accf",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "SZCZECINEK",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "12656d9e-6795-45f0-afe8-cf43e840933e",
      "REFERENCE": "zone",
      "REFERENCE _ID": "60fb72b9-4601-482a-a177-ce95fee7accf",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "SZCZECINEK",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "2.80 zł",
      "RUNNING_FEE": "0.69 zł",
      "PAUSED_FEE": "0.69 zł",
      "IDLE_FEE": "0.69 zł"
    },
    {
      "ID": "3dd17f65-612b-4eed-967a-c6d903c419c3",
      "REFERENCE": "zone",
      "REFERENCE _ID": "60fb72b9-4601-482a-a177-ce95fee7accf",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "SZCZECINEK",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "3.50 zł",
      "RUNNING_FEE": "0.79 zł",
      "PAUSED_FEE": "0.79 zł",
      "IDLE_FEE": "0.79 zł"
    },
    {
      "ID": "56fa5843-2fd3-4255-9fe8-5777c37ff683",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "0.00 Skr",
      "RUNNING_FEE": "2.50 Skr",
      "PAUSED_FEE": "2.50 Skr",
      "IDLE_FEE": "2.50 Skr"
    },
    {
      "ID": "757e204b-64eb-4f3c-924b-0a104aee9c49",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Moped",
      "UNLOCK_FEE": "12.00 Skr",
      "RUNNING_FEE": "2.80 Skr",
      "PAUSED_FEE": "2.80 Skr",
      "IDLE_FEE": "2.80 Skr"
    },
    {
      "ID": "30123d0c-c5a4-4e85-bb02-b335725819be",
      "REFERENCE": "zone",
      "REFERENCE _ID": "f1ee3b25-a636-4729-90fa-069f31fc6c20",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "TROLLHATTAN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "0.00 Skr",
      "RUNNING_FEE": "2.50 Skr",
      "PAUSED_FEE": "2.50 Skr",
      "IDLE_FEE": "2.50 Skr"
    },
    {
      "ID": "3ef86e7d-d28b-401c-b829-f38333601d13",
      "REFERENCE": "zone",
      "REFERENCE _ID": "760a8991-4565-47b0-a126-555457beee6c",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "BREMERHAVEN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.20€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    },
    {
      "ID": "d43a9e82-9d12-425e-8b11-305ca256ea47",
      "REFERENCE": "zone",
      "REFERENCE _ID": "760a8991-4565-47b0-a126-555457beee6c",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "BREMERHAVEN",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.20€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    },
    {
      "ID": "189d449f-5adb-4382-86b3-1f8bfd5845de",
      "REFERENCE": "zone",
      "REFERENCE _ID": "cc734b6e-0847-4969-bf1b-796936225c26",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PARIS",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    },
    {
      "ID": "a2cb6cab-ad53-49b9-99f1-e460c1b65f7e",
      "REFERENCE": "zone",
      "REFERENCE _ID": "bbfcbf50-0d16-4cd0-8c06-80f4e4da3955",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "BAUNATAL",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Bicycle",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.23€",
      "PAUSED_FEE": "0.23€",
      "IDLE_FEE": "0.23€"
    },
    {
      "ID": "d8835c26-4a7d-4406-b97f-b6196dabb561",
      "REFERENCE": "zone",
      "REFERENCE _ID": "bbfcbf50-0d16-4cd0-8c06-80f4e4da3955",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "BAUNATAL",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.00€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    },
    {
      "ID": "00eea7de-451b-4ab4-a5b8-c31b58539ebf",
      "REFERENCE": "zone",
      "REFERENCE _ID": "31c13adf-01e3-46bf-8f54-5d866866bd8f",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "HERNE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.20€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    },
    {
      "ID": "a04e2a5e-9a7a-46a1-9e89-1e7a23e3463e",
      "REFERENCE": "zone",
      "REFERENCE _ID": "5ce61043-a05c-4265-8a2e-ce85d3268e4a",
      "REFERENCE_TYPE": "zone",
      "ACTIVE": "",
      "ZONE": "PEINE",
      "BASE PRICING": "-",
      "VEHICLE_TYPE": "E Scooter",
      "UNLOCK_FEE": "1.20€",
      "RUNNING_FEE": "0.22€",
      "PAUSED_FEE": "0.22€",
      "IDLE_FEE": "0.22€"
    }
  ]
}