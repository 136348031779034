export const isDateOlderThanSixWeeks = (inputDate0) => {



    const inputDate :any = new Date(inputDate0);
    // Calculate the current date
    const currentDate :any = new Date();
    
    // Calculate the difference in milliseconds between the input date and the current date
    const timeDifference = currentDate - inputDate;
    
    // Calculate the number of milliseconds in 6 weeks (6 weeks * 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const sixWeeksInMilliseconds = 6 * 7 * 24 * 60 * 60 * 1000;
    
    // Check if the time difference is greater than or equal to 6 weeks
    return timeDifference >= sixWeeksInMilliseconds;
  }
  
