import { ZoneEdit, Icon } from 'components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useCurrentUser,
    useGetHistoryZone,
    useGetPricingPlan,
    updatePricing,
    updateBasePricing,
    usePriceBands,
} from 'services';
import { submitMapper } from './helper';

export const ZonesDiteals = ({ ...props }) => {
    const { id } = useParams();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const type = 'zones';
    const {
        data: zoneData,
        isLoading: loadZone,
        mutate: revalidZoneData,
    } = useGetPricingPlan(id, type);
    const { data: experiment, isLoading: loadExp } = useGetPricingPlan(
        id,
        'experiment'
    );
    const { data: countryLevel, isLoading: loadCountry }: any =
        useGetPricingPlan(zoneData?.countryId, 'countries');
    const { data: history, isLoading: LoadHistory }: any =
        useGetHistoryZone(id);

    const { data: user } = useCurrentUser();

    const { priceBandsData, priceBandsupdateDate } = usePriceBands();

    const ZonesDitealsHandler = async (data, handleClose) => {
        setIsLoadingSubmit(true);
        const dataTemp = submitMapper(data);
        await updateBasePricing({
            id: id,
            type,
            body: dataTemp.zoneData,
        });
        await updatePricing({
            id: id,
            type,
            body: { data: dataTemp.pricing },
        });

        revalidZoneData();
        setIsLoadingSubmit(false);
    };

    return (
        <ZoneEdit
            roles={user?.roles}
            priceBandsData={priceBandsData}
            {...props}
            data={{
                ...zoneData,
                experiment,
                countryLevel,
                history,
            }}
            onChange={ZonesDitealsHandler}
            isLoading={
                loadZone ||
                loadExp ||
                loadCountry ||
                LoadHistory ||
                isLoadingSubmit
            }
        />
    );
};
