import { BoxHOC } from 'components/Atoms/Box/Box';
import { Badge as BadgeTemp } from './Badge';
import { Input as InputTemp } from './Input';
import { Text as TextTemp } from './Text';
import { Select as SelectTemp } from './Select';
import { Button as ButtonTemp } from './Button';
import { Divider as DividerTemp } from './Divider';
import { ToggleSwitch as ToggleSwitchTemp } from './ToggleSwitch';
import { Icon as IconTemp } from './Icon';
import { Grid as GridTemp } from './Grid';
import { LoadingSpinner as LoadingSpinnerTemp } from './LoadingSpinner';

import { BoxProps } from './Box/Box.interface';

export { Box } from './Box';
export const Grid = BoxHOC(GridTemp);
export const Icon = BoxHOC(IconTemp);
export const Badge = BoxHOC(BadgeTemp);
export const Input = BoxHOC(InputTemp);
export const Text = BoxHOC(TextTemp);
export const Button = BoxHOC(ButtonTemp);
export const Select = BoxHOC(SelectTemp);
export const Divider = BoxHOC(DividerTemp);
export const LoadingSpinner = BoxHOC(LoadingSpinnerTemp);
export const ToggleSwitch = BoxHOC(ToggleSwitchTemp);
