import { Field } from "formik";
import { useNavigate } from "react-router-dom";
import { Input, Modal, Button, Box, Divider, Select, Text } from 'components'

const TextInput = ({ ...props }: any) => <Field {...props} as={Input} />;

export const BookmarckAdd = () => {

  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Modal w={'850px'} isOpen={true} header="Price Change Request"
        onClickClose={handleClose}
        footer={<Button variant="outlined" onClick={handleClose}>Cancel</Button>}
      >
        <Text variant="caption" mb={16} mr={20}>
          The feature works by storing the pricing as a list of IDs in local storage,fetch and display the current values right on here.<br />
          You can add or remove items from the list as you please.<br />
          if you switch to a different browser, use another computer, or perform some browser data cleanup for this site, Your list will always remove.
        </Text>
        <Box d="flex" gap={4} xs={{ flexWrap: 'wrap' }} >
          <Select w={80}
            helperText="Country select effect on Zone options and currency"
            label="Country"
            name="Country"
            options={[
              { label: 'Germany', value: 'DE' },
              { label: 'France', value: 'FR' },
              { label: 'United Kingdom', value: 'UK' },
              { label: 'United States', value: 'US' },
            ]}
          />
          <Select w={42}
            label="zone"
            name="zone"
            options={[
              { label: 'Berlin', value: 'berlin' },
              { label: 'Hamburg', value: 'hamburg' },
              { label: 'Test', value: 'Test' },
            ]}
          />
        </Box>
        <TextInput w={300} label="Dircsription" name="dircsription" helperText="Insert a Reason for change request" />
      </Modal>
    </>
  );
};
