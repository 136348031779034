export const submitMapper = (data: any) => {
    let pricing: any = [];

    if (
        data.minutePriceEBike ||
        data.unlockPriceEBike ||
        data.pausePriceEBike ||
        data.idlePriceEBike
    )
        pricing.push({
            vehicleType: 'ebicycle',
            runningFee: data.minutePriceEBike || 0,
            unlockFee: data.unlockPriceEBike || 0,
            pausedFee: data.pausePriceEBike || 0,
            idleFee: data.idlePriceEBike || 0,
        });
    if (
        data.minutePriceEScooter ||
        data.unlockPriceEScooter ||
        data.pausePriceEScooter ||
        data.idlePriceEScooter
    )
        pricing.push({
            vehicleType: 'escooter',
            runningFee: data.minutePriceEScooter || 0,
            unlockFee: data.unlockPriceEScooter || 0,
            pausedFee: data.pausePriceEScooter || 0,
            idleFee: data.idlePriceEScooter || 0,
        });
    return {
        zoneData: {
            hold_fee: data.holdFee,
            oba_fee: data.obaFee,
            convenience_fee: data.convenienceFee,
        },
        pricing,
    };
};
