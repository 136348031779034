import { Icon, PeriodicEditOrAdd } from 'components';
import { useParams } from 'react-router-dom';
import {
    updateBasePricing,
    updatePricing,
    useCurrentUser,
    useGetPricingPlan,
    usePriceBands,
    useRootZone,
} from 'services';
import { submitMapper } from './helper';
import { useState } from 'react';

const type = 'periodic';

export const PeriodicDiteals = ({ ...props }) => {
    const { id } = useParams();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { data: zoneData } = useRootZone();
    const { priceBandsData, priceBandsupdateDate } = usePriceBands();
    const {
        data: data,
        isLoading: dataLoading,
        mutate: revalidateData,
    } = useGetPricingPlan(id + '/periodic-pricing', type);
    const { data: userData } = useCurrentUser();
    const SubmitHandler = async (data, handleClose) => {
        setIsLoadingSubmit(true);
        
        const body = submitMapper(data);
        await updatePricing({
            id: type,
            type: 'pricing',
            body,
        });
        revalidateData();
        setIsLoadingSubmit(false);
    };

    const DeleteHandler = async handleClose => {
        setIsLoadingSubmit(true);
    
        await updateBasePricing({
            id: id+'/periodic-pricing',
            type: type,
            method: 'DELETE',
        });
        revalidateData();
        setIsLoadingSubmit(false);
        handleClose();
    };

    return (
        <PeriodicEditOrAdd
            {...props}
            zonesOptions={zoneData}
            roles={userData?.roles}
            onClickDelete={DeleteHandler}
            data={data}
            isLoading={dataLoading || isLoadingSubmit}
            edit={true}
            priceBandsData={priceBandsData}
            onChange={SubmitHandler}
        />
    );
};
