const currentDate = new Date();

export const getPricingPlansMapper = {
    experiment: (res, type = 'experiment') =>
        res.data.map(i => {
            let eScooter = i.pricing.escooter.map(i => ({
                ...i,
                vehicleType: 'escooter',
            }));
            let ebicycle = i.pricing.ebicycle.map(i => ({
                ...i,
                vehicleType: 'ebicycle',
            }));
            return {
                type: type,
                id: i.zoneId,
                name: i.zoneId,
                zoneId: i.zoneId,
                pricing: [...eScooter, ...ebicycle],
                isActive: !i.onlyWhitelist,
                experimentName: i.experimentName,
                onlyWhitelist: i.onlyWhitelist,
            };
        }),
    countries: (res, type = 'countries') =>
        res.data.map(i => ({
            ...i,
            type: type,
            id: i.id,
            name: i.internalName,
        })),
    zones: (res, type = 'zones') =>
        res.data.map(i => ({
            ...i,
            type: type,
            id: i.zoneId,
            country: i.countryName,
            name: i.zoneId,
        })),
    periodic: (res, type = 'periodic') =>
        res.data.map(i => {
            const validTo = new Date(i.validTo);
            const isActive =
                validTo > currentDate ||
                (i.validFrom == null && i.validTo == null);
            const tempData = {
                ...i,
                type: type,
                isActive,
                country: i.countryName,
                name: i.pricingName || 'Unknow',
            };
            delete tempData.pricingName;
            delete tempData.referenceType;
            return tempData;
        }),
};
