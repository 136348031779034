import { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
    Input,
    Text,
    Modal,
    Button,
    Box,
    Divider,
    ToggleSwitch,
    Autocomplete,
    Select,
} from 'components';
import RRuleGenerator from 'react-rrule-generator';
import { hasRole } from 'utils';
import moment from 'moment';

interface FormValues {
    OBAFee?: number;
    holdFee?: number;
    unlockPriceEScooter?: number;
    minutePriceEScooter?: number;
    pausePriceEScooter?: number;
    idlePriceEScooter?: number;
    unlockPriceEBike?: number;
    minutePriceEBike?: number;
    pausePriceEBike?: number;
    idlePriceEBike?: number;
    recurrence?: Array<string>;
    priceCommsEnabled?: boolean;
    id?: string;
    pricingName?: string;
    targetZones?: Array<string>;
}

// const initialValues: FormValues = {
//     OBAFee: 0,
//     holdFee: 0,
//     unlockPriceEScooter: 0,
//     minutePriceEScooter: 0,
//     pausePriceEScooter: 0,
//     idlePriceEScooter: 0,
//     unlockPriceEBike: 0,
//     minutePriceEBike: 0,
//     pausePriceEBike: 0,
//     idlePriceEBike: 0,
// };

const validationSchema = yup.object().shape({
    OBAFee: yup.number().required(),
    holdFee: yup.number().required(),
    unlockPriceEScooter: yup.number().required(),
    minutePriceEScooter: yup.number().required(),
    pausePriceEScooter: yup.number().required(),
    idlePriceEScooter: yup.number().required(),
    unlockPriceEBike: yup.number().required(),
    minutePriceEBike: yup.number().required(),
    pausePriceEBike: yup.number().required(),
    idlePriceEBike: yup.number().required(),
});

const TextInput = ({ ...props }: any) => (
    <Field {...props} bgColor={props.disabled && 'white'} as={Input} />
);

export const PeriodicEditOrAdd = ({
    data = {},
    isLoading = false,
    roles = [],
    onClickDelete = () => {},
    edit = true,
    onChange,
    zonesOptions = [],
    priceBandsData = [],
    currency = '',
}: any) => {
    const [values, setValues] = useState<FormValues>({
        ...data.values,
        recurrence: data?.values?.recurrence != 'None' ? data?.values?.recurrence?.split('\n') : '',
    });
    const editable = edit ? hasRole(roles, ['admin', 'pricing-manager']) : true;
    const navigate = useNavigate();
    const [rules, setRules] = useState<any>([]);
    const [country, setCountry] = useState('');
    const [openPopupDelete, setOpenPopupDelete] = useState(false);

    useEffect(() => {
        setValues({
            ...data?.values,
            recurrence: data?.values?.recurrence != "None" ? data?.values?.recurrence?.split('\n') : '',
        });
    }, [data.values]);
    useEffect(() => {
        const temp = zonesOptions.filter(
            i => i.value === (values?.targetZones ? values?.targetZones[0] : '')
        );

        setCountry(temp[0]?.contry);
    }, [zonesOptions, values]);

    const handleSubmit = (dataValues: FormValues) => {
        onChange({ ...dataValues });
    };
    const handleDeleteRule = index => {
        setRules(rules.filter((i, ind) => index != ind));
    };
    const handleClose = () => {
        navigate(-1);
    };

    return (
        <Formik
            enableReinitialize={true}
            // validationSchema={validationSchema}
            initialValues={values}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <Modal
                        isLoading={isLoading}
                        w={'70%'}
                        isOpen={true}
                        id="mainPopupPeriodic"
                        header={'Periodic ' + (edit ? 'Edit' : 'Add')}
                        onClickClose={handleClose}
                        FooterLeftSide={
                            <Button
                                color="primaryOrange"
                                onClick={() => {
                                    setOpenPopupDelete(true);
                                }}
                                hidden={!editable || !edit}
                            >
                                Delete
                            </Button>
                        }
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    {editable ? 'Cancel' : 'Close'}
                                </Button>
                                <Button hidden={!editable} type="submit">
                                    Save
                                </Button>
                            </>
                        }
                    >
                        {zonesOptions && (
                            <Autocomplete
                                disabled={!editable}
                                isMulti
                                label="Target Zones"
                                options={
                                    country
                                        ? zonesOptions.filter(
                                              i => i.contry == country
                                          )
                                        : zonesOptions
                                }
                                value={values.targetZones?.map(i => ({
                                    value: i,
                                    label: i,
                                }))}
                                onChange={data => {
                                    let temp = {};
                                    if (data.map(i => i.value).length > 1)
                                        temp = {
                                            priceCommsEnabled: false,
                                        };
                                    setValues({
                                        ...values,
                                        ...temp,
                                        targetZones: data.map(i => i.value),
                                    });
                                }}
                                mt={2}
                                w="50%"
                            />
                        )}
                        <TextInput
                            disabled={!editable || edit}
                            label="Pricing name"
                            onChange={(e)=>{
                                setValues({...values, pricingName: e.target.value})
                            }}
                            mt={2}
                            w="70%"
                            name="pricingName"
                        />
                        <Divider spaceX="-16px" mt={8} />
                        <Text variant="caption" mb={2} mr={10} bold>
                            To do the changes in consumer apps and show to offer
                            discount periodically, toggle it.
                        </Text>
                        <Text variant="caption" mb={2} mr={10}>
                            This feature can be activated when only one zone is
                            selected as the target zone.
                        </Text>
                        <ToggleSwitch
                            disabled={
                                !editable ||
                                (values.targetZones &&
                                    values.targetZones?.length > 1)
                            }
                            checked={values?.priceCommsEnabled}
                            onChange={e => {
                                setValues({
                                    ...values,
                                    priceCommsEnabled: e.target.checked,
                                });
                            }}
                            mb={2}
                        >
                            As Discount
                        </ToggleSwitch>
                        <Divider
                            header="Periodic pricing"
                            spaceX="-16px"
                            mt={8}
                        />
                        <Text variant="caption" spaceY={4}>
                            For periodic pricing, you must select at least start
                            and end dates or specify a recurrence rule.
                        </Text>
                        <Box d="flex" gap={4}>
                            <TextInput
                                type="datetime-local"
                                disabled={!editable}
                                w={80}
                                name="validFrom"
                                label="Valid from"
                                onChange={(e)=>{
                                    setValues({...values, [e.target.name]: e.target.value})
                                }}
                            />
                            <TextInput
                                type="datetime-local"
                                disabled={!editable}
                                w={80}
                                name="validTo"
                                label="Valid to"
                                onChange={(e)=>{
                                    setValues({...values, [e.target.name]: e.target.value})
                                }}
                            />
                        </Box>
                        <Divider spaceX="-16px" mt={8} />
                        <Box d="flex" gap={2} xs={{ direction: 'rtl' }}>
                            <Button
                                size="small"
                                hidden={!editable}
                                onClick={() => {
                                    let temp = values?.recurrence || [];
                                    temp.push('');
                                    setValues({
                                        ...values,
                                        recurrence: temp,
                                    });
                                }}
                            >
                                Add Rule
                            </Button>
                            <Button
                                size="small"
                                hidden={!editable}
                                onClick={() => {
                                    let temp = values?.recurrence || [];
                                    temp.push(
                                        `EXDATE:${moment(Date()).format(
                                            'YYYYMMDDTHHmmss[Z]'
                                        )}`
                                    );
                                    setValues({
                                        ...values,
                                        recurrence: temp,
                                    });
                                }}
                            >
                                Add Exp.date
                            </Button>
                            <Button
                                size="small"
                                hidden={!editable}
                                onClick={() => {
                                    let temp = values?.recurrence || [];
                                    temp.push(
                                        `RDATE:${moment(Date()).format(
                                            'YYYYMMDDTHHmmss[Z]'
                                        )}`
                                    );
                                    setValues({
                                        ...values,
                                        recurrence: temp,
                                    });
                                }}
                            >
                                Add date
                            </Button>
                        </Box>
                        <Box spaceY={2} xs={{ overflowWrap: 'normal' }}>
                            {values?.recurrence &&
                                values?.recurrence?.map((item, i) => (
                                    <>
                                        <Button
                                            mt={2}
                                            // mb={'25px'}
                                            h={6}
                                            hidden={!editable}
                                            w={6}
                                            rounded
                                            size="small"
                                            // hidden={i == 0}
                                            variant="outlined"
                                            onClick={() => {
                                                let temp =
                                                    values?.recurrence || [];
                                                temp.splice(i, 1);
                                                setValues({
                                                    ...values,
                                                    recurrence: temp || '',
                                                });
                                            }}
                                            sx={{ border: 'none' }}
                                            type="button"
                                        >
                                            ✕
                                        </Button>
                                        {item.includes('EXDATE') ||
                                        item.includes('RDATE') ? (
                                            <Input
                                                type="date"
                                                disabled={!editable}
                                                w={80}
                                                label={item.split(':')[0]}
                                                value={moment(
                                                    item.split(':')[1]
                                                ).format('YYYY-MM-DD')}
                                                onChange={e => {
                                                    const val =
                                                        item.split(':')[0] +
                                                        ':' +
                                                        moment(
                                                            e.target.value
                                                        ).format(
                                                            'YYYYMMDDTHHmmss[Z]'
                                                        );

                                                    let temp =
                                                        values?.recurrence ||
                                                        [];
                                                    temp[i] = val;
                                                    setValues({
                                                        ...values,
                                                        recurrence: temp,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <RRuleGenerator
                                                disabled={!editable}
                                                key={'recurrence' + i}
                                                config={{
                                                    repeat: ['Daily', 'Weekly'],
                                                    end: [],
                                                    hideError: true,
                                                    hideEnd: true,
                                                    hideStart: true,
                                                }}
                                                value={item || null}
                                                style={{
                                                    margin: '10px 0 10px 0',
                                                }}
                                                onChange={rrule => {
                                                    let temp =
                                                        values?.recurrence ||
                                                        [];
                                                    temp[i] = rrule;
                                                    setValues({
                                                        ...values,
                                                        recurrence: temp,
                                                    });
                                                }}
                                            />
                                        )}
                                    </>
                                ))}
                        </Box>

                        <Divider
                            header="Pricing EScooter"
                            spaceX="-16px"
                            mt={8}
                        />
                        <Box d="flex" gap={4} mt={2} xs={{ flexWrap: 'wrap' }}>
                            <Select
                                disabled={!editable}
                                options={[
                                    {
                                        label: `not selected band`,
                                        value: null,
                                    },
                                    ...priceBandsData
                                        .filter(
                                            i =>
                                                i.country?.toUpperCase() ==
                                                country?.toUpperCase()
                                        )
                                        .map(i => ({
                                            label: `${i.country} ${i.name} (${i.UnlockFee} + ${i.MinuteFee}, Idle: ${i.IdleFee}) `,
                                            value: i.id,
                                        })),
                                ]}
                                onChange={e => {
                                    let temp = priceBandsData.filter(
                                        i => i.id == e.target.value
                                    )[0];

                                    setValues({
                                        ...values,
                                        unlockPriceEScooter: temp.UnlockFee,
                                        minutePriceEScooter: temp.MinuteFee,
                                        idlePriceEScooter: temp.IdleFee,
                                    });
                                }}
                                value={
                                    priceBandsData.length &&
                                    priceBandsData.filter(
                                        (i: any) =>
                                            i.country?.toUpperCase() ==
                                                country?.toUpperCase() &&
                                            parseFloat(
                                                values.idlePriceEScooter + ''
                                            ) == parseFloat(i?.IdleFee) &&
                                            parseFloat(
                                                values.minutePriceEScooter + ''
                                            ) == parseFloat(i?.MinuteFee) &&
                                            parseFloat(
                                                values.unlockPriceEScooter + ''
                                            ) == parseFloat(i?.UnlockFee)
                                        // @ts-ignore
                                    )[0]?.id
                                }
                                // locked={locked}
                                label="Prices band"
                                name="unlockPriceEScooter"
                                // textAfter={currency}
                                type="number"
                            />
                            <TextInput
                                w={40}
                                label="Unlock price"
                                name="unlockPriceEScooter"
                                textAfter={currency}
                                type="number"
                                disabled={!editable}
                            />
                            <TextInput
                                w={40}
                                label="Minute price"
                                name="minutePriceEScooter"
                                textAfter={`${currency}/min`}
                                type="number"
                                disabled={!editable}
                            />
                            <TextInput
                                w={40}
                                label="Pause price"
                                name="pausePriceEScooter"
                                textAfter={`${currency}/min`}
                                type="number"
                                disabled={!editable}
                            />
                            <TextInput
                                w={40}
                                label="Idle price"
                                name="idlePriceEScooter"
                                textAfter={currency}
                                type="number"
                                helperText="Text helper"
                                disabled={!editable}
                            />
                        </Box>

                        <Divider header="Pricing EBike" spaceX="-16px" mt={8} />

                        <Box d="flex" gap={4} mt={2} xs={{ flexWrap: 'wrap' }}>
                            <TextInput
                                w={40}
                                label="Unlock price"
                                name="unlockPriceEBike"
                                textAfter={currency}
                                type="number"
                                disabled={!editable}
                            />
                            <TextInput
                                w={40}
                                label="Minute price"
                                name="minutePriceEBike"
                                textAfter={`${currency}/min`}
                                type="number"
                                disabled={!editable}
                            />
                            <TextInput
                                w={40}
                                label="Pause price"
                                name="pausePriceEBike"
                                textAfter={`${currency}/min`}
                                type="number"
                                disabled={!editable}
                            />
                            <TextInput
                                w={40}
                                label="Idle price"
                                name="idlePriceEBike"
                                textAfter={currency}
                                type="number"
                                disabled={!editable}
                            />
                        </Box>
                        <Text variant="caption" spaceY={4}>
                            Leaving any pricing value empty will inherit it from
                            the zone level, while assigning a value to any
                            parameter here will affect this specific periodic
                            setup accordingly.
                        </Text>
                    </Modal>
                    <Modal
                        id="popupDelete"
                        isOpen={openPopupDelete}
                        with
                        onClickClose={() => {
                            setOpenPopupDelete(false);
                        }}
                        withExpandButton={false}
                        header={` Delete periodic item for ${values.targetZones}`}
                        footer={
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setOpenPopupDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    hidden={!editable}
                                    onClick={() => {
                                        onClickDelete(() => {
                                            setOpenPopupDelete(false);
                                            handleClose();
                                        });
                                    }}
                                >
                                    Yes
                                </Button>
                            </>
                        }
                    >
                        <Text mt={6} mr={16} mb={2}>
                            Are you certain you want to delete the Periodic Item
                            with the name "{values.pricingName}"?
                        </Text>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
