import { Input } from 'components';
import Select from 'react-select';
import { AutocompleteStyled } from './Autocomplete.styled';
import { useTheme } from 'styled-components';

const customStyles = (theme: any) => ({
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        margin: 0,
        boxShadow: 'none',
        outline: 'none',
    }),
    menu: (provided: any) => ({
        ...provided,
        left: 0,
        backgroundColor: theme.palette.gray1,
    }),
});

export const Autocomplete = ({ onChangeValue, id, ...props }: any) => {
    const theme = useTheme();
    return (
        <AutocompleteStyled>
            <Input
                // styles={customStyles(theme)}
                as={Select}
                // onChange={(e: any) => onChangeValue(e.value, id)}
                {...props}
            />
        </AutocompleteStyled>
    );
};
