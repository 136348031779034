export const submitMapper = (data: any) => {
    let pricing: any = [];

    if (
        data.minutePriceEBike ||
        data.unlockPriceEBike ||
        data.pausePriceEBike ||
        data.idlePriceEBike
    )
        pricing.push({
            vehicleType: 'ebicycle',
            runningFee: data.minutePriceEBike || 0,
            unlockFee: data.unlockPriceEBike || 0,
            pausedFee: data.pausePriceEBike || 0,
            idleFee: data.idlePriceEBike || 0,
        });
    if (
        data.minutePriceEScooter ||
        data.unlockPriceEScooter ||
        data.pausePriceEScooter ||
        data.idlePriceEScooter
    )
        pricing.push({
            vehicleType: 'escooter',
            runningFee: data.minutePriceEScooter || 0,
            unlockFee: data.unlockPriceEScooter || 0,
            pausedFee: data.pausePriceEScooter || 0,
            idleFee: data.idlePriceEScooter || 0,
        });

    return {
        validFrom: data.validFrom || null,
        validTo: data.validTo || null,
        pricingName: data.pricingName,
        priceCommsEnabled: data.priceCommsEnabled || false,
        target_zones: data.targetZones || [],
        recurrence: data?.recurrence?.join("\n") || null,
        pricing,
    };
};
