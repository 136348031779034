export interface RowData {
  id?: string;
  type?: string;
  name?: string;
  timezone?: string;
  company?: string;
  currency?: string;
  recurrence?: string;
  validFrom?: string;
  validTo?: string;
  targetZones?: string[];
  lastEdit?: string;
  editedBy?: string;
  country?: string;
  vat?: string;
  obaFee?: string;
  holdFee?: string;
  convenienceFee?: string;
  asDiscount?: boolean;
  pricing?: {
    vehicleType?: string;
    unlockPrice?: string;
    minutePrice?: string;
    pauseFee?: string;
    idleFee?: string;
    active?: boolean;
  }[];
  abTests?: {
    id?: string;
    name?: string;
  }[];
  periodics?: {
    id?: string;
    name?: string;
    asDiscount?: boolean;
  }[];
}

interface SearchFormat {
  format: string;
  property: any;
}

export const filterData = (
  data: RowData[],
  search: string,
  excludeKeys: string[] = ["timezone"],
  searchFormats: SearchFormat[] = [
    { format: "zone", property: "zoneName" },
    { format: "name", property: "name" },
    { format: "country", property: "country" },
  ]
) => {
  const query = search.toLowerCase().trim();

  for (const { format, property } of searchFormats) {
    if (query.includes(`${format}=`)) {
      const value = query.replace(`${format}=`, "");
      return data.filter((item) =>
        item[property]?.toString().toLowerCase().includes(value)
      );
    }
  }

  return data.filter((item) =>
    Object.keys(item)
      .filter((key) => !excludeKeys.includes(key))
      .some((key) => item[key]?.toString().toLowerCase().includes(query))
  );
};
