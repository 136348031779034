import React from "react";
import { TextStyled } from "./Text.styled";
import { TextProps } from "./Text.interface";
// import { SVG } from "../SVG/SVG";
import { Tooltip } from "antd";

export const Text: React.FC<TextProps> = ({
  variant = "body_1",
  children,
  info,
  ...props
}: TextProps) => {
  return (
    <TextStyled {...props} variant={variant}>
      {children}
      {info && (
        <Tooltip placement="bottom" title={info}>
          {/* <SVG m={1} size="small" icon="information" /> */}
        </Tooltip>
      )}
    </TextStyled>
  );
};
