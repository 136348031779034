import styled from 'styled-components';
import { defaultTheme } from 'utils/theming';

export const LoadingSpinnerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    stroke: ${({ theme }) => theme.palette.primaryBlue};

    .spinner {
        font-size: ${({ theme }) => theme.space[10]};
        width: 1em;
        height: 1em;
        fill: transparent;
        stroke: inherit;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-dasharray: 125;
        animation: spinner 2.5s linear infinite;
    }

    @keyframes spinner {
        0% {
            stroke-dashoffset: 25;
            transform: rotate(0deg);
        }
        50% {
            stroke-dashoffset: 125;
            transform: rotate(720deg);
        }
        100% {
            stroke-dashoffset: 25;
            transform: rotate(1080deg);
        }
    }

    .spinner > circle {
        fill: transparent;
    }
`;

LoadingSpinnerStyled.defaultProps = defaultTheme;
