export const submitMapper = (data: any) => {
    let variantsTemp = data.variants;
    let ebicycleTouched = false;
    let escooterTouched = false;
    let ebicycleExist = false;
    let escooterExist = false;
    let ebicycleTemp: any = { ebicycle: [] };
    let escooterTemp: any = { escooter: [] };

    variantsTemp.forEach(i => {
        if (i.vehicleType == 'ebicycle') {
            ebicycleExist = true;
            if (!i.id) ebicycleTouched = true;
            ebicycleTemp.ebicycle.push(i);
        }
        if (i.vehicleType == 'escooter') {
            escooterExist = true;
            if (!i.id) escooterTouched = true;
            escooterTemp.escooter.push(i);
        }
    });

    ebicycleTemp.ebicycle = ebicycleTemp.ebicycle.map(i => {
        let temp: any = {};

        if (i.runningFee !== '') {
            temp.running_fee = i.runningFee;
        }
        if (i.unlockFee !== '') {
            temp.unlock_fee = i.unlockFee;
        }
        if (i.pausedFee !== '') {
            temp.paused_fee = i.pausedFee;
        }
        if (i.idleFee !== '') {
            temp.idle_fee = i.idleFee;
        }

        return temp;
    });
    escooterTemp.escooter = escooterTemp.escooter.map(i => {
        let temp: any = {};


        temp.running_fee = i.runningFee;

        temp.unlock_fee = i.unlockFee;

        temp.paused_fee = i.pausedFee;

        temp.idle_fee = i.idleFee;


        return temp;
    });

    if (!ebicycleTouched) ebicycleTemp = {};
    if (!escooterTouched) escooterTemp = {};

    if (!ebicycleExist) ebicycleTemp = { ebicycle: [] };
    if (!escooterExist) escooterTemp = { escooter: [] };

    return {
        experiment_name: data.experimentName,
        only_whitelist: true,
        ...escooterTemp,
        ...ebicycleTemp,
        zone_id: data.zoneId,
    };
};
