import styled, { css } from 'styled-components';
import { defaultTheme } from 'utils/theming';
import { ButtonProps } from './Button.interface';

export const ButtonStyled = styled.button<ButtonProps>`
    background: none;
    border: none;
    border-radius: ${({ rounded }) => (rounded ? '200px' : '8px')};
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.space[2]};
    display: flex;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    padding: ${({ theme }) => theme.space[2]};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    line-height: ${({ theme }) => theme.space[4]};
    min-height: ${({ theme }) => theme.space[10]};
    min-width: ${({ theme }) => theme.space[8]};
    min-width: ${({ hasText, theme }: any) =>
        hasText ? theme.space[15] : theme.space[8]};
    transition: all 0.1s ease-out;
    &:focus {
        outline: none;
        /* box-shadow: 0 0 0.25rem 0.0625rem #20e0cc; */
    }
    &:hover {
        background-color: ${({ theme }) => theme.palette.primaryVehicleGreen};
        border: 1px solid ${({ theme }) => theme.palette.primaryVehicleGreen};
        color: ${({ theme }) => theme.palette.primaryBlue} !important;
    }
    ${({ variant, color, theme }: any) => {
        switch (variant) {
            case 'contained':
                return css`
                    background-color: ${theme.palette[color]};
                    border: ${`1px solid ${theme.palette[color]}`};
                    color: ${theme.palette.white} !important;
                `;
            case 'outlined':
                return css`
                    background-color: ${theme.palette.white};
                    border: 1px solid ${theme.palette[color]};
                    color: ${theme.palette[color]} !important;
                `;
            case 'text':
                return css`
                    color: ${theme.palette[color]};
                    text-decoration: underline;
                    padding-right: ${({ theme }) => theme.space[0]};
                    padding-left: ${({ theme }) => theme.space[0]};
                    :hover {
                        background-color: transparent;
                        color: ${theme.palette.secondary} !important;
                        border: none;
                    }
                `;
            default:
                return css``;
        }
    }}

    ${({ size }) => {
        switch (size) {
            case 'small':
                return css`
                    font-size: ${({ theme }) => theme.space[3]};
                    line-height: ${({ theme }) => theme.space[3.5]};
                    min-height: ${({ theme }) => theme.space[6]};
                    min-width: ${({ theme }) => theme.space[6]};
                `;
            case 'medium':
                return css`
                    font-size: ${({ theme }) => theme.space[4]};
                    line-height: ${({ theme }) => theme.space[5]};
                    max-height: ${({ theme }) => theme.space[5]};
                `;
            default:
                return css``;
        }
    }}
    :disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    ${({ selected }) =>
        selected &&
        css`
            color: ${({ theme }) =>
                theme.palette.primaryVehicleGreen} !important;
        `}
`;

ButtonStyled.defaultProps = defaultTheme;
