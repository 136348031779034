import { ABTestEditOrAdd, Icon } from 'components';
import { updateBasePricing, updatePricing, useCurrentUser, useGetPricingPlan, usePriceBands, useRootZone } from 'services';
import { submitMapper } from './helper';
import { useState } from 'react';

const type = 'experiment';

export const ExperimentAddPages = ({ ...props }) => {

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { priceBandsData, priceBandsupdateDate } = usePriceBands();
    const { data: zoneData } = useRootZone();
    const { data: userData } = useCurrentUser();

    const SubmitHandler = async (data, handleClose) => {
        setIsLoadingSubmit(true);
        const body = submitMapper(data);
        await updateBasePricing({
            type: type,
            body,
            method: 'POST',
        });
        setIsLoadingSubmit(false);
        handleClose();
    };

    return (
        <ABTestEditOrAdd
            {...props}
            roles={userData?.roles}
            zonesOptions={zoneData}
            isLoading={isLoadingSubmit}
            priceBandsData={priceBandsData}
            onChange={SubmitHandler}
        />
    );
};
