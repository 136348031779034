import styled, { css } from "styled-components";
import { defaultTheme } from "utils/theming";

export const ModalBackdropStyled = styled.div<{ show: boolean }>`
  animation: fade-in-animation 0.2s forwards;

  ${({ show }) =>
    !show &&
    css`
      animation: fade-out-animation 0.2s forwards;
    `}

  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: ${({ theme }) => theme.palette.gray1 + "66"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalStyled = styled.div<{ show: boolean; expanded: boolean }>`
animation: zoom-in-animation 0.7s forwards;
border-radius: 4px;
max-width: 95%;

  ${({ theme, expanded }) =>
    css`
      /* max-width: ${expanded ? "99%" : "80%"};
      max-height: ${expanded ? "99%" : "80%"}; */
      border: 1px solid ${theme.palette.gray2};
      background-color: ${theme.palette.white};
      min-width: ${theme.space[96]} !important;

      @media (max-width: ${theme.screens.sm}) {
        animation: slide-out-animation 0.2s forwards;
      }
    `}
  ${({ expanded }) =>
    expanded &&
    css`

      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 100% !important;
      max-height: 100% !important;
      border-radius: 0px !important;
      margin: 0 !important;
      flex:1;
    `}
    box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12),
        0px 11px 15px -7px rgba(0, 0, 0, 0.2);

  animation: zoom-out-animation 0.2s forwards;

  ${({ show, theme }) =>
    !show &&
    css`
      animation: zoom-in-animation 0.2s forwards;
      @media (max-width: ${theme.screens.sm}) {
        animation: slide-in-animation 0.2s forwards;
      }
    `}
  ${({ theme }) =>
    css`
      @media (max-width: ${theme.screens.sm}) {
        bottom: 0;
        min-width: 100% !important;
        max-width: 100% !important;
        place-self: flex-end;
        border-radius: 16px 16px 0 0 !important;
        .footer {
          border-radius: 0 !important;
        }
      }
    `}
    min-width: 500px !important;
`;

ModalStyled.defaultProps = defaultTheme;
ModalBackdropStyled.defaultProps = defaultTheme;
