import styled from 'styled-components';
import { defaultTheme } from 'utils/theming';

export const ToggleSwitchStyled = styled.div<any>`
    cursor: pointer;
    display: flex;
    flex-direction: ${({ labelLeft }) => (labelLeft ? 'row' : 'row-reverse')};
    align-items: center;
    margin: 0;
    gap: ${({ theme }) => theme.space[2]};

    .switch {
        display: inline-block;
        border-radius: 24px;
        width: 60px;
        min-width: 60px;
        height: 34px;
        position: relative;
        vertical-align: middle;
        transition: background 0.25s;
        background: ${({ theme }) => theme.palette.white};
        border: 2px solid ${({ theme }) => theme.palette.primaryBlue};
        border-radius: 50px;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.gray1};

        :before,
        :after {
            content: '';
        }

        :before {
            display: block;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            position: absolute;
            top: -2px;
            left: -2px;
            transition: left 0.25s;
            background: ${({ theme }) => theme.palette.white};
            border: 2px solid ${({ theme }) => theme.palette.primaryBlue};
        }

        :hover :before {
            background-color: red;
        }
    }

    .checkbox {
        position: absolute;
        visibility: hidden;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .checkbox:checked + .switch {
        background: ${({ theme }) => theme.palette.primaryBlue};
        :before {
            left: 24px;
        }
    }
    .checkbox:disabled + .switch {
        opacity: 0.3;
        cursor: default;
    }

    .icon,
    .icon-off {
        position: absolute;
        left: 1px;
        top: 1px;
        width: 28px;
        height: 27px;
        padding: 2px;
        transition: left 0.25s;
    }

    .icon-off {
        border-radius: 50px;
        background-color: ${({ theme }) => theme.palette.white};
    }
    .checkbox:checked + .switch > .icon-off {
        opacity: 0;
        left: 27px;
    }
    .checkbox:checked + .switch > .icon {
        left: 27px;
    }

    .label {
        margin-left: 8px;
        padding-top: 2px;
        flex: 1;
        color: ${({ theme }) => theme.palette.gray6};
    }
`;

ToggleSwitchStyled.defaultProps = defaultTheme;
